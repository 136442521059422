import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { DeploymentEnvironment } from "shared/config/deployment-environments";
import { Disease } from "shared/model/diseases";
import {
  ConversationWithMessages,
  WebSocketResponseEventData,
} from "shared/model/websocket/schema";
import { userTracking } from "shared/types/auth";

import { Conversation } from "./conversation";
import { mamaAskChatDrawerComponents } from "./lookup";
import { PublicConversationRecordWithMessages } from "~/api/generated/multiagent";
import { usePublicApiQuery } from "~/api/use-public-api";
import { usePublicWebSocket } from "~/api/use-public-web-socket";
import { AuthPagesEnum } from "~/auth/constants";
import { useHandleMagicLinkSignUp } from "~/auth/hooks/use-handle-magic-link-sign-up";
import { AuthWrapper } from "~/auth/routes/cognito-layout";
import { LoadingScreen } from "~/components/loading-screen";
import {
  SpecialNavigationRoutes,
  useMamaNavigate,
} from "~/navigation/mama-navigate";
import {
  PUBLIC_CHAT_CONVERSATION_KEY,
  START_PUBLIC_CONVERSATION_KEY,
} from "~/types/query-keys";
import { userHasStartedSignup } from "~/util/data-layer-actions";
import { appEnv, isNotLocalhost } from "~/util/env-utils";
import { RouteWithTitle } from "~/util/metadata-for-active-route";
import { useTenantId } from "~/util/use-active-tenant-id";
import { useGetExtendedTheme } from "~/util/use-get-theme";
import { useQueryParamsForUserSignUp } from "~/util/use-save-query-params-to-local-storage";
import { logActivity } from "~/util/user-log";

export const PUBLIC_CHAT_LOCAL_STORAGE_KEY = "public-chat-credentials";

export type PublicChatCredentials = {
  userId: string;
  token: string;
  conversationId: string;
  language: string;
  disease: string;
};

export const PublicChat: React.FC = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const navigate = useMamaNavigate();
  const { colors } = useGetExtendedTheme();
  const { disease } = useTenantId();
  const [query] = useSearchParams();
  const entrypoint = useMemo(() => {
    if (disease === Disease.ENDOMETRIOSIS && language === "de-DE")
      return "entrypoint_endostudy";

    return query.get("entrypoint") ?? "entrypoint_default";
  }, [disease, language, query]);
  const publicConversationUserAttributes = useQueryParamsForUserSignUp();
  const handleMagicLinkSignUp = useHandleMagicLinkSignUp({
    legacy: false,
  });

  const [startNewConversation, setStartNewConversation] = useState(false);

  const [conversation, setConversation] = useState<
    PublicConversationRecordWithMessages | undefined
  >(undefined);

  const [publicChatCredentials, setPublicChatCredentials] =
    useState<PublicChatCredentials>();

  useEffect(() => {
    const creds = localStorage.getItem(PUBLIC_CHAT_LOCAL_STORAGE_KEY);
    if (!creds) {
      setStartNewConversation(true);
      return;
    }

    try {
      const parsedCreds = JSON.parse(creds);
      if (
        parsedCreds.disease !== disease ||
        parsedCreds.language !== language
      ) {
        setStartNewConversation(true);
        return;
      }

      setPublicChatCredentials(creds ? JSON.parse(creds) : undefined);
    } catch {
      navigate(SpecialNavigationRoutes.AUTH_FALLBACK, {
        error: { tx: "auth.signIn.errors.errorParsingClientMetaData" },
      });
      return;
    }
  }, [disease, language, navigate]);

  usePublicApiQuery(
    "multiagent",
    (api) =>
      api.startPublicConversation({
        mamaDisease: disease,
        mamaLanguage: language,
        entrypoint: entrypoint ?? undefined,
        createPublicConversationUserAttributes:
          publicConversationUserAttributes,
      }),
    [START_PUBLIC_CONVERSATION_KEY(disease)],
    {
      refetchOnWindowFocus: false,
      onSuccess: ({ conversationId, token, userId }) => {
        const creds = {
          conversationId,
          token,
          userId,
          language,
          disease,
        };
        localStorage.setItem(
          PUBLIC_CHAT_LOCAL_STORAGE_KEY,
          JSON.stringify(creds),
        );
        setPublicChatCredentials(creds);
        logActivity(token, false, {
          userId,
          triggerSource: userTracking.chatStarted,
          language,
          disease,
        });
        userHasStartedSignup();
      },
      enabled:
        startNewConversation &&
        !!entrypoint &&
        !!publicConversationUserAttributes,
    },
  );

  usePublicApiQuery(
    "multiagent",
    (api) =>
      api.getPublicConversationWithId({
        mamaUser: publicChatCredentials?.userId as string,
        authorization: publicChatCredentials?.token as string,
        mamaConversationId: publicChatCredentials?.conversationId as string,
        mamaDisease: disease,
      }),
    [PUBLIC_CHAT_CONVERSATION_KEY(disease)],
    {
      enabled: !!publicChatCredentials,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setConversation(data);
      },
    },
  );

  const useAgnosticWebSocket = useCallback(
    (params: {
      key?: string;
      query: {
        language: string;
        disease: string;
        conversation_id: string;
      };
      onResponse: (
        event?: MessageEvent<WebSocketResponseEventData>,
      ) => Promise<void> | void;
    }) => {
      return usePublicWebSocket({
        key: params.key,
        url: getWebSocketUrl(),
        query: {
          ...params.query,
          disease: disease,
          temp_token: publicChatCredentials?.token as string,
          external_user_id: publicChatCredentials?.userId as string,
        },
        onResponse: params.onResponse,
      });
    },
    [disease, publicChatCredentials?.token, publicChatCredentials?.userId],
  );

  const cognitoAuthRoutesWithReducedLayout: RouteWithTitle[] = [
    {
      path: AuthPagesEnum.ONBOARDING,
      title: {
        tx: "chat.headerTitle",
      },
    },
  ];

  return !conversation ? (
    <LoadingScreen message={{ tx: "chat.loadingInitialChat" }} />
  ) : (
    <AuthWrapper routes={cognitoAuthRoutesWithReducedLayout}>
      <Conversation
        chatDrawerComponents={mamaAskChatDrawerComponents}
        useAgnosticWebSocket={useAgnosticWebSocket}
        conversation={conversation as ConversationWithMessages}
        shouldShowChatCompletion={false}
        triggerSignUp={handleMagicLinkSignUp}
        userChatColors={
          colors && {
            bubbleColor: colors["mama-green-100"],
          }
        }
      />
    </AuthWrapper>
  );
};

const getWebSocketUrl = () => {
  return !isNotLocalhost
    ? "ws://localhost:8000/public/ws"
    : appEnv === DeploymentEnvironment.DEV
    ? "wss://ws.dev.mamahealth.com/public/ws"
    : "wss://ws.app.mamahealth.com/public/ws";
};
