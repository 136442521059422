import React from "react";
import { twMerge } from "tailwind-merge";

import { ChatMessageAssistant } from "./chat-message-assistant";
import { ChatMessageUser } from "./chat-message-user";
import { ChatMessage, ChatRole } from "../types";

export const ChatMessageComponent: React.FC<
  {
    message: ChatMessage;
    userChatColors?: Partial<{ bubbleColor: string; textColor: string }>;
  } & JSX.IntrinsicElements["div"]
> = ({
  message: { role, content, media, sources, questionNodeInfo },
  className,
  userChatColors,
  ...rest
}) => {
  return (
    <div
      className={twMerge(
        "chat break-words",
        role === ChatRole.USER
          ? "chat-end"
          : role === ChatRole.ASSISTANT
          ? "chat-start"
          : "mb-2 flex justify-center",
        className,
      )}
      {...rest}
    >
      {role === ChatRole.USER && (
        <ChatMessageUser
          text={content.text}
          media={media}
          userChatColors={userChatColors}
        />
      )}

      {role === ChatRole.ASSISTANT && (
        <ChatMessageAssistant
          text={content.text}
          mediaSrc={questionNodeInfo?.mediaSrc}
          mediaAlt={questionNodeInfo?.mediaAlt}
          media={media}
          sources={sources}
        />
      )}
    </div>
  );
};
