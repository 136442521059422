import React from "react";

import { EmbellishmentVisibilityProvider } from "./providers/embellishment-visibility-provider";
import {
  LanguageSelectorVisibilityProvider,
  useLanguageSelectorVisibility,
} from "./providers/language-selector-visibility-provider";
import { AuthPagesEnum } from "~/auth/constants";
import { LanguageSelector } from "~/components/language-selector";
import { Text } from "~/components/text";
import { useT } from "~/i18n/use-t";
import { cn } from "~/util/cn";
import { useMetadataForActiveRoute } from "~/util/metadata-for-active-route";

export const EcommerceLayout: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, className }) => {
  const t = useT();

  useMetadataForActiveRoute([
    {
      path: AuthPagesEnum.ONBOARDING,
      position: "start",
      title: t({ tx: "graph.text.metadata.title" }),
      isActive: true,
    },
  ]);

  return (
    <LanguageSelectorVisibilityProvider>
      <EmbellishmentVisibilityProvider>
        {(Component) => (
          <div
            style={{ height: "100dvh" }}
            className="grid h-screen grid-cols-1 grid-rows-[min-content_auto_min-content] bg-gradient-to-bl from-mama-green-300/5 to-transparent"
          >
            {Component}
            <Header className="z-50" />
            <main
              className={cn(
                "isolate z-10 flex flex-col items-center px-4",
                className,
              )}
            >
              {children}
            </main>
            <Footer className="z-0 mx-auto max-w-2xl" />
          </div>
        )}
      </EmbellishmentVisibilityProvider>
    </LanguageSelectorVisibilityProvider>
  );
};

const Header: React.FC<{ className?: string }> = ({ className }) => {
  const { isVisible } = useLanguageSelectorVisibility();

  return (
    <header
      className={cn("grid grid-cols-2 px-8 py-10 sm:grid-cols-3", className)}
    >
      <img
        className="place-self-center object-contain object-bottom sm:col-start-2"
        src="/resources/mama-logo-wide.svg"
        alt="mama health"
      />

      {isVisible && (
        <LanguageSelector className="max-w-[120px] place-self-end" />
      )}
    </header>
  );
};

const Footer: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <footer
      className={cn(
        "w-full px-6 py-8 text-center text-sm text-mama-default-primary",
        className,
      )}
    >
      <ul className="flex flex-wrap items-center justify-between gap-x-2">
        <li className="flex-1">
          <Text
            tx="signUp.customFields.mamaTermsAndConditions.href"
            txComponents={{
              href: <a className="link-hover link" target="_blank" />,
            }}
          />
        </li>

        <li className="flex-1">
          <Text
            tx="signUp.customFields.mamaPrivacyPolicy.href"
            txComponents={{
              href: <a className="link-hover link" target="_blank" />,
            }}
          />
        </li>
      </ul>
    </footer>
  );
};
