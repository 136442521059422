import { z } from "zod";

import { IeNodeType, IeSubtype } from "~/api/generated/multiagent";

const schemaSocketEventError = z.object({
  type: z.literal("error"),
  data: z.object({
    error: z.object({
      key: z.string(),
      message: z.string(),
    }),
  }),
});

const schemaSocketEventInfo = z.object({
  type: z.literal("info"),
  data: z.object({
    key: z.string(),
  }),
});

const ieNodeTypeValues = Object.values(IeNodeType);
const ieSubtypeValues = Object.values(IeSubtype);
const schemaSocketEventAssistantMessage = z.object({
  contentLocalized: z.string(),
  questionNodeInfo: z.object({
    choices: z.string().array(),
    subtype: z
      .string()
      .optional()
      .nullable()
      .refine(
        (value) =>
          value == null || ieSubtypeValues.includes(value as IeSubtype),
        {
          message:
            "Invalid subtype value. Must be one of: " +
            ieSubtypeValues.join(", "),
        },
      ),
    type: z
      .string()
      .refine((value) => ieNodeTypeValues.includes(value as IeNodeType), {
        message:
          "Invalid type value. Must be one of: " + ieNodeTypeValues.join(", "),
      }),
    mediaSrc: z.string().optional().nullable(),
    mediaAlt: z.string().optional().nullable(),
  }),
  role: z.string(),
});
export type SocketAssistantMessage = z.infer<
  typeof schemaSocketEventAssistantMessage
>;

const schemaSocketEventSuccess = z.object({
  type: z.literal("message"),
  data: z.object({
    success: z.boolean().refine((value) => value === true),
    error: z.null(),
    message: schemaSocketEventAssistantMessage,
    metadata: z.any(),
  }),
});

export const shemaSocketEvent = z.discriminatedUnion("type", [
  schemaSocketEventError,
  schemaSocketEventInfo,
  schemaSocketEventSuccess,
]);
