import { IeNodeType, IeSubtype } from "~/api/generated/multiagent";
import { I18nProps } from "~/components/text";

export type TypeTuple = [IeNodeType, IeSubtype?];
export type ComponentTuple<T> = [T, ValidationFunction?];

export class FallbackLookup<T> {
  private defaultValue: ComponentTuple<T>;
  private map: Map<string, ComponentTuple<T>>;

  public constructor(
    defaultValue: ComponentTuple<T>,
    entries?: readonly (readonly [TypeTuple, ComponentTuple<T>])[],
  ) {
    this.map = new Map(entries?.map(([key, value]) => [key.toString(), value]));
    this.defaultValue = defaultValue;
  }

  public get(key: TypeTuple): ComponentTuple<T> {
    const string_key = key.toString();
    const fallback_key = [key[0], undefined].toString();

    return (
      this.map.get(string_key) ??
      this.map.get(fallback_key) ??
      this.defaultValue
    );
  }
}

export type ValidationFunction = (value: string | number) => {
  ok: boolean;
  message?: I18nProps;
};
