import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { PlainI18nProps } from "shared/types/i18n";

export type MamaNavigateState = {
  redirectUrl?: string;
  prefillValues?: Record<string, string>;
  info?: PlainI18nProps;
  error?: PlainI18nProps;
};

type MamaNavigateErrorState = MamaNavigateState & { error: PlainI18nProps };
type MamaNavigateInfoState = MamaNavigateState & { info: PlainI18nProps };

export const useMamaNavigateState: () => MamaNavigateState = () => {
  const { state } = useLocation();

  const authNavigateState = useMemo(() => {
    const emptyState: MamaNavigateState = {};

    if (isMamaNavigateErrorState(state)) {
      emptyState.error = {
        text: state.error.text,
        tx: state.error.tx,
        txUnchecked: state.error.txUnchecked,
        txData: state.error.txData,
      };
    } else if (isMamaNavigateInfoState(state)) {
      emptyState.info = {
        text: state.info.text,
        tx: state.info.tx,
        txUnchecked: state.info.txUnchecked,
        txData: state.info.txData,
      };
    }

    if (
      state &&
      typeof state.prefillValues === "object" &&
      !!state.prefillValues
    ) {
      emptyState.prefillValues = state.prefillValues;
    }

    return emptyState;
  }, [state]);

  return authNavigateState;
};

const isMamaNavigateErrorState = (
  maybeMamaNavigateErrorState: unknown,
): maybeMamaNavigateErrorState is MamaNavigateErrorState =>
  maybeMamaNavigateErrorState !== null &&
  typeof maybeMamaNavigateErrorState === "object" &&
  !!(maybeMamaNavigateErrorState as unknown & { error: unknown }).error &&
  typeof (maybeMamaNavigateErrorState as unknown & { error: unknown }).error ===
    "object";

const isMamaNavigateInfoState = (
  maybeMamaNavigateInfoState: unknown,
): maybeMamaNavigateInfoState is MamaNavigateInfoState =>
  maybeMamaNavigateInfoState !== null &&
  typeof maybeMamaNavigateInfoState === "object" &&
  !!(maybeMamaNavigateInfoState as unknown & { info: unknown }).info &&
  typeof (maybeMamaNavigateInfoState as unknown & { info: unknown }).info ===
    "object";
