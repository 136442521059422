import { tx } from "shared/types/i18n";

import { FormTextInput } from "../../components/form/form-text-input";
import { DataCollectionImplementation } from "../types";
import { UpdateUserAttributesDto } from "~/api/generated/backend";

export const opadePatientIdInput: DataCollectionImplementation = {
  wasCompleted: (user) => !!user.userAttributes?.opadePatientId,
  Component: ({ errors, register }) => {
    return (
      <>
        <FormTextInput
          labelClassName="font-bold"
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
          autoComplete="off"
          title={{ tx: "signUp.customFields.opadePatientId.title" }}
          placeholder={{ tx: "signUp.customFields.opadePatientId.placeholder" }}
          {...register("opadePatientId", {
            required: tx(
              "signUp.customFields.opadePatientId.errorUserDidNotEnterPatientId",
            ),
          })}
          error={{ txUnchecked: errors.opadePatientId?.message }}
        />
        <FormTextInput
          labelClassName="font-bold"
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
          autoComplete="off"
          title={{ tx: "signUp.customFields.opadePatientId.confirmTitle" }}
          placeholder={{
            tx: "signUp.customFields.opadePatientId.confirmPlaceholder",
          }}
          {...register(
            "opadePatientId_confirmation" as keyof UpdateUserAttributesDto,
            {
              required: tx(
                "signUp.customFields.opadePatientId.errorUserDidNotConfirmPatientId",
              ),
              validate: (val, values) => {
                if (values.opadePatientId !== val) {
                  return "signUp.customFields.opadePatientId.errorPatientIdsDidNotMatch";
                }
              },
            },
          )}
          error={{
            txUnchecked:
              errors[
                "opadePatientId_confirmation" as keyof UpdateUserAttributesDto
              ]?.message,
          }}
        />
      </>
    );
  },
};
