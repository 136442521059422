import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { UpdateDefaultPaymentMethodRequest } from "../../api/generated/backend";
import { toTenantIdHeader, useApiMutation } from "../../api/use-api";
import { useStore } from "../../models/helpers";
import { ModalMethods } from "../../models/modal-provider";
import { USER_OWNED_ACTIVE_SUBSCRIPTIONS_KEY } from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";
import { PlainError } from "../events/plain-error";
import { SuccessEvent } from "../events/success-event";
import { LoadingButton } from "../form/loading-button";

export const StripeCheckoutForm: React.FC<
  ModalMethods & {
    isPaymentSwitchDisabled: boolean;
    setIsPaymentSwitchDisabled: (value: boolean) => void;
  }
> = ({
  hideModal,
  updateModal,
  isPaymentSwitchDisabled,
  setIsPaymentSwitchDisabled,
}) => {
  const store = useStore();
  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();
  const tenantId = useTenantId();

  const { mutate: updateDefaultPaymentMethod } = useApiMutation(
    "backend",
    (api) => (request: UpdateDefaultPaymentMethodRequest) =>
      api.updateDefaultPaymentMethod(request),
    undefined,
    undefined,
  );

  const submitPayment = useCallback(async () => {
    updateModal({
      disabled: { isCloseButtonDisabled: true },
    });

    if (isPaymentSwitchDisabled) return;

    try {
      setIsPaymentSwitchDisabled(true);
      if (!stripe || !elements) {
        return store.addToastEvent(
          new PlainError({ tx: "stripe.error.couldNotLoadCheckoutForm" }),
        );
      }

      const { error: submitError } = await elements.submit();
      if (submitError) {
        return store.addToastEvent(
          new PlainError({ tx: "stripe.error.checkoutFormError" }),
        );
      }

      const { error: paymentError, paymentIntent } =
        await stripe.confirmPayment({
          elements,
          redirect: "if_required",
        });

      if (paymentError) {
        return store.addToastEvent(
          new PlainError({ tx: "stripe.error.paymentError" }),
        );
      }

      updateDefaultPaymentMethod({
        defaultPaymentMethodDto: {
          defaultPaymentMethodId: paymentIntent.payment_method as string,
        },
        ...toTenantIdHeader(tenantId),
      });

      queryClient.invalidateQueries(
        USER_OWNED_ACTIVE_SUBSCRIPTIONS_KEY(tenantId),
      );

      store.addToastEvent(
        new SuccessEvent({ tx: "stripe.subscriptionPurchased" }),
      );
      hideModal();
    } finally {
      setIsPaymentSwitchDisabled(false);
      updateModal({
        disabled: { isCloseButtonDisabled: false },
      });
    }
  }, [
    updateModal,
    isPaymentSwitchDisabled,
    setIsPaymentSwitchDisabled,
    stripe,
    elements,
    updateDefaultPaymentMethod,
    tenantId,
    queryClient,
    store,
    hideModal,
  ]);

  return (
    <div className="m-auto grid gap-2">
      <PaymentElement className="px-2" />
      <LoadingButton
        tx="stripe.confirmPayment"
        onClick={submitPayment}
        loading={isPaymentSwitchDisabled}
      />
    </div>
  );
};
