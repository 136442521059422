import { Language } from "shared/model/languages";
import { PlainI18nProps } from "shared/types/i18n";

import { SniperLinkConfig, SniperLinkEnum } from "./types";

export const MAGIC_LINK_SIGN_IN_AUTH_FLOW = "CUSTOM_WITHOUT_SRP";

export const MAGIC_LINK_SIGN_IN_METHOD = "MAGIC_LINK";

export enum SignUpNextStepEnum {
  DONE = "DONE",
}

export enum AuthHubTypeEnum {
  AUTH = "auth",
  CUSTOM = "custom_auth",
}

export enum CustomHubAuthEventsEnum {
  SIGNING_OUT = "signingOut",
}

export enum SignUpStatusEnum {
  USER_ALREADY_EXISTS = "UserAlreadyExists",
  UNKNOWN_SIGN_IN_BEFORE_SIGN_UP_ERROR = "UnknownSignInBeforeSignUpError",
  MAGIC_LINK_SENT = "MagicLinkSent",
  SIGN_UP_NOT_DONE = "SignUpNotDone",
  UNKNOWN_ERROR = "UnknownError",
}

export enum SignInNextStepEnum {
  CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE = "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE",
  CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED = "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED",
}

export enum SignInStatusEnum {
  MAGIC_LINK_SENT = "magicLinkSent",
  USER_ALREADY_SIGNED_IN = "UserAlreadySignedIn",
  USER_CHALLENGE_INVALID = "UserChallangeInvalid",
  USER_CONFIRMED_SIGNED_IN = "UserConfirmedSignedIn",
  USER_CONFIRMED_CHALLENGE_INVALID = "UserConfirmedChallangeInvalid",
  CONDITIONAL_CHECK_FAILED_EXCEPTION = "ConditionalCheckFailedException",
  USER_NOT_FOUND = "UserNotFound",
  UNKNOWN_ERROR = "UnknownError",
}

export enum AuthPagesEnum {
  AUTH_ROOT = "/auth/",
  ONBOARDING = "/auth/onboarding",
  SIGN_UP = "/auth/signup",
  CONFIRM_SIGN_UP_CALLBACK = "/auth/signup-confirmation-callback",
  UPDATE_EMAIL_CALLBACK = "/auth/update-email-callback",
  UPDATE_EMAIL_INFO = "/auth/update-email-info",
  SIGN_IN = "/auth/signin",
  SIGN_UP_DEPRICATED = "/auth/signup-deprecated",
  MAGIC_SIGN_IN = "/auth/magic-signin",
  MAGIC_SIGN_UP = "/auth/magic-signup",
  LOGIN_PROBLEM = "/auth/login-problem",
  NEED_TO_MIGRATE_ACCOUNT_NOTICE = "/auth/need-to-migrate-account-notice",
  MIGRATE_ACCOUNT = "/auth/migrate-account",
  VERIFY_MAGIC_LINK = "/auth/verify-magic-link",
  MAGIC_LINK_SENT = "/auth/magic-link-sent",
}

export enum AuthStateEnum {
  SIGNED_IN = "SIGNED_IN",
  UNDETERMINED = "UNDETERMINED",
  SIGNED_OUT = "SIGNED_OUT",
}

export enum AuthMessageTypesEnum {
  CONFIRM_SIGN_IN = "CONFIRM_SIGN_IN",
  CONFIRM_SIGN_UP = "CONFIRM_SIGN_UP",
  LOGIN_PROBLEM = "LOGIN_PROBLEM",
  UPDATE_EMAIL_INFO = "UPDATE_EMAIL_INFO",
}

export const AuthMessages: Record<AuthMessageTypesEnum, PlainI18nProps> = {
  [AuthMessageTypesEnum.CONFIRM_SIGN_IN]: {
    tx: "auth.signInMagic.magicLinkLogin",
  },
  [AuthMessageTypesEnum.CONFIRM_SIGN_UP]: {
    tx: "auth.signUp.finishRegistrationProcessWithCustomLink",
  },
  [AuthMessageTypesEnum.LOGIN_PROBLEM]: {
    tx: "auth.navigation.resendMagicLink",
  },
  [AuthMessageTypesEnum.UPDATE_EMAIL_INFO]: {
    tx: "profile.emailConfirmedContinueToAppButton",
  },
};

export const sniperLinkConfig: SniperLinkConfig = {
  [SniperLinkEnum.GMAIL]: {
    link: "https://mail.google.com/",
    logo: "gmail",
  },
  [SniperLinkEnum.OUTLOOK]: {
    link: "https://outlook.live.com/",
    logo: "outlook",
  },
  [SniperLinkEnum.YAHOO]: {
    link: "https://mail.yahoo.com/",
    logo: "yahoo",
  },
  [SniperLinkEnum.GMX]: {
    link: "https://www.gmx.com/",
    logo: "gmx",
    language: Language.de_DE,
  },
  [SniperLinkEnum.LIBERO]: {
    link: "https://www.libero.it/",
    logo: "libero",
    language: Language.it_IT,
  },
};
