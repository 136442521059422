import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Disease } from "shared/model/diseases";
import { Language } from "shared/model/languages";

import { PublicEcommerceConversation } from "./conversation";
import { EcommerceLayout } from "./layout/ecommerce-layout";
import { usePublicApiQuery } from "~/api/use-public-api";
import {
  CredentialsStatus,
  ICredentials,
  useCredentials,
} from "~/auth/hooks/use-credentials";
import { LoadingIndicator } from "~/components/loading-spinner";
import {
  SpecialNavigationRoutes,
  useMamaNavigate,
} from "~/navigation/mama-navigate";
import { START_PUBLIC_CONVERSATION_KEY } from "~/types/query-keys";
import { userHasStartedSignup } from "~/util/data-layer-actions";
import { useWatchChange } from "~/util/hooks";
import { useTenantId } from "~/util/use-active-tenant-id";
import { useQueryParamsForUserSignUp } from "~/util/use-save-query-params-to-local-storage";
import { logActivity } from "~/util/user-log";

export const PublicEcommerce: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { disease } = useTenantId();
  const {
    i18n: { language },
  } = useTranslation();
  const queryParams = useQueryParamsForUserSignUp();
  const entrypoint = useMemo(() => {
    if (disease === Disease.ENDOMETRIOSIS && language === "de-DE")
      return "entrypoint_endostudy";

    return queryParams.entrypoint ?? "entrypoint_default";
  }, [disease, language, queryParams]);

  return (
    <PublicEcommerceGeneric
      disease={disease}
      language={language as Language}
      className={className}
      entrypoint={entrypoint}
      queryParams={queryParams}
    />
  );
};

const usePublicConversationCreation = ({
  disease,
  language,
  entrypoint,
  queryParams,
  onConversationCreated,
  enabled,
}: {
  disease: Disease;
  language: Language;
  entrypoint?: string;
  queryParams: ReturnType<typeof useQueryParamsForUserSignUp>;
  onConversationCreated: (credentials: ICredentials) => void;
  enabled: boolean;
}) => {
  const queryKey = [START_PUBLIC_CONVERSATION_KEY(disease)];
  const [disabled, setDisabled] = useState(enabled);

  const query = usePublicApiQuery(
    "multiagent",
    (api) =>
      api.startPublicConversation({
        mamaDisease: disease,
        mamaLanguage: language,
        entrypoint,
        createPublicConversationUserAttributes: queryParams,
      }),
    queryKey,
    {
      refetchOnWindowFocus: false,
      onSuccess: ({ conversationId, token, userId }) => {
        logActivity(token, false, {
          userId,
          triggerSource: "UserTracking_ChatStarted",
          language,
          disease,
        });
        userHasStartedSignup();
        onConversationCreated({
          conversationId,
          token,
          userId,
          language,
          disease,
        });
        setDisabled(false);
      },
      enabled,
    },
  );

  useWatchChange(language, () => {
    setDisabled(true);
    query.refetch();
  });

  return { disabled };
};

const PublicEcommerceGeneric: React.FC<{
  disease: Disease;
  language: Language;
  entrypoint: string;
  queryParams: ReturnType<typeof useQueryParamsForUserSignUp>;
  className?: string;
}> = ({ disease, language, entrypoint, queryParams, className }) => {
  const navigate = useMamaNavigate();

  const {
    credentials,
    status,
    error,
    set: setCredentials,
    reset,
  } = useCredentials();
  if (error) {
    reset();
    navigate(SpecialNavigationRoutes.AUTH_FALLBACK, {
      error: { tx: "auth.signIn.errors.errorParsingClientMetaData" },
    });
  }

  const { disabled } = usePublicConversationCreation({
    disease,
    language,
    entrypoint,
    queryParams,
    enabled: status === CredentialsStatus.NotFound,
    onConversationCreated: setCredentials,
  });

  return (
    <EcommerceLayout className={className}>
      {credentials == null ? (
        <LoadingIndicator message={{ tx: "chat.loadingInitialChat" }} />
      ) : (
        <PublicEcommerceConversation
          key={credentials.language}
          disease={disease}
          language={language}
          credentials={credentials}
          disabled={disabled}
        />
      )}
    </EcommerceLayout>
  );
};
