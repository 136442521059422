import { FrontendQuestionNodeInfo } from "shared/model/websocket/schema";
import { TranslationFeKey } from "shared/types/translation-key";

import { UserMediaRecord } from "../../api/generated/multiagent";
import { I18nProps } from "../text";

export enum ChatRole {
  ASSISTANT = "ASSISTANT",
  USER = "USER",
}

export type ChatUserMediaRecord = Omit<
  UserMediaRecord,
  "id" | "subtype" | "extractedText" | "createdAt"
>;

export interface ChatMessage {
  role: ChatRole;
  content: I18nProps;
  media?: ChatUserMediaRecord[];
  questionNodeInfo?: FrontendQuestionNodeInfo;
  sources?: Record<string, string>;
}

export interface Chat {
  id: string;
  userId: string;
  messages: ChatMessage[];
  loadingMessage: TranslationFeKey;
}
