import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { DataCollectionImplementation } from "../types";
import { FormInput } from "~/components/form/form-input";
import { Link } from "~/components/link";
import { Text } from "~/components/text";

export const privacyPolicy20231018Input: DataCollectionImplementation = {
  wasCompleted: (user) => !!user.userAttributes?.agreedToPrivacyPolicy20231018,
  Component: ({ errors }) => {
    const context = useFormContext();
    useEffect(() => {
      context?.setValue("agreedToPrivacyPolicy20231018", true);
    }, [context]);

    return (
      <div className="form-control my-2 text-left">
        <FormInput
          error={{
            txUnchecked: errors.agreedToPrivacyPolicy20231018?.message,
          }}
        >
          <Text
            className="label-text"
            tx="signUp.customFields.mamaPrivacyPolicy.checkOutPrivacyPolicy"
            txComponents={{
              href: <Link target="_blank" className="underline" />,
            }}
          />
        </FormInput>
      </div>
    );
  },
};
