import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { pathSegmentOfEnum } from "shared/config/base-urls";
import { allDiseaseTypes, Disease } from "shared/model/diseases";
import { TranslationFeKey } from "shared/types/translation-key";

import { Button } from "./components/form/button";
import { useT } from "./i18n/use-t";
import { useDefaultMetadata } from "./util/metadata-for-active-route";

export const SelectDisease: React.FC = () => {
  const [params] = useSearchParams();
  useDefaultMetadata({
    title: { text: "mama health" },
    favIcon: "/resources/mama-favicon-highres.png",
  });

  const navigateToDisease = useCallback((disease: Disease) => {
    location.href = `${location.origin}/${pathSegmentOfEnum(disease)}`;
  }, []);

  useEffect(() => {
    const maybeDisease = params.get("disease");
    if (maybeDisease && isDisease(maybeDisease))
      navigateToDisease(maybeDisease);
  }, [navigateToDisease, params]);

  const t = useT();

  return (
    <div className="m-auto flex h-screen max-w-md flex-col items-center justify-around p-5">
      <div className="flex h-full w-full max-w-md flex-col items-center justify-center gap-5">
        <p className="text-center">
          {t({ tx: "selectDisease.explanation_1" })}
        </p>
        <div className="flex w-full flex-col gap-3 overflow-scroll rounded-lg border-2 p-5">
          <p className="w-full self-start border-b-2 pb-2 text-2xl font-bold">
            {t({ tx: "selectDisease.selectDisease" })}
          </p>
          {allDiseaseTypes.map((disease) => {
            return (
              <DieaseButton
                key={disease}
                disease={disease}
                onClick={navigateToDisease}
              />
            );
          })}
          <p
            onClick={() => {
              open(t({ tx: "selectDisease.otherDisease" }), "_blank");
            }}
            className="w-full cursor-pointer self-start border-t-2 pt-2 font-bold"
          >
            {t({ tx: "profile.lookingFor.other" })}
          </p>
        </div>
        <p className="text-center">
          {t({ tx: "selectDisease.explanation_2" })}
        </p>
      </div>
    </div>
  );
};

const DieaseButton: React.FC<{
  disease: Disease;
  onClick: (disease: Disease) => void;
}> = ({ disease, onClick }) => {
  const diseaseName = `disease.${formatDiseaseName(
    disease,
  )}.settings.name` as TranslationFeKey;

  return (
    <Button
      className="w-full"
      tx={diseaseName}
      onClick={() => onClick(disease)}
    />
  );
};

const formatDiseaseName = (input: string): string =>
  input
    .split("_")
    .map((word) => word.toLocaleLowerCase())
    .join("-");

const isDisease = (maybeDisease: string): maybeDisease is Disease =>
  Object.values(Disease).includes(maybeDisease as Disease);
