import { MamaCognitoError } from "./cognito-errors";
import { accesTokenPayload } from "../types";

export const getTimeToTokenRefresh = (token: string): number => {
  const jwtPayload = getDecodedPayloadFromAccessToken(token);
  const refreshInterval = calculateTimeToTokenRefresh(jwtPayload);
  return refreshInterval;
};

const calculateTimeToTokenRefresh = (payload: accesTokenPayload): number => {
  const refreshTokenAtPercentageOfTotalValidity = 0.8;
  const nowTimestampInMilliseconds = Date.now();
  const refreshAtInMilliseconds =
    Math.floor(
      payload.iat +
        (payload.exp - payload.iat) * refreshTokenAtPercentageOfTotalValidity,
    ) * 1000;
  const timeToRefreshInMilliseconds =
    refreshAtInMilliseconds - nowTimestampInMilliseconds;

  return timeToRefreshInMilliseconds;
};

const getDecodedPayloadFromAccessToken = (token: string): accesTokenPayload => {
  try {
    const payload = token.split(".")[1];
    const base64DecodedPayload = JSON.parse(atob(payload));

    assertIsAccessTokenPayloadType(base64DecodedPayload);

    return base64DecodedPayload;
  } catch (err) {
    throw new MamaCognitoError({
      name: "auth.signIn.errors.errorDecodingMagicLinkToken",
    });
  }
};

function assertIsAccessTokenPayloadType(
  payload: unknown,
): asserts payload is accesTokenPayload {
  if (
    !payload ||
    typeof payload !== "object" ||
    !("sub" in payload) ||
    typeof payload.sub !== "string" ||
    !("username" in payload) ||
    typeof payload.username !== "string" ||
    !("exp" in payload) ||
    typeof payload.exp !== "number"
  ) {
    throw new MamaCognitoError({
      name: "auth.signIn.errors.errorAssertingAccessTokenPayloadType",
    });
  }
}
