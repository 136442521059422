import { Hub } from "aws-amplify/utils";

import { AuthHubTypeEnum, CustomHubAuthEventsEnum } from "./constants";

export const dispatchSigningOutToEventHub = async (): Promise<void> => {
  Hub.dispatch(AuthHubTypeEnum.CUSTOM, {
    event: CustomHubAuthEventsEnum.SIGNING_OUT,
  });

  const graceTimeout = new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, 300);
  });

  await graceTimeout;
};
