import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { MIN_PASSWORD_LENGTH } from "shared/config/cognito";
import { tx } from "shared/types/i18n";
import { TranslationFeKey } from "shared/types/translation-key";

import { SecondaryButton } from "../../components/form/button";
import { Form } from "../../components/form/form";
import { FormTextInput } from "../../components/form/form-text-input";
import { LoadingButton } from "../../components/form/loading-button";
import { useMamaNavigateState } from "../../navigation/use-mama-navigate-state";
import { useUserHasInteractedWithSignupForm } from "../../util/data-layer-actions";
import { isEmailRegex } from "../../util/regex";
import { AuthPagesEnum } from "../constants";
import { useHandleSignUp } from "../hooks/use-handle-sign-up";
import { useMamaNavigate } from "~/navigation/mama-navigate";

export const CognitoSignUp: React.FC<{
  userShouldRepeatEmail?: boolean;
}> = ({ userShouldRepeatEmail = false }) => {
  const { hash } = useLocation();
  const handleSignUp = useHandleSignUp();

  const { prefillValues } = useMamaNavigateState();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<{
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
    groupSecret?: string;
    confirmEmail?: string;
  }>({ defaultValues: prefillValues });

  useUserHasInteractedWithSignupForm(isDirty);

  const navigate = useMamaNavigate(
    () => ({
      email: getValues("email"),
      name: getValues("name"),
    }),
    [getValues],
  );

  useEffect(() => {
    if (hash !== "#override") {
      navigate(AuthPagesEnum.ONBOARDING, {
        replace: true,
      });
    }
  }, [hash, navigate]);

  return (
    <Form onSubmit={handleSubmit(handleSignUp)}>
      <FormTextInput
        title={{ tx: "auth.signUp.inputs.name.title" }}
        placeholder={{ tx: "auth.signUp.inputs.name.placeholder" }}
        {...register("name", {
          required: tx("auth.signUp.inputs.name.fieldMissingError"),
          validate: (value) => {
            if (value.trim() === "") {
              return tx("auth.signUp.inputs.name.fieldMissingError");
            }
          },
        })}
        error={{ txUnchecked: errors.name?.message }}
      />
      <FormTextInput
        title={{ tx: "auth.signUp.inputs.email.title" }}
        placeholder={{ tx: "auth.signUp.inputs.email.placeholder" }}
        autoComplete="username"
        {...register("email", {
          required: tx("auth.signUp.inputs.email.fieldMissingError"),
          pattern: {
            value: isEmailRegex,
            message: tx("auth.signUp.inputs.email.notAnEmailError"),
          },
        })}
        error={{ txUnchecked: errors.email?.message }}
      />
      {userShouldRepeatEmail ? (
        <FormTextInput
          title={{ tx: "auth.signUp.inputs.confirmEmail.title" }}
          placeholder={{ tx: "auth.signUp.inputs.confirmEmail.placeholder" }}
          autoComplete="off"
          {...register("confirmEmail", {
            required: tx("auth.signUp.inputs.confirmEmail.fieldMissingError"),
            validate: (
              val: string | undefined,
            ): TranslationFeKey | undefined => {
              if (getValues("email") !== val) {
                return "auth.signUp.inputs.confirmEmail.emailsDoNotMatchError";
              }
            },
          })}
          error={{ txUnchecked: errors.confirmEmail?.message }}
        />
      ) : null}
      <FormTextInput
        title={{ tx: "auth.signUp.inputs.password.title" }}
        placeholder={{ tx: "auth.signUp.inputs.password.placeholder" }}
        type="password"
        autoComplete="new-password"
        {...register("password", {
          required: tx("auth.signUp.inputs.password.fieldMissingError"),
          minLength: {
            value: MIN_PASSWORD_LENGTH,
            message: tx("auth.signUp.inputs.password.passwordTooShortError"),
          },
        })}
        error={{
          txUnchecked: errors.password?.message,
          txData: { min_password_length: MIN_PASSWORD_LENGTH },
        }}
      />
      <FormTextInput
        title={{ tx: "auth.signUp.inputs.confirmPassword.title" }}
        placeholder={{ tx: "auth.signUp.inputs.confirmPassword.placeholder" }}
        type="password"
        autoComplete="new-password"
        {...register("confirmPassword", {
          required: tx("auth.signUp.inputs.confirmPassword.fieldMissingError"),
          validate: (val: string): TranslationFeKey | undefined => {
            if (getValues("password") !== val) {
              return "auth.signUp.inputs.confirmPassword.passwordsDoNotMatchError";
            }
          },
        })}
        error={{ txUnchecked: errors.confirmPassword?.message }}
      />

      <LoadingButton
        type="submit"
        loading={isSubmitting}
        Button={SecondaryButton}
        tx="auth.signUp.signUpButton"
      />
    </Form>
  );
};
