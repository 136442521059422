import assert from "assert";
import { Amplify } from "aws-amplify";
import { Hub } from "aws-amplify/utils";
import i18next from "i18next";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { cognitoInfoApp } from "shared/config/cognito";
import { DeploymentEnvironment } from "shared/config/deployment-environments";
import { LogEventQueryParams, userTracking } from "shared/types/auth";

import { dirtyGetMaybeCurrentUserData } from "./auth/auth-store";
import { AuthHubTypeEnum, CustomHubAuthEventsEnum } from "./auth/constants";
import { reportWebVitals } from "./report-web-vitals";
import { router } from "./router/router";
import "./index.css";
import { setupSentry } from "./setup-sentry";
import { consoleLogLocalhost, isLocalhost } from "./util/env-utils";
import { dirtyGetMaybeDisease } from "./util/use-active-tenant-id";
import { logActivity } from "./util/user-log";

const rootElement = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(rootElement);
const env = process.env.REACT_APP_APP_ENV;
assert(
  env,
  "env variable REACT_APP_APP_ENV is missing or not in REACT_APP_APP_ENV",
);
assert(
  Object.values(DeploymentEnvironment).includes(env as DeploymentEnvironment),
  "REACT_APP_APP_ENV is not a valid deployment environment",
);

const cognitoConfig = cognitoInfoApp[env as DeploymentEnvironment];
Amplify.configure({
  Auth: {
    Cognito: cognitoConfig,
  },
});

const getDefaultData = (): Partial<LogEventQueryParams> => {
  const language = i18next.language;
  const disease = dirtyGetMaybeDisease();
  const isLocalhostLambdaBoolean = isLocalhost ? "yes" : "no";
  return {
    disease,
    language,
    isLocalhost: isLocalhostLambdaBoolean,
  };
};

const _removeGlobalAmplifyAuthLogger = Hub.listen<"auth">(
  AuthHubTypeEnum.AUTH,
  async ({ payload }) => {
    /**
     * The hub works within a tab only and does not dispatch events across tabs.
     */
    if (payload.event === "signedIn" || payload.event === "tokenRefresh") {
      consoleLogLocalhost("auth event", payload.event);
      const defaultData = getDefaultData();
      const maybeUserData = await dirtyGetMaybeCurrentUserData();
      if (!maybeUserData) return;
      const { accessToken, ...userData } = maybeUserData;
      logActivity(accessToken, true, {
        triggerSource:
          payload.event === "signedIn"
            ? userTracking.signedIn
            : userTracking.tokenRefreshed,
        ...defaultData,
        ...userData,
      });
    }
  },
);

const _removeGlobalCustomAuthLogger = Hub.listen<{
  event: CustomHubAuthEventsEnum;
}>(AuthHubTypeEnum.CUSTOM, async ({ payload }) => {
  /**
   * The hub works within a tab only and does not dispatch events across tabs.
   */
  if (payload.event === CustomHubAuthEventsEnum.SIGNING_OUT) {
    consoleLogLocalhost("custom_auth event", payload.event);
    const defaultData = getDefaultData();
    const maybeUserData = await dirtyGetMaybeCurrentUserData();
    if (!maybeUserData) return;
    const { accessToken, ...userData } = maybeUserData;
    logActivity(accessToken, true, {
      triggerSource: userTracking.signedOut,
      ...defaultData,
      ...userData,
    });
  }
});

setupSentry();

root.render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
