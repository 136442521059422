import { confirmSignIn, signIn, signUp } from "aws-amplify/auth";
import { CognitoClientMetadata } from "shared/types/auth";

export interface CognitoAuthInput {
  email: string;
  name: string;
  clientMetadata: Partial<CognitoClientMetadata>;
}

const MAX_ATTEMPTS = 3;

function retryJitter(attempt: number): number {
  const BASE = 100;
  const MAX_DELAY = 10000;
  const exponential = Math.pow(2, attempt) * BASE;
  const delay = Math.min(exponential, MAX_DELAY);
  return Math.floor(Math.random() * delay);
}

async function retryRequest<T>(f: () => Promise<T>): Promise<T> {
  for (let i = 0; i < MAX_ATTEMPTS; i++) {
    try {
      return f();
    } catch (err) {
      if (i === MAX_ATTEMPTS - 1) {
        throw err;
      }

      const delay = retryJitter(i);
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }

  throw new Error("Did not expect to reach this place");
}

export function signInRetry(
  ...args: Parameters<typeof signIn>
): ReturnType<typeof signIn> {
  return retryRequest(() => signIn(...args));
}

export function signUpRetry(
  ...args: Parameters<typeof signUp>
): ReturnType<typeof signUp> {
  return retryRequest(() => signUp(...args));
}

export function confirmSignInRetry(
  ...args: Parameters<typeof confirmSignIn>
): ReturnType<typeof confirmSignIn> {
  return retryRequest(() => confirmSignIn(...args));
}
