import { PrimaryButton } from "./form/button";
import { Text } from "./text";
import { EmptyWrapper } from "./util/empty-wrapper";
import { useTenantPages } from "../router/use-tenant-pages";
import { useTenantConfig } from "../tenant-settings";
import { useTenantId } from "../util/use-active-tenant-id";
import { useAuthContext } from "~/auth/auth-store";

export const HasEnabledPages: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const tenantId = useTenantId();
  const tenantPages = useTenantPages()?.filter(
    (page) =>
      page.tenantId.disease === tenantId.disease &&
      page.tenantId.organisation === tenantId.organisation,
  );

  return !tenantPages ? null : !!tenantPages.length ? (
    <EmptyWrapper>{children}</EmptyWrapper>
  ) : (
    <NoPermissionNotice />
  );
};

const NoPermissionNotice: React.FC = () => {
  const { signOut } = useAuthContext();

  const settings = useTenantConfig();

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-5">
      <img
        className="h-20 self-center object-contain object-bottom"
        src={settings?.authLogo}
      />

      <Text
        className="max-w-xl text-center text-2xl"
        tx="enabledPage.explanation_1"
      />
      <div className="flex gap-2">
        <PrimaryButton
          tx="auth.signOut.signOutButton"
          onClick={() => {
            signOut();
          }}
        />
      </div>
    </div>
  );
};
