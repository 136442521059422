import { useEffect, useState } from "react";
import { z } from "zod";

import { PUBLIC_CHAT_LOCAL_STORAGE_KEY } from "~/components/chat/ai/ask/chat/ai-public-chat";
import * as safeLocalStorage from "~/util/safe-local-storage";

const cfg = {
  key: PUBLIC_CHAT_LOCAL_STORAGE_KEY,
  schema: z.object({
    disease: z.string(),
    language: z.string(),
    userId: z.string(),
    token: z.string(),
    conversationId: z.string(),
  }),
} as const;
export type ICredentials = z.infer<typeof cfg.schema>;

export enum CredentialsStatus {
  Init,
  NotFound,
  Found,
  Error,
}

export const useCredentials = (): {
  credentials: ICredentials | null | undefined;
  status: CredentialsStatus;
  error: Error | null;
  refresh: () => void;
  set(value: ICredentials): void;
  reset(): void;
} => {
  const [credentials, setCredentials] = useState<
    ICredentials | undefined | null
  >(undefined);
  const [error, setError] = useState<Error | null>(null);
  useEffect(refresh, []);
  const [status, setStatus] = useState<CredentialsStatus>(
    CredentialsStatus.Init,
  );

  function refresh() {
    const { value, err } = safeLocalStorage.getValidated(cfg.key, cfg.schema, {
      shouldBeFound: true,
      deleteIfInvalid: true,
    });
    if (value == null) {
      setCredentials(null);
      setError(null);
      setStatus(CredentialsStatus.NotFound);
      return;
    }
    if (err) {
      setCredentials(null);
      setError(err);
      setStatus(CredentialsStatus.Error);
      return;
    }

    setCredentials(value);
    setError(null);
    setStatus(CredentialsStatus.Found);
  }

  return {
    credentials,
    status,
    error,
    refresh,
    set(value: ICredentials) {
      localStorage.setItem(cfg.key, JSON.stringify(value));
      setCredentials(value);
    },
    reset() {
      localStorage.removeItem(cfg.key);
      setCredentials(undefined);
    },
  };
};
