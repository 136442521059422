import { FormEvent, useState } from "react";
import { SECONDS_MAGIC_LINK_COOLDOWN } from "shared/utils/constants";

import { ButtonResend } from "../../ecommerce/components/signup/email-name-terms";
import { ChatOptionComponentProps } from "../lookup";
import { useHandleMagicLinkSignUp } from "~/auth/hooks/use-handle-magic-link-sign-up";
import { Link } from "~/components/link";
import { Text } from "~/components/text";
import { useT } from "~/i18n/use-t";
import { userHasCreatedAccount } from "~/util/data-layer-actions";

export const EmailNameTerms: React.FC<ChatOptionComponentProps> = ({
  sendResponse,
}) => {
  const t = useT();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleMagicLinkSignUp = useHandleMagicLinkSignUp({ legacy: false });
  const [isEmailSent, setIsEmailSent] = useState(false);

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsLoading(true);
    const successful = await handleMagicLinkSignUp({
      name,
      email,
    });
    setIsLoading(false);

    if (!successful) return;

    userHasCreatedAccount();

    sendResponse({
      message: name,
      selection: [name, email],
    });

    setIsEmailSent(true);
  };

  return !isEmailSent ? (
    <form
      className="flex flex-1 flex-col items-stretch justify-center gap-3"
      onSubmit={onSubmit}
    >
      <input
        title="name"
        type="text"
        placeholder={t({ tx: "study.name.placeholder" })}
        className="input-bordered input w-full"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <input
        title="email"
        type="text"
        placeholder={t({ tx: "study.email.placeholder" })}
        className="input-bordered input"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <div className="flex flex-nowrap items-center gap-1">
        <input
          title="terms"
          type="checkbox"
          className="checkbox"
          checked={acceptTerms}
          onChange={(e) => setAcceptTerms(e.target.checked)}
        />
        <Text
          className="ml-2 text-left"
          tx="signUp.customFields.mamaTermsAndConditions.agreeToTermsAndConditions"
          txComponents={{
            href: (
              <Link
                className="font-normal text-bg-blue-900 underline"
                target="_blank"
              />
            ),
          }}
        />
      </div>

      <button
        type="submit"
        disabled={isLoading || acceptTerms !== true}
        className="btn-primary btn"
      >
        {isLoading && <span className="loading loading-spinner loading-md" />}

        {isLoading ? <Text tx="study.signingUp" /> : <Text tx="study.signUp" />}
      </button>
    </form>
  ) : (
    <ButtonResend
      countdownMs={SECONDS_MAGIC_LINK_COOLDOWN * 1_000}
      data={{ name, email }}
    />
  );
};
