/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Account,
  AttachPaymentMethodDto,
  CancelSubscriptionDto,
  ConnectAccountDto,
  CreateActiveSubscriptionDto,
  CreateAndLinkAccountDto,
  CreateSubscriptionDto,
  CreditCard,
  Customer,
  DefaultPaymentMethodDto,
  FeedbacksDto,
  FinishedSignUpViaCallDto,
  GetProrationInvoiceDto,
  GetUserDto,
  GroupInfoDto,
  GroupsAndSubscriptionsPerFollowedOrg,
  Invoice,
  IsFollowingTenant,
  LastActiveTenant,
  LinkAccount,
  MetabaseDashboardToken,
  MultipleMembershipsDto,
  NewSubscription,
  Notification,
  NotificationDto,
  PaymentMethod,
  RegisterUserDto,
  RenewSubscriptionDto,
  ReportDto,
  Settings,
  StripePrices,
  SubmitFeedbackDto,
  Subscription,
  Subscriptions,
  SwitchSubscriptionDto,
  TenantSettings,
  TimelineDto,
  UpdateSettingsDto,
  UpdateUserAttributesDto,
  UpdateUserDetailsDto,
  User,
  UserCountryAndPostalCode,
  UserEventDto,
  UserExists,
  UserFeedback,
} from '../models/index';
import {
    AccountFromJSON,
    AccountToJSON,
    AttachPaymentMethodDtoFromJSON,
    AttachPaymentMethodDtoToJSON,
    CancelSubscriptionDtoFromJSON,
    CancelSubscriptionDtoToJSON,
    ConnectAccountDtoFromJSON,
    ConnectAccountDtoToJSON,
    CreateActiveSubscriptionDtoFromJSON,
    CreateActiveSubscriptionDtoToJSON,
    CreateAndLinkAccountDtoFromJSON,
    CreateAndLinkAccountDtoToJSON,
    CreateSubscriptionDtoFromJSON,
    CreateSubscriptionDtoToJSON,
    CreditCardFromJSON,
    CreditCardToJSON,
    CustomerFromJSON,
    CustomerToJSON,
    DefaultPaymentMethodDtoFromJSON,
    DefaultPaymentMethodDtoToJSON,
    FeedbacksDtoFromJSON,
    FeedbacksDtoToJSON,
    FinishedSignUpViaCallDtoFromJSON,
    FinishedSignUpViaCallDtoToJSON,
    GetProrationInvoiceDtoFromJSON,
    GetProrationInvoiceDtoToJSON,
    GetUserDtoFromJSON,
    GetUserDtoToJSON,
    GroupInfoDtoFromJSON,
    GroupInfoDtoToJSON,
    GroupsAndSubscriptionsPerFollowedOrgFromJSON,
    GroupsAndSubscriptionsPerFollowedOrgToJSON,
    InvoiceFromJSON,
    InvoiceToJSON,
    IsFollowingTenantFromJSON,
    IsFollowingTenantToJSON,
    LastActiveTenantFromJSON,
    LastActiveTenantToJSON,
    LinkAccountFromJSON,
    LinkAccountToJSON,
    MetabaseDashboardTokenFromJSON,
    MetabaseDashboardTokenToJSON,
    MultipleMembershipsDtoFromJSON,
    MultipleMembershipsDtoToJSON,
    NewSubscriptionFromJSON,
    NewSubscriptionToJSON,
    NotificationFromJSON,
    NotificationToJSON,
    NotificationDtoFromJSON,
    NotificationDtoToJSON,
    PaymentMethodFromJSON,
    PaymentMethodToJSON,
    RegisterUserDtoFromJSON,
    RegisterUserDtoToJSON,
    RenewSubscriptionDtoFromJSON,
    RenewSubscriptionDtoToJSON,
    ReportDtoFromJSON,
    ReportDtoToJSON,
    SettingsFromJSON,
    SettingsToJSON,
    StripePricesFromJSON,
    StripePricesToJSON,
    SubmitFeedbackDtoFromJSON,
    SubmitFeedbackDtoToJSON,
    SubscriptionFromJSON,
    SubscriptionToJSON,
    SubscriptionsFromJSON,
    SubscriptionsToJSON,
    SwitchSubscriptionDtoFromJSON,
    SwitchSubscriptionDtoToJSON,
    TenantSettingsFromJSON,
    TenantSettingsToJSON,
    TimelineDtoFromJSON,
    TimelineDtoToJSON,
    UpdateSettingsDtoFromJSON,
    UpdateSettingsDtoToJSON,
    UpdateUserAttributesDtoFromJSON,
    UpdateUserAttributesDtoToJSON,
    UpdateUserDetailsDtoFromJSON,
    UpdateUserDetailsDtoToJSON,
    UserFromJSON,
    UserToJSON,
    UserCountryAndPostalCodeFromJSON,
    UserCountryAndPostalCodeToJSON,
    UserEventDtoFromJSON,
    UserEventDtoToJSON,
    UserExistsFromJSON,
    UserExistsToJSON,
    UserFeedbackFromJSON,
    UserFeedbackToJSON,
} from '../models/index';

export interface AttachPaymentMethodRequest {
    mamaDisease: AttachPaymentMethodMamaDiseaseEnum;
    attachPaymentMethodDto: AttachPaymentMethodDto;
    mamaOrganisation?: AttachPaymentMethodMamaOrganisationEnum;
}

export interface CancelSubscriptionRequest {
    mamaDisease: CancelSubscriptionMamaDiseaseEnum;
    cancelSubscriptionDto: CancelSubscriptionDto;
    mamaOrganisation?: CancelSubscriptionMamaOrganisationEnum;
}

export interface ConnectAccountRequest {
    mamaDisease: ConnectAccountMamaDiseaseEnum;
    connectAccountDto: ConnectAccountDto;
    mamaOrganisation?: ConnectAccountMamaOrganisationEnum;
}

export interface CreateActiveSubscriptionRequest {
    mamaDisease: CreateActiveSubscriptionMamaDiseaseEnum;
    createActiveSubscriptionDto: CreateActiveSubscriptionDto;
    mamaOrganisation?: CreateActiveSubscriptionMamaOrganisationEnum;
}

export interface CreateAndLinkStripeAccountRequest {
    mamaDisease: CreateAndLinkStripeAccountMamaDiseaseEnum;
    createAndLinkAccountDto: CreateAndLinkAccountDto;
    mamaOrganisation?: CreateAndLinkStripeAccountMamaOrganisationEnum;
}

export interface CreateNotificationRequest {
    notificationDto: NotificationDto;
}

export interface CreateSubscriptionRequest {
    mamaDisease: CreateSubscriptionMamaDiseaseEnum;
    createSubscriptionDto: CreateSubscriptionDto;
    mamaOrganisation?: CreateSubscriptionMamaOrganisationEnum;
}

export interface CreateUserRequest {
    mamaDisease: CreateUserMamaDiseaseEnum;
    mamaOrganisation?: CreateUserMamaOrganisationEnum;
}

export interface DeleteNotificationRequest {
    id: string;
}

export interface FinishedSignUpViaCallRequest {
    mamaDisease: FinishedSignUpViaCallMamaDiseaseEnum;
    finishedSignUpViaCallDto: FinishedSignUpViaCallDto;
    mamaOrganisation?: FinishedSignUpViaCallMamaOrganisationEnum;
}

export interface GetCreditCardsRequest {
    mamaDisease: GetCreditCardsMamaDiseaseEnum;
    mamaOrganisation?: GetCreditCardsMamaOrganisationEnum;
}

export interface GetDynamicManifestRequest {
    disease: string;
}

export interface GetMeRequest {
    mamaDisease: GetMeMamaDiseaseEnum;
    mamaOrganisation?: GetMeMamaOrganisationEnum;
}

export interface GetPriceListRequest {
    mamaDisease: GetPriceListMamaDiseaseEnum;
    mamaOrganisation?: GetPriceListMamaOrganisationEnum;
}

export interface GetProrationInvoiceRequest {
    mamaDisease: GetProrationInvoiceMamaDiseaseEnum;
    getProrationInvoiceDto: GetProrationInvoiceDto;
    mamaOrganisation?: GetProrationInvoiceMamaOrganisationEnum;
}

export interface GetStripeAccountRequest {
    mamaDisease: GetStripeAccountMamaDiseaseEnum;
    mamaOrganisation?: GetStripeAccountMamaOrganisationEnum;
}

export interface GetStripeCustomerRequest {
    mamaDisease: GetStripeCustomerMamaDiseaseEnum;
    mamaOrganisation?: GetStripeCustomerMamaOrganisationEnum;
}

export interface GetTenantSettingsRequest {
    mamaDisease: GetTenantSettingsMamaDiseaseEnum;
    mamaOrganisation?: GetTenantSettingsMamaOrganisationEnum;
}

export interface GetUserRequest {
    mamaDisease: GetUserMamaDiseaseEnum;
    getUserDto: GetUserDto;
    mamaOrganisation?: GetUserMamaOrganisationEnum;
}

export interface GetUserOwnedSubscriptionsRequest {
    mamaDisease: GetUserOwnedSubscriptionsMamaDiseaseEnum;
    mamaOrganisation?: GetUserOwnedSubscriptionsMamaOrganisationEnum;
}

export interface GetiframeUrlTokenForAdminDashboardRequest {
    dashboardIndex: number;
    mamaDisease: GetiframeUrlTokenForAdminDashboardMamaDiseaseEnum;
    mamaOrganisation?: GetiframeUrlTokenForAdminDashboardMamaOrganisationEnum;
}

export interface IsUserFollowingTenantRequest {
    mamaDisease: IsUserFollowingTenantMamaDiseaseEnum;
    mamaOrganisation?: IsUserFollowingTenantMamaOrganisationEnum;
}

export interface JoinGroupRequest {
    mamaDisease: JoinGroupMamaDiseaseEnum;
    groupInfoDto: GroupInfoDto;
    mamaOrganisation?: JoinGroupMamaOrganisationEnum;
}

export interface LeaveGroupRequest {
    mamaDisease: LeaveGroupMamaDiseaseEnum;
    groupInfoDto: GroupInfoDto;
    mamaOrganisation?: LeaveGroupMamaOrganisationEnum;
}

export interface ListFollowedOrganisationsRequest {
    mamaDisease: ListFollowedOrganisationsMamaDiseaseEnum;
}

export interface LogUserEventRequest {
    userEventDto: UserEventDto;
}

export interface MarkSignUpAsDoneRequest {
    mamaDisease: MarkSignUpAsDoneMamaDiseaseEnum;
    mamaOrganisation?: MarkSignUpAsDoneMamaOrganisationEnum;
}

export interface RegisterUserRequest {
    registerUserDto: RegisterUserDto;
}

export interface RenewCurrentSubscriptionRequest {
    mamaDisease: RenewCurrentSubscriptionMamaDiseaseEnum;
    renewSubscriptionDto: RenewSubscriptionDto;
    mamaOrganisation?: RenewCurrentSubscriptionMamaOrganisationEnum;
}

export interface SendReportViaEmailRequest {
    mamaDisease: SendReportViaEmailMamaDiseaseEnum;
    reportDto: ReportDto;
    mamaOrganisation?: SendReportViaEmailMamaOrganisationEnum;
}

export interface SendTimelineRequest {
    mamaDisease: SendTimelineMamaDiseaseEnum;
    timelineDto: TimelineDto;
    mamaOrganisation?: SendTimelineMamaOrganisationEnum;
}

export interface SetLastActiveTenantRequest {
    lastActiveTenant: LastActiveTenant;
}

export interface ShowFeedbackRequest {
    feedbacksDto: FeedbacksDto;
}

export interface SubmitUserFeedbackRequest {
    submitFeedbackDto: SubmitFeedbackDto;
}

export interface SwitchSubscriptionRequest {
    mamaDisease: SwitchSubscriptionMamaDiseaseEnum;
    switchSubscriptionDto: SwitchSubscriptionDto;
    mamaOrganisation?: SwitchSubscriptionMamaOrganisationEnum;
}

export interface UpdateDefaultPaymentMethodRequest {
    mamaDisease: UpdateDefaultPaymentMethodMamaDiseaseEnum;
    defaultPaymentMethodDto: DefaultPaymentMethodDto;
    mamaOrganisation?: UpdateDefaultPaymentMethodMamaOrganisationEnum;
}

export interface UpdateOrganisationMemebershipsRequest {
    mamaDisease: UpdateOrganisationMemebershipsMamaDiseaseEnum;
    multipleMembershipsDto: MultipleMembershipsDto;
    mamaOrganisation?: UpdateOrganisationMemebershipsMamaOrganisationEnum;
}

export interface UpdateSettingsRequest {
    mamaDisease: UpdateSettingsMamaDiseaseEnum;
    updateSettingsDto: UpdateSettingsDto;
}

export interface UpdateUserAttributesRequest {
    mamaDisease: UpdateUserAttributesMamaDiseaseEnum;
    updateUserAttributesDto: UpdateUserAttributesDto;
    mamaOrganisation?: UpdateUserAttributesMamaOrganisationEnum;
}

export interface UpdateUserDetailsRequest {
    mamaDisease: UpdateUserDetailsMamaDiseaseEnum;
    updateUserDetailsDto: UpdateUserDetailsDto;
    mamaOrganisation?: UpdateUserDetailsMamaOrganisationEnum;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async aliveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alive`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async alive(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.aliveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async aliveCheckRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alive-check`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async aliveCheck(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.aliveCheckRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async attachPaymentMethodRaw(requestParameters: AttachPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentMethod>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling attachPaymentMethod.');
        }

        if (requestParameters.attachPaymentMethodDto === null || requestParameters.attachPaymentMethodDto === undefined) {
            throw new runtime.RequiredError('attachPaymentMethodDto','Required parameter requestParameters.attachPaymentMethodDto was null or undefined when calling attachPaymentMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stripe-customer/attach-payment-method`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AttachPaymentMethodDtoToJSON(requestParameters.attachPaymentMethodDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentMethodFromJSON(jsonValue));
    }

    /**
     */
    async attachPaymentMethod(requestParameters: AttachPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentMethod> {
        const response = await this.attachPaymentMethodRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async cancelSubscriptionRaw(requestParameters: CancelSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling cancelSubscription.');
        }

        if (requestParameters.cancelSubscriptionDto === null || requestParameters.cancelSubscriptionDto === undefined) {
            throw new runtime.RequiredError('cancelSubscriptionDto','Required parameter requestParameters.cancelSubscriptionDto was null or undefined when calling cancelSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription/cancel-subscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CancelSubscriptionDtoToJSON(requestParameters.cancelSubscriptionDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cancelSubscription(requestParameters: CancelSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.cancelSubscriptionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async connectAccountRaw(requestParameters: ConnectAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling connectAccount.');
        }

        if (requestParameters.connectAccountDto === null || requestParameters.connectAccountDto === undefined) {
            throw new runtime.RequiredError('connectAccountDto','Required parameter requestParameters.connectAccountDto was null or undefined when calling connectAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stripe/connnect-account`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConnectAccountDtoToJSON(requestParameters.connectAccountDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async connectAccount(requestParameters: ConnectAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.connectAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createActiveSubscriptionRaw(requestParameters: CreateActiveSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling createActiveSubscription.');
        }

        if (requestParameters.createActiveSubscriptionDto === null || requestParameters.createActiveSubscriptionDto === undefined) {
            throw new runtime.RequiredError('createActiveSubscriptionDto','Required parameter requestParameters.createActiveSubscriptionDto was null or undefined when calling createActiveSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription/create-active-subscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateActiveSubscriptionDtoToJSON(requestParameters.createActiveSubscriptionDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createActiveSubscription(requestParameters: CreateActiveSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createActiveSubscriptionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createAndLinkStripeAccountRaw(requestParameters: CreateAndLinkStripeAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LinkAccount>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling createAndLinkStripeAccount.');
        }

        if (requestParameters.createAndLinkAccountDto === null || requestParameters.createAndLinkAccountDto === undefined) {
            throw new runtime.RequiredError('createAndLinkAccountDto','Required parameter requestParameters.createAndLinkAccountDto was null or undefined when calling createAndLinkStripeAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stripe/link-account`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAndLinkAccountDtoToJSON(requestParameters.createAndLinkAccountDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkAccountFromJSON(jsonValue));
    }

    /**
     */
    async createAndLinkStripeAccount(requestParameters: CreateAndLinkStripeAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LinkAccount> {
        const response = await this.createAndLinkStripeAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createNotificationRaw(requestParameters: CreateNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.notificationDto === null || requestParameters.notificationDto === undefined) {
            throw new runtime.RequiredError('notificationDto','Required parameter requestParameters.notificationDto was null or undefined when calling createNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationDtoToJSON(requestParameters.notificationDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createNotification(requestParameters: CreateNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createNotificationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createSubscriptionRaw(requestParameters: CreateSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NewSubscription>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling createSubscription.');
        }

        if (requestParameters.createSubscriptionDto === null || requestParameters.createSubscriptionDto === undefined) {
            throw new runtime.RequiredError('createSubscriptionDto','Required parameter requestParameters.createSubscriptionDto was null or undefined when calling createSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription/create-subscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSubscriptionDtoToJSON(requestParameters.createSubscriptionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewSubscriptionFromJSON(jsonValue));
    }

    /**
     */
    async createSubscription(requestParameters: CreateSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NewSubscription> {
        const response = await this.createSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createUserRaw(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling createUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-creation/create-user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createUser(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createUserRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deletUserFromCognitoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-deletion/delete-cognito-user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletUserFromCognito(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletUserFromCognitoRaw(initOverrides);
    }

    /**
     */
    async deleteAllUserRecordsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-deletion/delete-all-records`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteAllUserRecords(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAllUserRecordsRaw(initOverrides);
    }

    /**
     */
    async deleteNotificationRaw(requestParameters: DeleteNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteNotification(requestParameters: DeleteNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteNotificationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async dismissUserFeedbackRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-feedback/dismiss-user-feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async dismissUserFeedback(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.dismissUserFeedbackRaw(initOverrides);
    }

    /**
     */
    async finishedSignUpViaCallRaw(requestParameters: FinishedSignUpViaCallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling finishedSignUpViaCall.');
        }

        if (requestParameters.finishedSignUpViaCallDto === null || requestParameters.finishedSignUpViaCallDto === undefined) {
            throw new runtime.RequiredError('finishedSignUpViaCallDto','Required parameter requestParameters.finishedSignUpViaCallDto was null or undefined when calling finishedSignUpViaCall.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/signup/finish-via-call`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FinishedSignUpViaCallDtoToJSON(requestParameters.finishedSignUpViaCallDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async finishedSignUpViaCall(requestParameters: FinishedSignUpViaCallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.finishedSignUpViaCallRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getCreditCardsRaw(requestParameters: GetCreditCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CreditCard>>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getCreditCards.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stripe-customer/get-credit-cards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CreditCardFromJSON));
    }

    /**
     */
    async getCreditCards(requestParameters: GetCreditCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CreditCard>> {
        const response = await this.getCreditCardsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDynamicManifestRaw(requestParameters: GetDynamicManifestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.disease === null || requestParameters.disease === undefined) {
            throw new runtime.RequiredError('disease','Required parameter requestParameters.disease was null or undefined when calling getDynamicManifest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tenant/{disease}/manifest.json`.replace(`{${"disease"}}`, encodeURIComponent(String(requestParameters.disease))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getDynamicManifest(requestParameters: GetDynamicManifestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getDynamicManifestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLastActiveTenantRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LastActiveTenant>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/last-active-tenant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LastActiveTenantFromJSON(jsonValue));
    }

    /**
     */
    async getLastActiveTenant(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LastActiveTenant> {
        const response = await this.getLastActiveTenantRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getMeRaw(requestParameters: GetMeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getMe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async getMe(requestParameters: GetMeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.getMeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getNotificationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Notification>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotificationFromJSON));
    }

    /**
     */
    async getNotifications(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Notification>> {
        const response = await this.getNotificationsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getPriceListRaw(requestParameters: GetPriceListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StripePrices>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getPriceList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription/get-prices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StripePricesFromJSON(jsonValue));
    }

    /**
     */
    async getPriceList(requestParameters: GetPriceListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StripePrices> {
        const response = await this.getPriceListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProrationInvoiceRaw(requestParameters: GetProrationInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Invoice>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getProrationInvoice.');
        }

        if (requestParameters.getProrationInvoiceDto === null || requestParameters.getProrationInvoiceDto === undefined) {
            throw new runtime.RequiredError('getProrationInvoiceDto','Required parameter requestParameters.getProrationInvoiceDto was null or undefined when calling getProrationInvoice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription/get-proration-invoice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetProrationInvoiceDtoToJSON(requestParameters.getProrationInvoiceDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceFromJSON(jsonValue));
    }

    /**
     */
    async getProrationInvoice(requestParameters: GetProrationInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Invoice> {
        const response = await this.getProrationInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getStripeAccountRaw(requestParameters: GetStripeAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Account>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getStripeAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stripe/get-account`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountFromJSON(jsonValue));
    }

    /**
     */
    async getStripeAccount(requestParameters: GetStripeAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Account> {
        const response = await this.getStripeAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getStripeCustomerRaw(requestParameters: GetStripeCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Customer>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getStripeCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stripe-customer/get-customer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerFromJSON(jsonValue));
    }

    /**
     */
    async getStripeCustomer(requestParameters: GetStripeCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Customer> {
        const response = await this.getStripeCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTenantSettingsRaw(requestParameters: GetTenantSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TenantSettings>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getTenantSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenant/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantSettingsFromJSON(jsonValue));
    }

    /**
     */
    async getTenantSettings(requestParameters: GetTenantSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TenantSettings> {
        const response = await this.getTenantSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserRaw(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getUser.');
        }

        if (requestParameters.getUserDto === null || requestParameters.getUserDto === undefined) {
            throw new runtime.RequiredError('getUserDto','Required parameter requestParameters.getUserDto was null or undefined when calling getUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetUserDtoToJSON(requestParameters.getUserDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async getUser(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.getUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserCountryAndPostalCodeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserCountryAndPostalCode>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/city-and-postalcode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCountryAndPostalCodeFromJSON(jsonValue));
    }

    /**
     */
    async getUserCountryAndPostalCode(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserCountryAndPostalCode> {
        const response = await this.getUserCountryAndPostalCodeRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserOwnedSubscriptionsRaw(requestParameters: GetUserOwnedSubscriptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Subscriptions>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getUserOwnedSubscriptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription/get-user-owned-subscriptions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionsFromJSON(jsonValue));
    }

    /**
     */
    async getUserOwnedSubscriptions(requestParameters: GetUserOwnedSubscriptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Subscriptions> {
        const response = await this.getUserOwnedSubscriptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getiframeUrlTokenForAdminDashboardRaw(requestParameters: GetiframeUrlTokenForAdminDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MetabaseDashboardToken>> {
        if (requestParameters.dashboardIndex === null || requestParameters.dashboardIndex === undefined) {
            throw new runtime.RequiredError('dashboardIndex','Required parameter requestParameters.dashboardIndex was null or undefined when calling getiframeUrlTokenForAdminDashboard.');
        }

        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getiframeUrlTokenForAdminDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin-dashboard/iframeUrlToken/{dashboardIndex}`.replace(`{${"dashboardIndex"}}`, encodeURIComponent(String(requestParameters.dashboardIndex))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MetabaseDashboardTokenFromJSON(jsonValue));
    }

    /**
     */
    async getiframeUrlTokenForAdminDashboard(requestParameters: GetiframeUrlTokenForAdminDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MetabaseDashboardToken> {
        const response = await this.getiframeUrlTokenForAdminDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async handleStripeEventRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/stripe-webhook/events`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async handleStripeEvent(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.handleStripeEventRaw(initOverrides);
    }

    /**
     */
    async isUserFollowingTenantRaw(requestParameters: IsUserFollowingTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IsFollowingTenant>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling isUserFollowingTenant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenant/is-following-tenant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IsFollowingTenantFromJSON(jsonValue));
    }

    /**
     */
    async isUserFollowingTenant(requestParameters: IsUserFollowingTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IsFollowingTenant> {
        const response = await this.isUserFollowingTenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async joinGroupRaw(requestParameters: JoinGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling joinGroup.');
        }

        if (requestParameters.groupInfoDto === null || requestParameters.groupInfoDto === undefined) {
            throw new runtime.RequiredError('groupInfoDto','Required parameter requestParameters.groupInfoDto was null or undefined when calling joinGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/join-group`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GroupInfoDtoToJSON(requestParameters.groupInfoDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async joinGroup(requestParameters: JoinGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.joinGroupRaw(requestParameters, initOverrides);
    }

    /**
     */
    async leaveGroupRaw(requestParameters: LeaveGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling leaveGroup.');
        }

        if (requestParameters.groupInfoDto === null || requestParameters.groupInfoDto === undefined) {
            throw new runtime.RequiredError('groupInfoDto','Required parameter requestParameters.groupInfoDto was null or undefined when calling leaveGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/leave-group`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GroupInfoDtoToJSON(requestParameters.groupInfoDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async leaveGroup(requestParameters: LeaveGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.leaveGroupRaw(requestParameters, initOverrides);
    }

    /**
     */
    async listFollowedOrganisationsRaw(requestParameters: ListFollowedOrganisationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupsAndSubscriptionsPerFollowedOrg>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling listFollowedOrganisations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenant/followed-organisations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupsAndSubscriptionsPerFollowedOrgFromJSON(jsonValue));
    }

    /**
     */
    async listFollowedOrganisations(requestParameters: ListFollowedOrganisationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupsAndSubscriptionsPerFollowedOrg> {
        const response = await this.listFollowedOrganisationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async logUserEventRaw(requestParameters: LogUserEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userEventDto === null || requestParameters.userEventDto === undefined) {
            throw new runtime.RequiredError('userEventDto','Required parameter requestParameters.userEventDto was null or undefined when calling logUserEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-activity/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserEventDtoToJSON(requestParameters.userEventDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async logUserEvent(requestParameters: LogUserEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logUserEventRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markSignUpAsDoneRaw(requestParameters: MarkSignUpAsDoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling markSignUpAsDone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/signup/mark-as-done`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markSignUpAsDone(requestParameters: MarkSignUpAsDoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markSignUpAsDoneRaw(requestParameters, initOverrides);
    }

    /**
     */
    async notificationsWebhookRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/twilio-webhook`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async notificationsWebhook(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.notificationsWebhookRaw(initOverrides);
    }

    /**
     */
    async registerUserRaw(requestParameters: RegisterUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.registerUserDto === null || requestParameters.registerUserDto === undefined) {
            throw new runtime.RequiredError('registerUserDto','Required parameter requestParameters.registerUserDto was null or undefined when calling registerUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-creation/register-user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterUserDtoToJSON(requestParameters.registerUserDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async registerUser(requestParameters: RegisterUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.registerUserRaw(requestParameters, initOverrides);
    }

    /**
     */
    async renewCurrentSubscriptionRaw(requestParameters: RenewCurrentSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Subscription>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling renewCurrentSubscription.');
        }

        if (requestParameters.renewSubscriptionDto === null || requestParameters.renewSubscriptionDto === undefined) {
            throw new runtime.RequiredError('renewSubscriptionDto','Required parameter requestParameters.renewSubscriptionDto was null or undefined when calling renewCurrentSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription/renew-subscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RenewSubscriptionDtoToJSON(requestParameters.renewSubscriptionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionFromJSON(jsonValue));
    }

    /**
     */
    async renewCurrentSubscription(requestParameters: RenewCurrentSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Subscription> {
        const response = await this.renewCurrentSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async retrieveUnseenFeedbacksRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserFeedback>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-feedback/retrieve-unseen-feedbacks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFeedbackFromJSON));
    }

    /**
     */
    async retrieveUnseenFeedbacks(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserFeedback>> {
        const response = await this.retrieveUnseenFeedbacksRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async sendReportViaEmailRaw(requestParameters: SendReportViaEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling sendReportViaEmail.');
        }

        if (requestParameters.reportDto === null || requestParameters.reportDto === undefined) {
            throw new runtime.RequiredError('reportDto','Required parameter requestParameters.reportDto was null or undefined when calling sendReportViaEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/send`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportDtoToJSON(requestParameters.reportDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendReportViaEmail(requestParameters: SendReportViaEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendReportViaEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendTimelineRaw(requestParameters: SendTimelineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling sendTimeline.');
        }

        if (requestParameters.timelineDto === null || requestParameters.timelineDto === undefined) {
            throw new runtime.RequiredError('timelineDto','Required parameter requestParameters.timelineDto was null or undefined when calling sendTimeline.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/timeline`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TimelineDtoToJSON(requestParameters.timelineDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendTimeline(requestParameters: SendTimelineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendTimelineRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setLastActiveTenantRaw(requestParameters: SetLastActiveTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.lastActiveTenant === null || requestParameters.lastActiveTenant === undefined) {
            throw new runtime.RequiredError('lastActiveTenant','Required parameter requestParameters.lastActiveTenant was null or undefined when calling setLastActiveTenant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/last-active-tenant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LastActiveTenantToJSON(requestParameters.lastActiveTenant),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setLastActiveTenant(requestParameters: SetLastActiveTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setLastActiveTenantRaw(requestParameters, initOverrides);
    }

    /**
     */
    async showFeedbackRaw(requestParameters: ShowFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.feedbacksDto === null || requestParameters.feedbacksDto === undefined) {
            throw new runtime.RequiredError('feedbacksDto','Required parameter requestParameters.feedbacksDto was null or undefined when calling showFeedback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-feedback/show-feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FeedbacksDtoToJSON(requestParameters.feedbacksDto),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async showFeedback(requestParameters: ShowFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.showFeedbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async submitUserFeedbackRaw(requestParameters: SubmitUserFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.submitFeedbackDto === null || requestParameters.submitFeedbackDto === undefined) {
            throw new runtime.RequiredError('submitFeedbackDto','Required parameter requestParameters.submitFeedbackDto was null or undefined when calling submitUserFeedback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-feedback/submit-feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubmitFeedbackDtoToJSON(requestParameters.submitFeedbackDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async submitUserFeedback(requestParameters: SubmitUserFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.submitUserFeedbackRaw(requestParameters, initOverrides);
    }

    /**
     */
    async switchSubscriptionRaw(requestParameters: SwitchSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Subscription>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling switchSubscription.');
        }

        if (requestParameters.switchSubscriptionDto === null || requestParameters.switchSubscriptionDto === undefined) {
            throw new runtime.RequiredError('switchSubscriptionDto','Required parameter requestParameters.switchSubscriptionDto was null or undefined when calling switchSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription/switch-subscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwitchSubscriptionDtoToJSON(requestParameters.switchSubscriptionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionFromJSON(jsonValue));
    }

    /**
     */
    async switchSubscription(requestParameters: SwitchSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Subscription> {
        const response = await this.switchSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async unlinkTemporaryTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-creation/unlink-temporary-token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async unlinkTemporaryToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unlinkTemporaryTokenRaw(initOverrides);
    }

    /**
     */
    async updateDefaultPaymentMethodRaw(requestParameters: UpdateDefaultPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling updateDefaultPaymentMethod.');
        }

        if (requestParameters.defaultPaymentMethodDto === null || requestParameters.defaultPaymentMethodDto === undefined) {
            throw new runtime.RequiredError('defaultPaymentMethodDto','Required parameter requestParameters.defaultPaymentMethodDto was null or undefined when calling updateDefaultPaymentMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stripe-customer/update-payment-method`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DefaultPaymentMethodDtoToJSON(requestParameters.defaultPaymentMethodDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateDefaultPaymentMethod(requestParameters: UpdateDefaultPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateDefaultPaymentMethodRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateOrganisationMemebershipsRaw(requestParameters: UpdateOrganisationMemebershipsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling updateOrganisationMemeberships.');
        }

        if (requestParameters.multipleMembershipsDto === null || requestParameters.multipleMembershipsDto === undefined) {
            throw new runtime.RequiredError('multipleMembershipsDto','Required parameter requestParameters.multipleMembershipsDto was null or undefined when calling updateOrganisationMemeberships.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/member-group/update-groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MultipleMembershipsDtoToJSON(requestParameters.multipleMembershipsDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateOrganisationMemeberships(requestParameters: UpdateOrganisationMemebershipsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateOrganisationMemebershipsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateSettingsRaw(requestParameters: UpdateSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Settings>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling updateSettings.');
        }

        if (requestParameters.updateSettingsDto === null || requestParameters.updateSettingsDto === undefined) {
            throw new runtime.RequiredError('updateSettingsDto','Required parameter requestParameters.updateSettingsDto was null or undefined when calling updateSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/settings`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSettingsDtoToJSON(requestParameters.updateSettingsDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsFromJSON(jsonValue));
    }

    /**
     */
    async updateSettings(requestParameters: UpdateSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Settings> {
        const response = await this.updateSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUserAttributesRaw(requestParameters: UpdateUserAttributesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateUserAttributesDto>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling updateUserAttributes.');
        }

        if (requestParameters.updateUserAttributesDto === null || requestParameters.updateUserAttributesDto === undefined) {
            throw new runtime.RequiredError('updateUserAttributesDto','Required parameter requestParameters.updateUserAttributesDto was null or undefined when calling updateUserAttributes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attributes`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserAttributesDtoToJSON(requestParameters.updateUserAttributesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateUserAttributesDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateUserAttributes(requestParameters: UpdateUserAttributesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateUserAttributesDto> {
        const response = await this.updateUserAttributesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUserDetailsRaw(requestParameters: UpdateUserDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling updateUserDetails.');
        }

        if (requestParameters.updateUserDetailsDto === null || requestParameters.updateUserDetailsDto === undefined) {
            throw new runtime.RequiredError('updateUserDetailsDto','Required parameter requestParameters.updateUserDetailsDto was null or undefined when calling updateUserDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/details`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserDetailsDtoToJSON(requestParameters.updateUserDetailsDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateUserDetails(requestParameters: UpdateUserDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateUserDetailsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async userExistsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserExists>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-creation/user-exists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserExistsFromJSON(jsonValue));
    }

    /**
     */
    async userExists(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserExists> {
        const response = await this.userExistsRaw(initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const AttachPaymentMethodMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type AttachPaymentMethodMamaDiseaseEnum = typeof AttachPaymentMethodMamaDiseaseEnum[keyof typeof AttachPaymentMethodMamaDiseaseEnum];
/**
 * @export
 */
export const AttachPaymentMethodMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type AttachPaymentMethodMamaOrganisationEnum = typeof AttachPaymentMethodMamaOrganisationEnum[keyof typeof AttachPaymentMethodMamaOrganisationEnum];
/**
 * @export
 */
export const CancelSubscriptionMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type CancelSubscriptionMamaDiseaseEnum = typeof CancelSubscriptionMamaDiseaseEnum[keyof typeof CancelSubscriptionMamaDiseaseEnum];
/**
 * @export
 */
export const CancelSubscriptionMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type CancelSubscriptionMamaOrganisationEnum = typeof CancelSubscriptionMamaOrganisationEnum[keyof typeof CancelSubscriptionMamaOrganisationEnum];
/**
 * @export
 */
export const ConnectAccountMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type ConnectAccountMamaDiseaseEnum = typeof ConnectAccountMamaDiseaseEnum[keyof typeof ConnectAccountMamaDiseaseEnum];
/**
 * @export
 */
export const ConnectAccountMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type ConnectAccountMamaOrganisationEnum = typeof ConnectAccountMamaOrganisationEnum[keyof typeof ConnectAccountMamaOrganisationEnum];
/**
 * @export
 */
export const CreateActiveSubscriptionMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type CreateActiveSubscriptionMamaDiseaseEnum = typeof CreateActiveSubscriptionMamaDiseaseEnum[keyof typeof CreateActiveSubscriptionMamaDiseaseEnum];
/**
 * @export
 */
export const CreateActiveSubscriptionMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type CreateActiveSubscriptionMamaOrganisationEnum = typeof CreateActiveSubscriptionMamaOrganisationEnum[keyof typeof CreateActiveSubscriptionMamaOrganisationEnum];
/**
 * @export
 */
export const CreateAndLinkStripeAccountMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type CreateAndLinkStripeAccountMamaDiseaseEnum = typeof CreateAndLinkStripeAccountMamaDiseaseEnum[keyof typeof CreateAndLinkStripeAccountMamaDiseaseEnum];
/**
 * @export
 */
export const CreateAndLinkStripeAccountMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type CreateAndLinkStripeAccountMamaOrganisationEnum = typeof CreateAndLinkStripeAccountMamaOrganisationEnum[keyof typeof CreateAndLinkStripeAccountMamaOrganisationEnum];
/**
 * @export
 */
export const CreateSubscriptionMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type CreateSubscriptionMamaDiseaseEnum = typeof CreateSubscriptionMamaDiseaseEnum[keyof typeof CreateSubscriptionMamaDiseaseEnum];
/**
 * @export
 */
export const CreateSubscriptionMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type CreateSubscriptionMamaOrganisationEnum = typeof CreateSubscriptionMamaOrganisationEnum[keyof typeof CreateSubscriptionMamaOrganisationEnum];
/**
 * @export
 */
export const CreateUserMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type CreateUserMamaDiseaseEnum = typeof CreateUserMamaDiseaseEnum[keyof typeof CreateUserMamaDiseaseEnum];
/**
 * @export
 */
export const CreateUserMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type CreateUserMamaOrganisationEnum = typeof CreateUserMamaOrganisationEnum[keyof typeof CreateUserMamaOrganisationEnum];
/**
 * @export
 */
export const FinishedSignUpViaCallMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type FinishedSignUpViaCallMamaDiseaseEnum = typeof FinishedSignUpViaCallMamaDiseaseEnum[keyof typeof FinishedSignUpViaCallMamaDiseaseEnum];
/**
 * @export
 */
export const FinishedSignUpViaCallMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type FinishedSignUpViaCallMamaOrganisationEnum = typeof FinishedSignUpViaCallMamaOrganisationEnum[keyof typeof FinishedSignUpViaCallMamaOrganisationEnum];
/**
 * @export
 */
export const GetCreditCardsMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type GetCreditCardsMamaDiseaseEnum = typeof GetCreditCardsMamaDiseaseEnum[keyof typeof GetCreditCardsMamaDiseaseEnum];
/**
 * @export
 */
export const GetCreditCardsMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type GetCreditCardsMamaOrganisationEnum = typeof GetCreditCardsMamaOrganisationEnum[keyof typeof GetCreditCardsMamaOrganisationEnum];
/**
 * @export
 */
export const GetMeMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type GetMeMamaDiseaseEnum = typeof GetMeMamaDiseaseEnum[keyof typeof GetMeMamaDiseaseEnum];
/**
 * @export
 */
export const GetMeMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type GetMeMamaOrganisationEnum = typeof GetMeMamaOrganisationEnum[keyof typeof GetMeMamaOrganisationEnum];
/**
 * @export
 */
export const GetPriceListMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type GetPriceListMamaDiseaseEnum = typeof GetPriceListMamaDiseaseEnum[keyof typeof GetPriceListMamaDiseaseEnum];
/**
 * @export
 */
export const GetPriceListMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type GetPriceListMamaOrganisationEnum = typeof GetPriceListMamaOrganisationEnum[keyof typeof GetPriceListMamaOrganisationEnum];
/**
 * @export
 */
export const GetProrationInvoiceMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type GetProrationInvoiceMamaDiseaseEnum = typeof GetProrationInvoiceMamaDiseaseEnum[keyof typeof GetProrationInvoiceMamaDiseaseEnum];
/**
 * @export
 */
export const GetProrationInvoiceMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type GetProrationInvoiceMamaOrganisationEnum = typeof GetProrationInvoiceMamaOrganisationEnum[keyof typeof GetProrationInvoiceMamaOrganisationEnum];
/**
 * @export
 */
export const GetStripeAccountMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type GetStripeAccountMamaDiseaseEnum = typeof GetStripeAccountMamaDiseaseEnum[keyof typeof GetStripeAccountMamaDiseaseEnum];
/**
 * @export
 */
export const GetStripeAccountMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type GetStripeAccountMamaOrganisationEnum = typeof GetStripeAccountMamaOrganisationEnum[keyof typeof GetStripeAccountMamaOrganisationEnum];
/**
 * @export
 */
export const GetStripeCustomerMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type GetStripeCustomerMamaDiseaseEnum = typeof GetStripeCustomerMamaDiseaseEnum[keyof typeof GetStripeCustomerMamaDiseaseEnum];
/**
 * @export
 */
export const GetStripeCustomerMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type GetStripeCustomerMamaOrganisationEnum = typeof GetStripeCustomerMamaOrganisationEnum[keyof typeof GetStripeCustomerMamaOrganisationEnum];
/**
 * @export
 */
export const GetTenantSettingsMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type GetTenantSettingsMamaDiseaseEnum = typeof GetTenantSettingsMamaDiseaseEnum[keyof typeof GetTenantSettingsMamaDiseaseEnum];
/**
 * @export
 */
export const GetTenantSettingsMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type GetTenantSettingsMamaOrganisationEnum = typeof GetTenantSettingsMamaOrganisationEnum[keyof typeof GetTenantSettingsMamaOrganisationEnum];
/**
 * @export
 */
export const GetUserMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type GetUserMamaDiseaseEnum = typeof GetUserMamaDiseaseEnum[keyof typeof GetUserMamaDiseaseEnum];
/**
 * @export
 */
export const GetUserMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type GetUserMamaOrganisationEnum = typeof GetUserMamaOrganisationEnum[keyof typeof GetUserMamaOrganisationEnum];
/**
 * @export
 */
export const GetUserOwnedSubscriptionsMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type GetUserOwnedSubscriptionsMamaDiseaseEnum = typeof GetUserOwnedSubscriptionsMamaDiseaseEnum[keyof typeof GetUserOwnedSubscriptionsMamaDiseaseEnum];
/**
 * @export
 */
export const GetUserOwnedSubscriptionsMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type GetUserOwnedSubscriptionsMamaOrganisationEnum = typeof GetUserOwnedSubscriptionsMamaOrganisationEnum[keyof typeof GetUserOwnedSubscriptionsMamaOrganisationEnum];
/**
 * @export
 */
export const GetiframeUrlTokenForAdminDashboardMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type GetiframeUrlTokenForAdminDashboardMamaDiseaseEnum = typeof GetiframeUrlTokenForAdminDashboardMamaDiseaseEnum[keyof typeof GetiframeUrlTokenForAdminDashboardMamaDiseaseEnum];
/**
 * @export
 */
export const GetiframeUrlTokenForAdminDashboardMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type GetiframeUrlTokenForAdminDashboardMamaOrganisationEnum = typeof GetiframeUrlTokenForAdminDashboardMamaOrganisationEnum[keyof typeof GetiframeUrlTokenForAdminDashboardMamaOrganisationEnum];
/**
 * @export
 */
export const IsUserFollowingTenantMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type IsUserFollowingTenantMamaDiseaseEnum = typeof IsUserFollowingTenantMamaDiseaseEnum[keyof typeof IsUserFollowingTenantMamaDiseaseEnum];
/**
 * @export
 */
export const IsUserFollowingTenantMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type IsUserFollowingTenantMamaOrganisationEnum = typeof IsUserFollowingTenantMamaOrganisationEnum[keyof typeof IsUserFollowingTenantMamaOrganisationEnum];
/**
 * @export
 */
export const JoinGroupMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type JoinGroupMamaDiseaseEnum = typeof JoinGroupMamaDiseaseEnum[keyof typeof JoinGroupMamaDiseaseEnum];
/**
 * @export
 */
export const JoinGroupMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type JoinGroupMamaOrganisationEnum = typeof JoinGroupMamaOrganisationEnum[keyof typeof JoinGroupMamaOrganisationEnum];
/**
 * @export
 */
export const LeaveGroupMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type LeaveGroupMamaDiseaseEnum = typeof LeaveGroupMamaDiseaseEnum[keyof typeof LeaveGroupMamaDiseaseEnum];
/**
 * @export
 */
export const LeaveGroupMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type LeaveGroupMamaOrganisationEnum = typeof LeaveGroupMamaOrganisationEnum[keyof typeof LeaveGroupMamaOrganisationEnum];
/**
 * @export
 */
export const ListFollowedOrganisationsMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type ListFollowedOrganisationsMamaDiseaseEnum = typeof ListFollowedOrganisationsMamaDiseaseEnum[keyof typeof ListFollowedOrganisationsMamaDiseaseEnum];
/**
 * @export
 */
export const MarkSignUpAsDoneMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type MarkSignUpAsDoneMamaDiseaseEnum = typeof MarkSignUpAsDoneMamaDiseaseEnum[keyof typeof MarkSignUpAsDoneMamaDiseaseEnum];
/**
 * @export
 */
export const MarkSignUpAsDoneMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type MarkSignUpAsDoneMamaOrganisationEnum = typeof MarkSignUpAsDoneMamaOrganisationEnum[keyof typeof MarkSignUpAsDoneMamaOrganisationEnum];
/**
 * @export
 */
export const RenewCurrentSubscriptionMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type RenewCurrentSubscriptionMamaDiseaseEnum = typeof RenewCurrentSubscriptionMamaDiseaseEnum[keyof typeof RenewCurrentSubscriptionMamaDiseaseEnum];
/**
 * @export
 */
export const RenewCurrentSubscriptionMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type RenewCurrentSubscriptionMamaOrganisationEnum = typeof RenewCurrentSubscriptionMamaOrganisationEnum[keyof typeof RenewCurrentSubscriptionMamaOrganisationEnum];
/**
 * @export
 */
export const SendReportViaEmailMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type SendReportViaEmailMamaDiseaseEnum = typeof SendReportViaEmailMamaDiseaseEnum[keyof typeof SendReportViaEmailMamaDiseaseEnum];
/**
 * @export
 */
export const SendReportViaEmailMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type SendReportViaEmailMamaOrganisationEnum = typeof SendReportViaEmailMamaOrganisationEnum[keyof typeof SendReportViaEmailMamaOrganisationEnum];
/**
 * @export
 */
export const SendTimelineMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type SendTimelineMamaDiseaseEnum = typeof SendTimelineMamaDiseaseEnum[keyof typeof SendTimelineMamaDiseaseEnum];
/**
 * @export
 */
export const SendTimelineMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type SendTimelineMamaOrganisationEnum = typeof SendTimelineMamaOrganisationEnum[keyof typeof SendTimelineMamaOrganisationEnum];
/**
 * @export
 */
export const SwitchSubscriptionMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type SwitchSubscriptionMamaDiseaseEnum = typeof SwitchSubscriptionMamaDiseaseEnum[keyof typeof SwitchSubscriptionMamaDiseaseEnum];
/**
 * @export
 */
export const SwitchSubscriptionMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type SwitchSubscriptionMamaOrganisationEnum = typeof SwitchSubscriptionMamaOrganisationEnum[keyof typeof SwitchSubscriptionMamaOrganisationEnum];
/**
 * @export
 */
export const UpdateDefaultPaymentMethodMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type UpdateDefaultPaymentMethodMamaDiseaseEnum = typeof UpdateDefaultPaymentMethodMamaDiseaseEnum[keyof typeof UpdateDefaultPaymentMethodMamaDiseaseEnum];
/**
 * @export
 */
export const UpdateDefaultPaymentMethodMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type UpdateDefaultPaymentMethodMamaOrganisationEnum = typeof UpdateDefaultPaymentMethodMamaOrganisationEnum[keyof typeof UpdateDefaultPaymentMethodMamaOrganisationEnum];
/**
 * @export
 */
export const UpdateOrganisationMemebershipsMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type UpdateOrganisationMemebershipsMamaDiseaseEnum = typeof UpdateOrganisationMemebershipsMamaDiseaseEnum[keyof typeof UpdateOrganisationMemebershipsMamaDiseaseEnum];
/**
 * @export
 */
export const UpdateOrganisationMemebershipsMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type UpdateOrganisationMemebershipsMamaOrganisationEnum = typeof UpdateOrganisationMemebershipsMamaOrganisationEnum[keyof typeof UpdateOrganisationMemebershipsMamaOrganisationEnum];
/**
 * @export
 */
export const UpdateSettingsMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type UpdateSettingsMamaDiseaseEnum = typeof UpdateSettingsMamaDiseaseEnum[keyof typeof UpdateSettingsMamaDiseaseEnum];
/**
 * @export
 */
export const UpdateUserAttributesMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type UpdateUserAttributesMamaDiseaseEnum = typeof UpdateUserAttributesMamaDiseaseEnum[keyof typeof UpdateUserAttributesMamaDiseaseEnum];
/**
 * @export
 */
export const UpdateUserAttributesMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type UpdateUserAttributesMamaOrganisationEnum = typeof UpdateUserAttributesMamaOrganisationEnum[keyof typeof UpdateUserAttributesMamaOrganisationEnum];
/**
 * @export
 */
export const UpdateUserDetailsMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Insomnia: 'INSOMNIA',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type UpdateUserDetailsMamaDiseaseEnum = typeof UpdateUserDetailsMamaDiseaseEnum[keyof typeof UpdateUserDetailsMamaDiseaseEnum];
/**
 * @export
 */
export const UpdateUserDetailsMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type UpdateUserDetailsMamaOrganisationEnum = typeof UpdateUserDetailsMamaOrganisationEnum[keyof typeof UpdateUserDetailsMamaOrganisationEnum];
