import { useT } from "../../i18n/use-t";
import { DataCollectionImplementation } from "../types";
import { FormTextInput } from "~/components/form/form-text-input";

const postalCodeMinLength = 3;
export const postalCodeInput: DataCollectionImplementation = {
  wasCompleted: (user) => !!user.userAttributes?.postalCode,
  Component: ({ errors, register }) => {
    const t = useT();
    return (
      <FormTextInput
        labelClassName="font-bold"
        title={{ tx: "userData.postalCode" }}
        error={{ txUnchecked: errors.postalCode?.message }}
        {...register("postalCode", {
          validate: (value) => {
            if (!value || value === "") {
              return t({ tx: "userData.postalCodeIsRequiredError" });
            }

            if (value.length < postalCodeMinLength) {
              return t({
                tx: "userData.postalCodeMustBeLonger",
                txData: { characters: postalCodeMinLength },
              });
            }
          },
        })}
      />
    );
  },
};
