import { useEffect, useMemo, useState } from "react";
import { IFramePage } from "shared/model/pages";

import { useGetPageInfo } from "../router/use-get-page-info";
import { TenantPage } from "../router/use-tenant-pages";
import { setCookie } from "../util/cookie";
import { useUser } from "../util/use-user";
import { useAuthContext } from "~/auth/auth-store";
import { PrimaryButton } from "~/components/form/button";
import { LoadingScreen } from "~/components/loading-screen";

export const IFrameRoute: React.FC<{
  page: TenantPage & { page: IFramePage };
}> = ({ page }) => {
  const user = useUser();
  const getPageInfo = useGetPageInfo();
  const iFrameUrlBase = useMemo(
    () => getPageInfo(page).iFrameUrl,
    [getPageInfo, page],
  );

  const [iFrameUrl, setIFrameUrl] = useState<string | undefined>();
  const { jwt } = useAuthContext();

  useEffect(() => {
    if (page.page.passJwt && jwt) {
      setCookie({
        name: "auth_jwt_token",
        value: jwt,
        domain: `.${location.hostname}`,
      });

      setIFrameUrl(
        `${iFrameUrlBase}?
        nickname=${user?.userAttributes?.name}
        &email=${user?.userAttributes?.email}
        &postalCode=${user?.userAttributes?.postalCode}
        &userId=${user?.id}`,
      );
    } else {
      setIFrameUrl(iFrameUrlBase);
    }
  }, [
    iFrameUrlBase,
    jwt,
    page.page.passJwt,
    user?.id,
    user?.userAttributes?.email,
    user?.userAttributes?.name,
    user?.userAttributes?.postalCode,
  ]);

  if (iFrameUrl && page.page.isPdfPage) {
    return (
      <>
        <div className="flex h-full w-full flex-col items-center justify-center md:hidden">
          <PrimaryButton
            className="w-40"
            tx="tabs.utils.openIframeButtonText"
            onClick={() => {
              openInNewTab(iFrameUrl);
            }}
          />
        </div>
        <iframe
          className="hidden h-full w-full md:flex"
          src={iFrameUrl}
          allowFullScreen={page.page.allowFullScreen}
        />
      </>
    );
  }

  if (iFrameUrl) {
    return (
      <iframe
        className="h-full w-full"
        src={iFrameUrl}
        allowFullScreen={page.page.allowFullScreen}
      />
    );
  }

  return <LoadingScreen />;
};

const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};
