import assert from "assert";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import { signOut as amplifySignOut } from "aws-amplify/auth";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { dispatchSigningOutToEventHub } from "./hub-dispatch";
import { AuthContextType, Jwt, JwtState, LoginState } from "./types";
import { getTimeToTokenRefresh } from "./utils/decode-jwt-methods";
import { LoadingScreen } from "~/components/loading-screen";
import {
  MamaNavigate,
  SpecialNavigationRoutes,
} from "~/navigation/mama-navigate";
import { consoleLogLocalhost } from "~/util/env-utils";
import { isErrorWithName } from "~/util/error";

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { loginState, ensureValidJwt, signOut } = useLoginState();

  const authInfo = useMemo<AuthContextType | undefined>(
    () =>
      loginState.state === JwtState.SUCCESS
        ? { jwt: loginState.jwt, ensureValidJwt, signOut }
        : undefined,
    [loginState, ensureValidJwt, signOut],
  );

  return loginState.state === JwtState.MISSING ? (
    <MamaNavigate to={SpecialNavigationRoutes.AUTH_FALLBACK} />
  ) : loginState.state === JwtState.SUCCESS && !!authInfo ? (
    <authContext.Provider value={authInfo}>{children}</authContext.Provider>
  ) : (
    <LoadingScreen />
  );
};

export const useMaybeAuthContext = (): AuthContextType | undefined => {
  return useContext(authContext);
};

export const useAuthContext = (): AuthContextType => {
  const maybeAuthContext = useMaybeAuthContext();
  assert(maybeAuthContext, "Expected auth context to be defined");

  return maybeAuthContext;
};

const authContext = createContext<AuthContextType | undefined>(undefined);

const useLoginState = (): {
  loginState: LoginState;
  ensureValidJwt: () => Promise<Jwt | undefined>;
  signOut: () => void;
} => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const internalJwtRef = useRef<Jwt | undefined>(undefined);
  const [loginState, internalSetLoginState] = useState<LoginState>({
    state: JwtState.PENDING,
  });

  const setJwtStateAndRef = useCallback(
    (newJwt: Jwt | undefined, newJwtState: JwtState) => {
      internalJwtRef.current = newJwt;
      internalSetLoginState(
        newJwtState === JwtState.SUCCESS && newJwt
          ? { jwt: newJwt, state: JwtState.SUCCESS }
          : newJwtState === JwtState.PENDING
          ? { state: JwtState.PENDING }
          : { state: JwtState.MISSING },
      );
    },
    [internalSetLoginState],
  );

  useEffect(() => {
    const assessLogInStateOnFirstLoad = async () => {
      const maybeExistingJwt = await getCurrentJwtFromStorage();
      if (maybeExistingJwt) {
        setJwtStateAndRef(maybeExistingJwt, JwtState.SUCCESS);
        return;
      }

      const refreshedJwt = await forceRefreshUserSession();
      if (refreshedJwt) {
        setJwtStateAndRef(refreshedJwt, JwtState.SUCCESS);
        return;
      }

      setJwtStateAndRef(undefined, JwtState.MISSING);
    };

    assessLogInStateOnFirstLoad();
  }, [setJwtStateAndRef]);

  useEffect(
    function attachNewRefreshTimeout() {
      if (loginState.state !== JwtState.SUCCESS) return;

      const refreshInterval = getTimeToTokenRefresh(loginState.jwt);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);

      consoleLogLocalhost(
        `set timeout to ${
          getTimeToTokenRefresh(loginState.jwt) / 1000
        } seconds`,
      );
      timeoutRef.current = setTimeout(async () => {
        const refreshedJwt = await forceRefreshUserSession();
        if (refreshedJwt) {
          setJwtStateAndRef(refreshedJwt, JwtState.SUCCESS);
          return;
        }
        setJwtStateAndRef(undefined, JwtState.MISSING);
      }, refreshInterval);

      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    },
    [loginState, setJwtStateAndRef],
  );

  const ensureValidJwt = useCallback(
    async (): Promise<Jwt | undefined> =>
      withMutex(async () => {
        const currentJwt = internalJwtRef.current;
        if (currentJwt && getTimeToTokenRefresh(currentJwt) > 0)
          return currentJwt;

        const newJwt = await forceRefreshUserSession();
        setJwtStateAndRef(newJwt, JwtState.SUCCESS);
        if (!newJwt) return;

        return newJwt;
      }),
    [setJwtStateAndRef],
  );

  const signOut = useCallback(async () => {
    await dispatchSigningOutToEventHub();
    // This needs to come first, otherwise the HubListener is triggered
    await amplifySignOut({ global: true });
    setJwtStateAndRef(undefined, JwtState.MISSING);
    clearSessionStorage();
  }, [setJwtStateAndRef]);

  return {
    loginState,
    ensureValidJwt,
    signOut,
  };
};

export const forceRefreshUserSession = async (): Promise<Jwt | undefined> => {
  try {
    await getCurrentUser();
    const session = await fetchAuthSession({ forceRefresh: true });
    const idToken = session.tokens?.idToken;
    const accessToken = session.tokens?.accessToken;

    if (accessToken && idToken) return accessToken?.toString();

    return undefined;
  } catch (error) {
    if (!isErrorWithName(error, "UserUnAuthenticatedException")) {
      console.error("Error refreshing user session", error);
    }
    return undefined;
  }
};

const getCurrentJwtFromStorage = async (): Promise<Jwt | undefined> => {
  try {
    const session = await fetchAuthSession({ forceRefresh: false });
    const idToken = session.tokens?.idToken;
    const accessToken = session.tokens?.accessToken;

    if (accessToken && idToken) return accessToken.toString();

    return undefined;
  } catch {
    return undefined;
  }
};

export const dirtyGetMaybeCurrentUserData = async (): Promise<
  | {
      accessToken: string;
      email: string;
      sub: string;
    }
  | undefined
> => {
  try {
    const session = await fetchAuthSession({
      forceRefresh: false,
    });
    const idToken = session.tokens?.idToken;
    const accessToken = session.tokens?.accessToken;
    if (
      !accessToken ||
      !idToken ||
      !idToken.payload.sub ||
      !idToken.payload.email ||
      typeof idToken.payload.email !== "string"
    )
      return undefined;

    return {
      accessToken: accessToken.toString(),
      email: idToken.payload.email,
      sub: idToken.payload.sub,
    };
  } catch {
    return undefined;
  }
};

let locked = false;
const waitingResolvers: Array<() => void> = [];
export async function withMutex<T>(callback: () => Promise<T>): Promise<T> {
  if (!locked) {
    locked = true;
  } else {
    await new Promise<void>((resolve) => {
      waitingResolvers.push(() => {
        locked = true;
        resolve();
      });
    });
  }

  try {
    return await callback();
  } finally {
    if (waitingResolvers.length > 0) {
      const nextResolve = waitingResolvers.shift();
      nextResolve?.();
    } else {
      locked = false;
    }
  }
}

const clearSessionStorage = () => {
  window.sessionStorage.clear();
};
