import React, { useCallback, useEffect, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";

import { useStore } from "../../../../../../models/helpers";
import { useAudioRecorder } from "../../../../../../util/use-record-audio";
import { PlainError } from "../../../../../events/plain-error";
import { AutoResizingTextInput } from "../../../../../form/auto-resizing-text-input";
import { Button } from "../../../../../form/button";
import { Image, ImageInput } from "../../../../../form/form-image-input";
import { SvgIcon } from "../../../../../icons/svg-icon";
import { Text } from "../../../../../text";
import {
  EditableAudio,
  EditableImages,
} from "../../../../generic/media-editor";
import { ChatOptionComponentProps } from "../lookup";

const HAS_SENT_MEDIA_KEY = "has-sent-media";

export const TextDrawer: React.FC<ChatOptionComponentProps> = ({
  sendResponse: onSendMessage,
}) => {
  const store = useStore();

  const [showMediaUploadMessage, setShowMediaUploadMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [userUploadedImages, setUserUploadedImages] = useState<Image[]>([]);
  const {
    toggleRecordAudio,
    removeRecording,
    base64Audio,
    isRecording,
    recordingDuration,
  } = useAudioRecorder();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowMediaUploadMessage(false);
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, [showMediaUploadMessage]);

  const hasSentMedia = useMemo(
    () => !!localStorage.getItem(HAS_SENT_MEDIA_KEY),
    [],
  );

  const removeImage = useCallback((image: Image) => {
    setUserUploadedImages((prev) =>
      prev.filter((img) => img.name !== image.name),
    );
  }, []);

  const selectImages = useCallback(
    (images: Image[]) => {
      const previousAndCurrentImages = [
        ...new Set([...userUploadedImages, ...images]),
      ];

      if (previousAndCurrentImages.length > 5) {
        return store.addToastEvent(
          new PlainError({ tx: "chat.errors.maximumFiveImages" }),
        );
      }

      setUserUploadedImages(previousAndCurrentImages);
    },
    [store, userUploadedImages],
  );

  const sendMessage = useCallback(() => {
    if (base64Audio === "data:") {
      removeRecording();
      return store.addToastEvent(
        new PlainError({ tx: "chat.errors.audioRecordingTooShort" }),
      );
    }

    if (userUploadedImages.length && !hasSentMedia) {
      localStorage.setItem(HAS_SENT_MEDIA_KEY, "true");
      setShowMediaUploadMessage(false);
    }

    const result = onSendMessage?.({
      message,
      base64Images: userUploadedImages.map(({ image }) => image),
      base64Audio,
      selection: [],
    });
    if (result.hasResponseBeenSent) {
      setMessage("");
      removeRecording();
      setUserUploadedImages([]);
    }
  }, [
    base64Audio,
    userUploadedImages,
    hasSentMedia,
    onSendMessage,
    message,
    removeRecording,
    store,
  ]);

  const showSendButton = useMemo(
    () => base64Audio || message || userUploadedImages.length,
    [base64Audio, message, userUploadedImages.length],
  );

  useEffect(() => {
    if (base64Audio && !isRecording) {
      sendMessage?.();
    }
  }, [base64Audio, isRecording, sendMessage]);

  return (
    <div className="flex w-full flex-row items-center justify-center gap-4">
      <EditableImages images={userUploadedImages} onRemoveImage={removeImage} />

      {base64Audio || isRecording ? null : (
        <div className="relative flex h-fit w-full flex-row">
          <AutoResizingTextInput
            className="resize-none pr-12 leading-snug"
            as="textarea"
            value={message}
            onKeyDown={(event) => {
              if (event.key === "Enter" && !event.shiftKey) {
                sendMessage();
                event.preventDefault();
                event.stopPropagation();
              }
            }}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />

          <div
            onClick={(e) => {
              if (!hasSentMedia && !showMediaUploadMessage) {
                setShowMediaUploadMessage(true);
                e.preventDefault();
              }
            }}
          >
            <ImageInput
              multiple
              className="indicator absolute bottom-0 right-0"
              onImageSelected={selectImages}
            >
              {userUploadedImages.length ? (
                <div className="badge badge-secondary badge-sm indicator-item">
                  {userUploadedImages.length}
                </div>
              ) : null}

              <SvgIcon width={45} height={45} icon="paperClip" />
            </ImageInput>
          </div>
        </div>
      )}

      {showMediaUploadMessage && (
        <Text
          className="absolute -top-[34px] bg-white text-xs text-slate-500"
          tx="chat.onlyJpgPngAllowed"
        />
      )}

      {base64Audio || isRecording ? (
        <div className="flex w-full items-center justify-center">
          <EditableAudio audio={base64Audio} onRemoveAudio={removeRecording} />
        </div>
      ) : null}

      {showSendButton ? (
        <Button
          className=" bg-bg-blue-900"
          iconColor="fill-mama-white"
          icon="paperPlane"
          onClick={sendMessage}
        />
      ) : (
        <Button
          icon="mic"
          className={twMerge(
            isRecording ? "color-error animate-pulse" : "bg-bg-blue-900",
            "self-center",
          )}
          onClick={toggleRecordAudio}
          iconColor={isRecording ? "fill-error" : "fill-mama-white"}
        >
          {isRecording && <Text text={recordingDuration} />}
        </Button>
      )}
    </div>
  );
};
