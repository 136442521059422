import { useCallback, useEffect, useRef, useState } from "react";

import { IUseWebSocket } from "./types/websocket";

export const usePublicWebSocket = <T extends object, U extends object>({
  url,
  key,
  query,
  onError,
  onResponse,
}: {
  url: string;
  key?: string;
  enabled?: boolean;
  query?: Record<string, string>;
  onError?: () => Promise<void> | void;
  onResponse?: (event?: MessageEvent<U>) => Promise<void> | void;
}): IUseWebSocket<T> => {
  const [isReady, setIsReady] = useState(false);

  const socketRef = useRef<WebSocket | null>(null);
  const prevKeyRef = useRef<string | undefined>(key);

  useEffect(() => {
    const hasKeyChanged = key !== prevKeyRef.current;

    if (
      socketRef.current &&
      (hasKeyChanged ||
        (query?.disease &&
          socketRef.current.url.includes("disease=") &&
          !socketRef.current.url.includes(`disease=${query.disease}`)))
    ) {
      socketRef.current.close();
      socketRef.current = null;
      setIsReady(false);
    }

    prevKeyRef.current = key;

    if (!socketRef.current) {
      const queryParams = new URLSearchParams({});

      Object.entries(query || {}).forEach(([paramKey, value]) => {
        queryParams.append(paramKey, value);
      });

      const fullUrl = `${url}?${queryParams.toString()}`;
      const socket = new WebSocket(fullUrl);

      socket.onmessage = (event) => {
        onResponse?.(JSON.parse(event.data));
      };

      socket.onclose = () => {
        socketRef.current = null;
        setIsReady(false);
      };

      socket.onerror = (event) => {
        setIsReady(false);
        console.log("WebSocket error", event);
        onError?.();
      };

      socket.onopen = () => {
        setIsReady(true);
      };

      socketRef.current = socket;
    }
  }, [url, key, query, onError, onResponse]);

  const sendMessage = useCallback((request: T) => {
    socketRef.current?.send(JSON.stringify(request));
  }, []);

  return { sendMessage, webSocket: socketRef.current, isReady };
};
