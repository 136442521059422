import { PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import { matchPath, Outlet, useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { Button } from "../../components/form/button";
import { NavbarRoute } from "../../components/header";
import { LanguageSelector } from "../../components/language-selector";
import { Text } from "../../components/text";
import { useT } from "../../i18n/use-t";
import {
  lastActiveTenantLocalStorageKey,
  lastActiveTenantSessionStorageKey,
} from "../../navigate-to-last-active-tenant";
import { useMamaNavigateState } from "../../navigation/use-mama-navigate-state";
import { useTenantConfig } from "../../tenant-settings";
import {
  RouteWithTitle,
  useMetadataForActiveRoute,
} from "../../util/metadata-for-active-route";
import { useTenantId } from "../../util/use-active-tenant-id";
import { AuthPagesEnum } from "../constants";

const ROOT = "/";

export type CognitoRouteMetadataProps = {
  routes: RouteWithTitle[];
};

export const CognitoLayout: React.FC<CognitoRouteMetadataProps> = ({
  routes,
}) => {
  const settings = useTenantConfig();
  const state = useMamaNavigateState();
  const messageToShow = useMemo(
    () => (state.error || state.info) ?? {},
    [state.error, state.info],
  );

  return (
    <AuthWrapper routes={routes} isFlat>
      <div className="flex h-full w-full  justify-center p-4">
        <div className="m-auto flex w-full max-w-[660px] flex-col gap-4">
          <img
            className="h-20 self-center object-contain object-bottom"
            src={settings?.authLogo}
          />
          <Text
            as="div"
            className={`w-full rounded pt-2 text-center text-lg	 ${
              state.error ? "text-mama-error" : ""
            }`}
            {...messageToShow}
          />
          <Outlet />
        </div>
      </div>
    </AuthWrapper>
  );
};

export const AuthWrapper: React.FC<
  PropsWithChildren<CognitoRouteMetadataProps & { isFlat?: boolean }>
> = ({ children, routes, isFlat }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const tenantId = useTenantId();
  const { pathname } = useLocation();
  const t = useT();

  const canNavigateToRoot = useMemo(
    () => new URLSearchParams(location.search).toString() === "",
    [location.search],
  );

  const removeLastActiveTenant = useCallback(() => {
    localStorage.removeItem(lastActiveTenantLocalStorageKey);
    sessionStorage.removeItem(lastActiveTenantSessionStorageKey);
  }, []);

  const isOrganisationSignUp = useMemo(
    () =>
      location.pathname.includes(AuthPagesEnum.SIGN_UP) &&
      tenantId.organisation,
    [location.pathname, tenantId.organisation],
  );

  useEffect(() => {
    if (isOrganisationSignUp) {
      navigate(AuthPagesEnum.SIGN_IN);
    }
  }, [isOrganisationSignUp, navigate]);

  useMetadataForActiveRoute(
    useMemo(
      () =>
        routes.map(
          (route): NavbarRoute => ({
            path: route.path,
            position: "start",
            title: t(route.title),
            isActive: !!matchPath(pathname, route.path),
          }),
        ),
      [pathname, routes, t],
    ),
  );

  return (
    <div className="flex h-full flex-col">
      <div
        className={twMerge(
          "sticky top-0 z-[1001] flex flex-row items-center justify-between bg-white p-4 ",
          isFlat ? "" : "shadow-xl",
        )}
      >
        {canNavigateToRoot ? (
          <Button
            onClick={() => {
              removeLastActiveTenant();
              navigate(ROOT);
            }}
            icon="left"
          />
        ) : (
          <div />
        )}
        <LanguageSelector className="max-w-[120px] self-end" />
      </div>
      <div className="flex h-full overflow-scroll">{children}</div>
    </div>
  );
};
