import { confirmSignUp } from "aws-amplify/auth";
import { useEffect, useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { ConfirmationCodeLinkContent } from "shared/types/auth";

import { useMamaNavigate } from "../../navigation/mama-navigate";
import { AuthPagesEnum } from "../constants";
import { useClientMetaData } from "../hooks/use-client-meta-data";
import { handleCognitoError } from "../utils/cognito-errors";
import { LoadingIndicator } from "~/components/loading-spinner";

export const CognitoConfirmSignUpCallback: React.FC = () => {
  const [params] = useSearchParams();
  const { sub, confirmation_code } = useMemo(() => {
    const keyVals = Object.fromEntries(params.entries());
    return keyVals as unknown as ConfirmationCodeLinkContent;
  }, [params]);

  const navigate = useMamaNavigate();
  const clientMetadata = useClientMetaData();
  const hasSentRequest = useRef(false);

  useEffect(() => {
    const executeAsync = async () => {
      try {
        if (!sub || !confirmation_code) return;
        if (hasSentRequest.current) return;

        hasSentRequest.current = true;
        const { isSignUpComplete } = await confirmSignUp({
          username: sub,
          confirmationCode: confirmation_code,
          options: {
            clientMetadata,
          },
        });

        if (isSignUpComplete) {
          navigate(AuthPagesEnum.SIGN_IN, {
            replace: true,
            info: {
              tx: "auth.signUp.registrationConfirmed",
            },
          });
          return;
        }

        navigate(AuthPagesEnum.SIGN_IN, {
          replace: true,
          error: {
            tx: "auth.signUp.confirmationLinkError",
          },
        });
      } catch (error) {
        navigate(AuthPagesEnum.SIGN_IN, {
          replace: true,
          error: handleCognitoError(error, { sub }),
        });
      }
    };

    executeAsync();
  }, [clientMetadata, confirmation_code, navigate, sub]);

  return (
    <div className="min-h-16 flex flex-row items-center justify-center self-stretch">
      <LoadingIndicator
        as="spinner"
        message={{ tx: "auth.signUp.confirmingSignUpWaitingMessage" }}
      />
    </div>
  );
};
