import React from "react";

import { useT } from "../../../../../../../i18n/use-t";
import { Button } from "../../../../../../form/button";
import { ChatOptionComponentProps } from "../../lookup";

export const Choices: React.FC<ChatOptionComponentProps> = ({
  choices,
  sendResponse,
}) => {
  const t = useT();

  return (
    <div className="mx-4 flex w-full flex-row items-center justify-center gap-1 md:w-80">
      {choices.map((choice) => (
        <Button
          className="w-fit min-w-[30%]"
          key={choice}
          tx={choice}
          onClick={() =>
            sendResponse({ message: t({ tx: choice }), selection: [choice] })
          }
        />
      ))}
    </div>
  );
};
