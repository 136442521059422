import { z, ZodSchema } from "zod";

type _GetNotFound = { value: null; err: null };
type _GetFound<T> = { value: T; err: null };
type _GetError<E extends Error> = { value: null; err: E };
type GetUnion<T, E extends Error = Error> =
  | _GetNotFound
  | _GetFound<T>
  | _GetError<E>;

type Options = Partial<{ shouldBeFound: boolean; deleteIfInvalid: boolean }>;

const defaultOpts: Options = Object.freeze({
  shouldBeFound: false,
  deleteIfInvalid: true,
});

const get = <T>(
  key: string,
  { shouldBeFound = false, deleteIfInvalid = true }: Options = defaultOpts,
): GetUnion<T> => {
  const found = localStorage.getItem(key);
  if (!found) {
    if (shouldBeFound) {
      return { value: null, err: new Error("should be found") };
    }

    return { value: null, err: null };
  }

  try {
    const value = JSON.parse(found);
    return { value, err: null };
  } catch (err) {
    console.groupCollapsed(`localStorage [${key}]`);
    console.error("not valid JSON", found, err);
    console.groupEnd();

    if (deleteIfInvalid) {
      localStorage.removeItem(key);
    }

    return { value: null, err: err as Error };
  }
};

const getValidated = <S extends ZodSchema>(
  key: string,
  schema: S,
  opts: Options = defaultOpts,
): GetUnion<z.infer<S>> => {
  const { value, err } = get(key, opts);
  if (err) {
    return { value, err };
  }
  if (value == null) {
    return { value, err: null };
  }

  const { success, data, error } = schema.safeParse(value);
  if (!success) {
    console.groupCollapsed(`localStorage [${key}]`);
    console.error("invalid value", value, error);
    console.groupEnd();

    if (opts.deleteIfInvalid) {
      localStorage.removeItem(key);
    }
    return { value: null, err: error };
  }

  return { value: data, err: null };
};

export { get, getValidated };
