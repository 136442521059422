/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const IeNodeType = {
    Approval: 'APPROVAL',
    Auxiliary: 'AUXILIARY',
    Dynamic: 'DYNAMIC',
    Modal: 'MODAL',
    MultipleChoice: 'MULTIPLE_CHOICE',
    MultipleChoiceOther: 'MULTIPLE_CHOICE_OTHER',
    MultipleEnum: 'MULTIPLE_ENUM',
    Question: 'QUESTION',
    Regex: 'REGEX',
    Scale: 'SCALE',
    Signup: 'SIGNUP',
    SingleChoice: 'SINGLE_CHOICE',
    SingleEnum: 'SINGLE_ENUM',
    Text: 'TEXT'
} as const;
export type IeNodeType = typeof IeNodeType[keyof typeof IeNodeType];


export function IeNodeTypeFromJSON(json: any): IeNodeType {
    return IeNodeTypeFromJSONTyped(json, false);
}

export function IeNodeTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): IeNodeType {
    return json as IeNodeType;
}

export function IeNodeTypeToJSON(value?: IeNodeType | null): any {
    return value as any;
}

