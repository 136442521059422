import { useEffect, useState } from "react";

import { useViewport } from "~/auth/hooks/use-viewport";
import { cn } from "~/util/cn";


const images = [
  "/resources/avatars/avatar-1.png",
  "/resources/avatars/avatar-2.png",
  "/resources/avatars/avatar-3.png",
  "/resources/avatars/avatar-4.png",
  "/resources/avatars/avatar-5.png",
  "/resources/avatars/avatar-6.png",
  "/resources/avatars/avatar-7.png",
  "/resources/avatars/avatar-8.png",
  "/resources/avatars/avatar-9.png",
  "/resources/avatars/avatar-10.png",
  "/resources/avatars/avatar-11.png",
  "/resources/avatars/avatar-12.png",
];

const shuffleArray = (array: string[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const PatientsAvatars: React.FC<{
  randomOrder?: boolean;
  conf?: Record<number, number> & { default: number };
}> = ({
  randomOrder,
  conf = {
    320: 8,
    380: 10,
    500: images.length,
    default: 6,
  },
}) => {
  const { width } = useViewport();
  const [shuffledImages, setShuffledImages] = useState(images);

  useEffect(() => {
    if (randomOrder) {
      setShuffledImages(shuffleArray([...images]));
    } else {
      setShuffledImages(images);
    }
  }, [randomOrder]);

  const displayedCount = Object.keys(conf)
    .map(Number)
    .filter((breakpoint) => width >= breakpoint)
    .reduce((acc, curr) => conf[curr], conf.default);

  const displayedImages = shuffledImages.slice(0, displayedCount);

  return (
    <div className="avatar-group pointer-events-none select-none -space-x-3">
      {displayedImages.map((image, index) => (
        <div className={cn("avatar border-0 shadow-sm")} key={index}>
          <div className="w-9">
            <img
              src={image}
              alt={`Avatar ${index + 1}`}
              className="object-cover"
            />
          </div>
        </div>
      ))}
    </div>
  );
};
