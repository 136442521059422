import { useState } from "react";


import { ChatOptionComponentProps } from "../lookup";
import { Button } from "~/components/form/button";
import { cn } from "~/util/cn";

const min = 0;
const max = 10;
const numberOfTicks = max - min;

export const Slider: React.FC<ChatOptionComponentProps> = ({
  sendResponse,
}) => {
  const [value, setValue] = useState((min + max) / 2);

  return (
    <div className="max-w-md flex-1">
      <input
        type="range"
        min={min}
        max={max}
        step="1"
        className="range range-primary range-md"
        value={value}
        onChange={(event) => setValue(event.target.valueAsNumber)}
      />
      <div className="mt-2 flex translate-x-0.5 justify-between text-sm font-medium">
        {Array.from({ length: numberOfTicks + 1 }, (_, index) => (
          <span
            key={index}
            className={cn(
              "block w-4",
              "transition-colors delay-75 duration-500 ease-in-out",
              min + index <= value
                ? "text-mama-default-secondary"
                : "text-mama-default-primary",
            )}
          >
            {min + index}
          </span>
        ))}
      </div>
      <div className="mt-4 flex items-center justify-center">
        <Button
          icon="paperPlane"
          onClick={() => {
            sendResponse({
              message: value.toString(),
              selection: [value.toString()],
            });
          }}
        />
      </div>
    </div>
  );
};
