import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { TranslationFeKey } from "shared/types/translation-key";

import { Modal as ModalComponent } from "../components/modal/modal";
import { I18nProps, TextProps } from "../components/text";

export type ModalConfig = PropsWithChildren<{
  title?: TextProps;
  onConfirm?: () => void;
  onClose?: () => void;
  ensureCanClose?: () => boolean;
  confirmButtonTx?: TranslationFeKey;
  closeButtonTx?: TranslationFeKey;
  description?: TextProps;
  loading?: { message?: I18nProps; isLoading: boolean };
  confirmLoading?: boolean;
  disabled?: {
    isCloseButtonDisabled?: boolean;
    isConfirmButtonDisabled?: boolean;
  };
  showsWindowCloseButton?: boolean;
  windowCloseButtonClassName?: string;
  showCloseButton?: boolean;
  overflowVisible?: boolean;
  closeOnClickOutside?: boolean;
  className?: string;
}>;

export interface ModalMethods {
  hideModal: () => void;
  showModal: (config: ModalConfig) => void;
  updateModal: (config: Partial<ModalConfig>) => void;
}

interface ModalConfigWithId extends ModalConfig {
  id: number;
}

interface ModalMethodsWithId {
  hideModal: (id?: number) => void;
  showModal: (config: ModalConfigWithId) => void;
  updateModal: (config: Partial<ModalConfigWithId> & { id: number }) => void;
}

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [openedModals, setOpenedModals] = useState<ModalConfigWithId[]>([]);
  const openedModalRef = useRef<ModalConfigWithId[]>([]);

  useEffect(() => {
    openedModalRef.current = openedModals;
  }, [openedModals]);

  const showModal = useCallback((props: ModalConfigWithId) => {
    setOpenedModals((modals) => [
      ...modals.filter((m) => m.id !== props.id),
      props,
    ]);
  }, []);

  const hideModal = useCallback((id?: number) => {
    // Warning: This needs to be outside the setOpenedModals function to avoid race conditions
    const maybeModal = openedModalRef.current.find((m) => m.id === id);
    if (maybeModal && (maybeModal?.ensureCanClose?.() ?? true)) {
      setOpenedModals((prev) => prev.filter((m) => m.id !== id));
    }
  }, []);

  const updateModal = useCallback((props: Partial<ModalConfigWithId>) => {
    setOpenedModals((modals) =>
      modals.map((modal) =>
        modal.id === props.id ? { ...modal, ...props } : modal,
      ),
    );
  }, []);

  return (
    <ModalContext.Provider
      value={useMemo(
        () => ({
          showModal,
          hideModal,
          updateModal,
        }),
        [showModal, hideModal, updateModal],
      )}
    >
      {openedModals.map((modal) => (
        <ModalComponent
          key={modal.id}
          close={() => hideModal(modal.id)}
          {...modal}
        />
      ))}
      {children}
    </ModalContext.Provider>
  );
};

let modalId = 0;
export const useModal = (): ModalMethods => {
  const idRef = useRef(modalId++);
  const context = useContext(ModalContext);

  const showModal = useCallback(
    (props: ModalConfig) => {
      context.showModal({ ...props, id: idRef.current });
    },
    [context],
  );

  const hideModal = useCallback(() => {
    context.hideModal(idRef.current);
  }, [context]);

  const updateModal = useCallback(
    (props: Partial<ModalConfig>) => {
      context.updateModal({ ...props, id: idRef.current });
    },
    [context],
  );

  return useMemo(
    () => ({
      showModal,
      hideModal,
      updateModal,
    }),
    [showModal, hideModal, updateModal],
  );
};

const noopModal: ModalMethods = {
  hideModal: () => {},
  showModal: () => {},
  updateModal: () => {},
};

const ModalContext = createContext<ModalMethodsWithId>(noopModal);
