import { Navigate, RouteObject } from "react-router-dom";
import { TenantIdentifier } from "shared/config";
import { basePathOfTenant } from "shared/config/base-urls";

import { AuthRoot } from "./auth-root";
import { CognitoConfirmSignUpCallback } from "./cognito-confirm-sign-up-callback";
import { CognitoLayout } from "./cognito-layout";
import { CognitoLoginProblem } from "./cognito-login-problem";
import { CognitoMagicLinkSent } from "./cognito-magic-link-sent";
import { CognitoMagicLinkSignIn } from "./cognito-magic-link-sign-in";
import { CognitoMagicLinkSignUp } from "./cognito-magic-link-sign-up";
import { CognitoMigrateAccount } from "./cognito-migrate-account/cognito-migrate-account";
import { CognitoNeedToMigrateAccountNotice } from "./cognito-migrate-account/cognito-need-to-migrate-account-notice";
import { CognitoSignIn } from "./cognito-sign-in";
import { CognitoSignUp } from "./cognito-sign-up";
import { CognitoUpdateEmailCallback } from "./cognito-update-email-callback";
import { CognitoUpdateEmailInfo } from "./cognito-update-email-info";
import { CognitoVerifyMagicLink } from "./cognito-verify-magic-link";
import { RouteWithTitle } from "../../util/metadata-for-active-route";
import { AuthPagesEnum } from "../constants";
import { OnboardingEntrypoint } from "~/components/chat/ai/ask/entrypoint";

const cognitoAuthRoutesWithLayout: RouteWithTitle[] = [
  {
    path: AuthPagesEnum.SIGN_IN,
    element: <CognitoSignIn />,
    title: {
      tx: "auth.signIn.title",
    },
  },
  {
    path: AuthPagesEnum.MAGIC_SIGN_IN,
    element: <CognitoMagicLinkSignIn />,
    title: {
      tx: "auth.signIn.title",
    },
  },
  {
    path: AuthPagesEnum.SIGN_UP,
    element: <CognitoSignUp />,
    title: {
      tx: "auth.signUp.title",
    },
  },
  {
    path: AuthPagesEnum.MAGIC_SIGN_UP,
    element: <CognitoMagicLinkSignUp />,
    title: {
      tx: "auth.signInMagic.title",
    },
  },
  {
    path: AuthPagesEnum.CONFIRM_SIGN_UP_CALLBACK,
    element: <CognitoConfirmSignUpCallback />,
    title: {
      tx: "auth.signUp.title",
    },
  },
  {
    path: AuthPagesEnum.UPDATE_EMAIL_CALLBACK,
    element: <CognitoUpdateEmailCallback />,
    title: {
      tx: "profile.confirmEmailUpdateTitle",
    },
  },
  {
    path: AuthPagesEnum.LOGIN_PROBLEM,
    element: <CognitoLoginProblem />,
    title: {
      tx: "auth.loginProblem.title",
    },
  },
  {
    path: AuthPagesEnum.UPDATE_EMAIL_INFO,
    element: <CognitoUpdateEmailInfo />,
    title: {
      tx: "profile.confirmEmailUpdateTitle",
    },
  },
  {
    path: AuthPagesEnum.NEED_TO_MIGRATE_ACCOUNT_NOTICE,
    element: <CognitoNeedToMigrateAccountNotice />,
    title: {
      tx: "auth.migrate.needToMigrateAccountNoticeTitle",
    },
  },
  {
    path: AuthPagesEnum.MIGRATE_ACCOUNT,
    element: <CognitoMigrateAccount />,
    title: {
      tx: "auth.migrate.migrateAccountTitle",
    },
  },
  {
    path: AuthPagesEnum.VERIFY_MAGIC_LINK,
    element: <CognitoVerifyMagicLink />,
    title: {
      tx: "auth.migrate.migrateAccountTitle",
    },
  },
  {
    path: AuthPagesEnum.MAGIC_LINK_SENT,
    element: <CognitoMagicLinkSent />,
    title: {
      tx: "auth.migrate.migrateAccountTitle",
    },
  },
];

export const getCognitoAuthRoutes = (
  activeTenant: TenantIdentifier,
): RouteObject[] => {
  const basePath = basePathOfTenant(activeTenant);
  const routesForTenant = cognitoAuthRoutesWithLayout.map((route) => ({
    ...route,
    path: basePath + route.path,
  }));

  return [
    {
      path: basePath + AuthPagesEnum.ONBOARDING,
      element: <OnboardingEntrypoint />,
    },
    {
      path: basePath + "/auth",
      element: <CognitoLayout routes={routesForTenant} />,
      children: [
        ...routesForTenant.map((route) => ({
          path: route.path,
          element: route.element,
        })),
        {
          path: basePath + "/auth/*",
          element: <Navigate to={basePath} replace={true} />,
        },
        {
          path: basePath + "/auth/",
          index: true,
          element: <AuthRoot />,
        },
      ],
    },
  ];
};
