import { useMemo } from "react";

import { ChatMessageDocument } from "./chat-message-document";
import { Text } from "../../text";
import { ChatUserMediaRecord } from "../types";

export const ChatMessageUser: React.FC<{
  text?: string;
  userChatColors?: Partial<{ bubbleColor: string; textColor: string }>;
  media?: ChatUserMediaRecord[];
}> = ({ text = " ", userChatColors, media }) => {
  const style = useMemo(
    () =>
      !!userChatColors
        ? {
            backgroundColor: userChatColors.bubbleColor,
            color: userChatColors.textColor,
          }
        : {},
    [userChatColors],
  );

  return (
    <Text
      as="div"
      style={style}
      className="chat-bubble chat-bubble-primary whitespace-pre-line text-bg-blue-900"
      text={text}
    >
      <ChatMessageDocument media={media} />
    </Text>
  );
};
