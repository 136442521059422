import { FrontendPageEnum } from "shared/model/pages";

import { Link } from "./link";
import { Text } from "./text";
import { DefaultContentContainer } from "../layouts/default-content-container";

export const EmptyJourney: React.FC = () => {
  return (
    <DefaultContentContainer className="flex h-full items-center justify-center">
      <Text
        className="text-center text-2xl"
        tx="journey.noEventsEnteredYet"
        txComponents={{
          ChatLink: <Link href={FrontendPageEnum.CHAT} />,
        }}
      />
    </DefaultContentContainer>
  );
};
