import {
  backendApiUrl,
  landbotApiUrl,
  multiagentApiUrl,
} from "../../util/env-utils";
import {
  DefaultApi as BackendApi,
  ConfigurationParameters as BackendConfigurationParameters,
  ErrorContext as BackendErrorContext,
  RequestContext as BackendRequestContext,
  ResponseContext as BackendResponseContext,
  GetMeMamaDiseaseEnum as DiseaseEnum,
  GetMeMamaOrganisationEnum as OrganisationEnum,
} from "../generated/backend";
import {
  DefaultApi as LandbotApi,
  ConfigurationParameters as LandbotConfigurationParameters,
  ErrorContext as LandbotErrorContext,
  RequestContext as LandbotRequestContext,
  ResponseContext as LandbotResponseContext,
} from "../generated/landbot";
import {
  DefaultApi as MultiagentApi,
  ConfigurationParameters as MultiagentConfigurationParameters,
  ErrorContext as MultiagentErrorContext,
  RequestContext as MultiagentRequestContext,
  ResponseContext as MultiagentResponseContext,
} from "../generated/multiagent";

export type ApiClientService = "backend" | "multiagent" | "landbot";

export type ApiClient<TClient extends ApiClientService> =
  TClient extends "backend"
    ? BackendApi
    : TClient extends "multiagent"
    ? MultiagentApi
    : LandbotApi;

export const apiUrl: Record<ApiClientService, string> = {
  backend: backendApiUrl,
  multiagent: multiagentApiUrl,
  landbot: landbotApiUrl,
};

export type TenantIdHeader = {
  mamaDisease: DiseaseEnum;
  mamaOrganisation?: OrganisationEnum;
};

export type RequestContext =
  | BackendRequestContext
  | MultiagentRequestContext
  | LandbotRequestContext;

export type ResponseContext =
  | BackendResponseContext
  | MultiagentResponseContext
  | LandbotResponseContext;

export type ErrorContext =
  | BackendErrorContext
  | MultiagentErrorContext
  | LandbotErrorContext;

export type ConfigurationParameters =
  | BackendConfigurationParameters
  | MultiagentConfigurationParameters
  | LandbotConfigurationParameters;

export type PrivateApiContextType = {
  backend: ApiClient<"backend">;
  multiagent: ApiClient<"multiagent">;
  landbot: ApiClient<"landbot">;
};
