import { useCallback } from "react";

import { useClientMetaData } from "./use-client-meta-data";
import {
  SpecialNavigationRoutes,
  useMamaNavigate,
} from "../../navigation/mama-navigate";
import {
  AuthMessages,
  AuthMessageTypesEnum,
  AuthPagesEnum,
} from "../constants";
import { signUpRetry } from "../utils/auth-methods";
import { handleCognitoError } from "../utils/cognito-errors";

export const useHandleSignUp = (): (({
  email,
  name,
  password,
}: {
  email: string;
  name: string;
  password: string;
}) => Promise<void>) => {
  const clientMetadata = useClientMetaData();
  const navigate = useMamaNavigate();

  return useCallback(
    async ({
      email,
      name,
      password,
    }: {
      email: string;
      name: string;
      password: string;
    }) => {
      try {
        const { isSignUpComplete } = await signUpRetry({
          username: email,
          password,
          options: {
            userAttributes: {
              name,
              email,
            },
            autoSignIn: false,
            clientMetadata,
          },
        });

        if (isSignUpComplete) {
          navigate(AuthPagesEnum.SIGN_IN, {
            info: {
              tx: "auth.signUp.autoConfirmedRegistrationComplete",
            },
            replace: true,
          });
        }

        navigate(AuthPagesEnum.AUTH_ROOT, {
          info: AuthMessages[AuthMessageTypesEnum.CONFIRM_SIGN_UP],
          replace: true,
        });
      } catch (error) {
        navigate(SpecialNavigationRoutes.RELOAD, {
          replace: true,
          error: handleCognitoError(error, { email }),
        });
      }
    },
    [clientMetadata, navigate],
  );
};
