"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataToBeCollectedEnum = void 0;
var DataToBeCollectedEnum;
(function (DataToBeCollectedEnum) {
    DataToBeCollectedEnum["NAME"] = "NAME";
    DataToBeCollectedEnum["EMAIL"] = "EMAIL";
    DataToBeCollectedEnum["PHONE_NUMBER"] = "PHONE_NUMBER";
    DataToBeCollectedEnum["YEAR_OF_BIRTH"] = "YEAR_OF_BIRTH";
    DataToBeCollectedEnum["GENDER"] = "GENDER";
    DataToBeCollectedEnum["POSTAL_CODE"] = "POSTAL_CODE";
    DataToBeCollectedEnum["COUNTRY"] = "COUNTRY";
    DataToBeCollectedEnum["ACQUISITION_DETAIL"] = "ACQUISITION_DETAIL";
    DataToBeCollectedEnum["PRIVACY_POLICY_2023_10_18"] = "PRIVACY_POLICY_2023_10_18";
    DataToBeCollectedEnum["TERMS_AND_CONDITIONS_2023_08_30"] = "TERMS_AND_CONDITIONS_2023_08_30";
    DataToBeCollectedEnum["ENDO_STUDY_INFORMATION_2024_01_15"] = "ENDO_STUDY_INFORMATION_2024_01_15";
    DataToBeCollectedEnum["OPADE_PATIENT_ID"] = "OPADE_PATIENT_ID";
    DataToBeCollectedEnum["LANGUAGE"] = "LANGUAGE";
})(DataToBeCollectedEnum || (exports.DataToBeCollectedEnum = DataToBeCollectedEnum = {}));
