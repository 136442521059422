/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserAttributes
 */
export interface UserAttributes {
    /**
     * 
     * @type {boolean}
     * @memberof UserAttributes
     */
    finishedSignUp: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAttributes
     */
    finishedSignUpViaCall: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAttributes
     */
    watchedOnboardingVideo?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAttributes
     */
    hasSeenAllCards?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    language?: UserAttributesLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    gender?: UserAttributesGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    acquisitionSource?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    acquisitionDetail?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    utmSource?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    utmCampaign?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    utmContent?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    opadePatientId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    hometown?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    currentTown?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    occupation?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    introduction?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    lookingForOnMamaHealth?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    lookingForOthersOnMamaHealth?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserAttributes
     */
    agreedToEndoStudyInformation20240115?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAttributes
     */
    agreedToPrivacyPolicy20231018?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAttributes
     */
    agreedToTermsAndConditions20230830?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    telephone?: string;
}


/**
 * @export
 */
export const UserAttributesLanguageEnum = {
    EnUs: 'en-US',
    DeCh: 'de-CH',
    DeDe: 'de-DE',
    ItIt: 'it-IT',
    EsEs: 'es-ES',
    EsCo: 'es-CO',
    NlNl: 'nl-NL',
    TrTr: 'tr-TR',
    FrFr: 'fr-FR'
} as const;
export type UserAttributesLanguageEnum = typeof UserAttributesLanguageEnum[keyof typeof UserAttributesLanguageEnum];

/**
 * @export
 */
export const UserAttributesGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Intersex: 'INTERSEX'
} as const;
export type UserAttributesGenderEnum = typeof UserAttributesGenderEnum[keyof typeof UserAttributesGenderEnum];


/**
 * Check if a given object implements the UserAttributes interface.
 */
export function instanceOfUserAttributes(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "finishedSignUp" in value;
    isInstance = isInstance && "finishedSignUpViaCall" in value;

    return isInstance;
}

export function UserAttributesFromJSON(json: any): UserAttributes {
    return UserAttributesFromJSONTyped(json, false);
}

export function UserAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAttributes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'finishedSignUp': json['finishedSignUp'],
        'finishedSignUpViaCall': json['finishedSignUpViaCall'],
        'watchedOnboardingVideo': !exists(json, 'watchedOnboardingVideo') ? undefined : json['watchedOnboardingVideo'],
        'hasSeenAllCards': !exists(json, 'hasSeenAllCards') ? undefined : json['hasSeenAllCards'],
        'avatar': !exists(json, 'avatar') ? undefined : json['avatar'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : json['dateOfBirth'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'acquisitionSource': !exists(json, 'acquisitionSource') ? undefined : json['acquisitionSource'],
        'acquisitionDetail': !exists(json, 'acquisitionDetail') ? undefined : json['acquisitionDetail'],
        'utmSource': !exists(json, 'utmSource') ? undefined : json['utmSource'],
        'utmCampaign': !exists(json, 'utmCampaign') ? undefined : json['utmCampaign'],
        'utmContent': !exists(json, 'utmContent') ? undefined : json['utmContent'],
        'opadePatientId': !exists(json, 'opadePatientId') ? undefined : json['opadePatientId'],
        'hometown': !exists(json, 'hometown') ? undefined : json['hometown'],
        'currentTown': !exists(json, 'currentTown') ? undefined : json['currentTown'],
        'occupation': !exists(json, 'occupation') ? undefined : json['occupation'],
        'introduction': !exists(json, 'introduction') ? undefined : json['introduction'],
        'lookingForOnMamaHealth': !exists(json, 'lookingForOnMamaHealth') ? undefined : json['lookingForOnMamaHealth'],
        'lookingForOthersOnMamaHealth': !exists(json, 'lookingForOthersOnMamaHealth') ? undefined : json['lookingForOthersOnMamaHealth'],
        'agreedToEndoStudyInformation20240115': !exists(json, 'agreedToEndoStudyInformation20240115') ? undefined : json['agreedToEndoStudyInformation20240115'],
        'agreedToPrivacyPolicy20231018': !exists(json, 'agreedToPrivacyPolicy20231018') ? undefined : json['agreedToPrivacyPolicy20231018'],
        'agreedToTermsAndConditions20230830': !exists(json, 'agreedToTermsAndConditions20230830') ? undefined : json['agreedToTermsAndConditions20230830'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
    };
}

export function UserAttributesToJSON(value?: UserAttributes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'finishedSignUp': value.finishedSignUp,
        'finishedSignUpViaCall': value.finishedSignUpViaCall,
        'watchedOnboardingVideo': value.watchedOnboardingVideo,
        'hasSeenAllCards': value.hasSeenAllCards,
        'avatar': value.avatar,
        'language': value.language,
        'dateOfBirth': value.dateOfBirth,
        'gender': value.gender,
        'country': value.country,
        'postalCode': value.postalCode,
        'city': value.city,
        'address': value.address,
        'acquisitionSource': value.acquisitionSource,
        'acquisitionDetail': value.acquisitionDetail,
        'utmSource': value.utmSource,
        'utmCampaign': value.utmCampaign,
        'utmContent': value.utmContent,
        'opadePatientId': value.opadePatientId,
        'hometown': value.hometown,
        'currentTown': value.currentTown,
        'occupation': value.occupation,
        'introduction': value.introduction,
        'lookingForOnMamaHealth': value.lookingForOnMamaHealth,
        'lookingForOthersOnMamaHealth': value.lookingForOthersOnMamaHealth,
        'agreedToEndoStudyInformation20240115': value.agreedToEndoStudyInformation20240115,
        'agreedToPrivacyPolicy20231018': value.agreedToPrivacyPolicy20231018,
        'agreedToTermsAndConditions20230830': value.agreedToTermsAndConditions20230830,
        'name': value.name,
        'email': value.email,
        'telephone': value.telephone,
    };
}

