import { confirmUserAttribute } from "aws-amplify/auth";
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { LoadingIndicator } from "../../components/loading-spinner";
import { AuthPagesEnum } from "../constants";
import { handleCognitoError } from "../utils/cognito-errors";
import { useMamaNavigate } from "~/navigation/mama-navigate";
import { useStrictModeSafeEffect } from "~/util/hooks";

export const CognitoUpdateEmailCallback: React.FC = () => {
  const [params] = useSearchParams();
  const sub = useMemo(() => params.get("sub"), [params]);
  const confirmationCode = useMemo(
    () => params.get("confirmation_code"),
    [params],
  );
  const email = useMemo(() => params.get("email"), [params]);

  const navigate = useMamaNavigate(email ? () => ({ email }) : undefined);

  const handleEmailUpdate = useCallback(async () => {
    if (sub && confirmationCode) {
      try {
        await confirmUserAttribute({
          userAttributeKey: "email",
          confirmationCode,
        });

        navigate(AuthPagesEnum.UPDATE_EMAIL_INFO, {
          replace: true,
          info: {
            tx: "profile.updateEmailSuccessful",
          },
        });
      } catch (error) {
        navigate(AuthPagesEnum.UPDATE_EMAIL_INFO, {
          replace: true,
          info: handleCognitoError(error, { id: sub }),
        });
      }
    }
  }, [confirmationCode, navigate, sub]);

  useStrictModeSafeEffect(handleEmailUpdate);

  return (
    <div className="min-h-16 flex flex-row items-center justify-center self-stretch">
      <LoadingIndicator
        as="spinner"
        message={{ tx: "profile.confirmingEmailWaitingMessage" }}
      />
    </div>
  );
};
