import { ChatUserMediaRecord } from "../types";
import { MimeMainType } from "~/types/mime-main-type";

export const ChatMessageDocument: React.FC<{
  media?: ChatUserMediaRecord[];
}> = ({ media }) => {
  if (!media?.length) return null;

  return (
    <div className="flex flex-wrap justify-center gap-2 py-2">
      {media?.map((m, idx) =>
        m.mainType === MimeMainType.IMAGE ? (
          <img
            className="h-full max-h-48 rounded-xl object-cover"
            key={`${m.path}-${idx}`}
            alt={`${m.path}-${idx}`}
            src={m.path}
          />
        ) : m.mainType === MimeMainType.AUDIO ? (
          <audio key={`${m.path}-${idx}`} controls src={m.path} />
        ) : null,
      )}
    </div>
  );
};
