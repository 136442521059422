import { ChevronLeft } from "lucide-react";


import { useCredentials } from "~/auth/hooks/use-credentials";
import { Text } from "~/components/text";
import { useT } from "~/i18n/use-t";
import { cn } from "~/util/cn";

export const RestartButton: React.FC<{ className: string }> = ({
  className,
}) => {
  const { reset } = useCredentials();
  const t = useT();

  return (
    <div
      className={cn(
        "my-8 flex w-full max-w-screen-sm items-center justify-start",
        className,
      )}
    >
      <button
        title={t({ tx: "graph.text.restart" })}
        onClick={() => {
          reset();
          window.location.reload();
        }}
        className="btn-ghost btn-sm btn text-slate-500 hover:text-slate-900"
      >
        <ChevronLeft />
        <Text tx="graph.text.restart" />
      </button>
    </div>
  );
};
