import { useEffect, useRef } from "react";
import { TranslationFeKey } from "shared/types/translation-key";
import { twMerge } from "tailwind-merge";

import { ChatMessageComponent } from "~/components/chat/generic/chat-message";
import { ChatMessage, ChatRole } from "~/components/chat/types";
import { LoadingIndicator } from "~/components/loading-spinner";

export type MessageListProps = {
  isChatBusy: boolean;
  chatMessages: ChatMessage[];
  chatLoadingMessage?: TranslationFeKey;
  userChatColors?: { bubbleColor?: string; textColor?: string };
};

export const MessageList: React.FC<MessageListProps> = ({
  isChatBusy,
  chatMessages,
  chatLoadingMessage,
  userChatColors,
}) => {
  const bottomRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatMessages[chatMessages.length - 1]?.role === ChatRole.USER) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    chatContainerRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatMessages]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <div className="grow overflow-y-auto">
      {chatMessages.map((chatMessage, index) => (
        <div
          key={index}
          ref={index === chatMessages.length - 1 ? chatContainerRef : undefined}
        >
          <ChatMessageComponent
            message={chatMessage}
            className={index === 0 ? "mt-4" : ""}
            userChatColors={userChatColors}
          />
        </div>
      ))}

      {isChatBusy && (
        <div className="py-2">
          <LoadingIndicator
            as="dots"
            message={{ tx: chatLoadingMessage ?? "chat.loading" }}
            className={twMerge(isChatBusy ? "justify-start" : "justify-end")}
          />
        </div>
      )}
      <div ref={bottomRef} />
    </div>
  );
};
