import { useCallback } from "react";

import { useClientMetaData } from "./use-client-meta-data";
import {
  SpecialNavigationRoutes,
  useMamaNavigate,
} from "../../navigation/mama-navigate";
import { AuthPagesEnum, SignInNextStepEnum } from "../constants";
import { signInRetry } from "../utils/auth-methods";
import { handleCognitoError } from "../utils/cognito-errors";

export const useHandleSignIn = (): (({
  email,
  password,
}: {
  email: string;
  password: string;
}) => Promise<void>) => {
  const navigate = useMamaNavigate();
  const clientMetadata = useClientMetaData();

  return useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      try {
        const { isSignedIn, nextStep } = await signInRetry({
          username: email,
          password: password,
          options: { clientMetadata },
        });

        if (isSignedIn) {
          navigate(SpecialNavigationRoutes.HOME);
          return;
        }

        if (
          nextStep.signInStep ===
          SignInNextStepEnum.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED
        ) {
          navigate(AuthPagesEnum.NEED_TO_MIGRATE_ACCOUNT_NOTICE);
          return;
        }

        navigate(AuthPagesEnum.SIGN_IN, {
          replace: true,
          error: {
            tx: "auth.signIn.errors.unexpectedSignInChallenge",
          },
        });
      } catch (error) {
        navigate(AuthPagesEnum.SIGN_IN, {
          replace: true,
          error: handleCognitoError(error, { email }),
        });
      }
    },
    [clientMetadata, navigate],
  );
};
