/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Conversation } from './Conversation';
import {
    ConversationFromJSON,
    ConversationFromJSONTyped,
    ConversationToJSON,
} from './Conversation';
import type { DiseaseSettings } from './DiseaseSettings';
import {
    DiseaseSettingsFromJSON,
    DiseaseSettingsFromJSONTyped,
    DiseaseSettingsToJSON,
} from './DiseaseSettings';
import type { Event } from './Event';
import {
    EventFromJSON,
    EventFromJSONTyped,
    EventToJSON,
} from './Event';
import type { GroupsOfUser } from './GroupsOfUser';
import {
    GroupsOfUserFromJSON,
    GroupsOfUserFromJSONTyped,
    GroupsOfUserToJSON,
} from './GroupsOfUser';
import type { LandbotCaseData } from './LandbotCaseData';
import {
    LandbotCaseDataFromJSON,
    LandbotCaseDataFromJSONTyped,
    LandbotCaseDataToJSON,
} from './LandbotCaseData';
import type { LandbotEventData } from './LandbotEventData';
import {
    LandbotEventDataFromJSON,
    LandbotEventDataFromJSONTyped,
    LandbotEventDataToJSON,
} from './LandbotEventData';
import type { Notification } from './Notification';
import {
    NotificationFromJSON,
    NotificationFromJSONTyped,
    NotificationToJSON,
} from './Notification';
import type { OrganisationSettings } from './OrganisationSettings';
import {
    OrganisationSettingsFromJSON,
    OrganisationSettingsFromJSONTyped,
    OrganisationSettingsToJSON,
} from './OrganisationSettings';
import type { Report } from './Report';
import {
    ReportFromJSON,
    ReportFromJSONTyped,
    ReportToJSON,
} from './Report';
import type { StripeInfo } from './StripeInfo';
import {
    StripeInfoFromJSON,
    StripeInfoFromJSONTyped,
    StripeInfoToJSON,
} from './StripeInfo';
import type { UserActivityLog } from './UserActivityLog';
import {
    UserActivityLogFromJSON,
    UserActivityLogFromJSONTyped,
    UserActivityLogToJSON,
} from './UserActivityLog';
import type { UserAttributes } from './UserAttributes';
import {
    UserAttributesFromJSON,
    UserAttributesFromJSONTyped,
    UserAttributesToJSON,
} from './UserAttributes';
import type { UserDetail } from './UserDetail';
import {
    UserDetailFromJSON,
    UserDetailFromJSONTyped,
    UserDetailToJSON,
} from './UserDetail';
import type { UserFeedback } from './UserFeedback';
import {
    UserFeedbackFromJSON,
    UserFeedbackFromJSONTyped,
    UserFeedbackToJSON,
} from './UserFeedback';
import type { UserSettings } from './UserSettings';
import {
    UserSettingsFromJSON,
    UserSettingsFromJSONTyped,
    UserSettingsToJSON,
} from './UserSettings';

/**
 * Represents a User record
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    cognitoSub: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isRegistered: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    temporaryToken?: string;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    createdAt: Date;
    /**
     * 
     * @type {DiseaseSettings}
     * @memberof User
     */
    lastActiveDisease?: DiseaseSettings;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastActiveDiseaseId: string;
    /**
     * 
     * @type {OrganisationSettings}
     * @memberof User
     */
    lastActiveOrganisation?: OrganisationSettings;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastActiveOrganisationId?: string;
    /**
     * 
     * @type {Array<UserSettings>}
     * @memberof User
     */
    settings?: Array<UserSettings>;
    /**
     * 
     * @type {Array<StripeInfo>}
     * @memberof User
     */
    stripeInfo?: Array<StripeInfo>;
    /**
     * 
     * @type {Array<UserDetail>}
     * @memberof User
     */
    details?: Array<UserDetail>;
    /**
     * 
     * @type {Array<Event>}
     * @memberof User
     */
    diseaseEvents?: Array<Event>;
    /**
     * 
     * @type {Array<LandbotCaseData>}
     * @memberof User
     */
    landbotCaseData?: Array<LandbotCaseData>;
    /**
     * 
     * @type {Array<LandbotEventData>}
     * @memberof User
     */
    landbotEventData?: Array<LandbotEventData>;
    /**
     * 
     * @type {Array<Conversation>}
     * @memberof User
     */
    conversation?: Array<Conversation>;
    /**
     * 
     * @type {Array<GroupsOfUser>}
     * @memberof User
     */
    groupsOfUser?: Array<GroupsOfUser>;
    /**
     * 
     * @type {UserAttributes}
     * @memberof User
     */
    userAttributes?: UserAttributes;
    /**
     * 
     * @type {Array<UserActivityLog>}
     * @memberof User
     */
    userActivityLog?: Array<UserActivityLog>;
    /**
     * 
     * @type {Array<Notification>}
     * @memberof User
     */
    notification?: Array<Notification>;
    /**
     * 
     * @type {Array<UserFeedback>}
     * @memberof User
     */
    userFeedback?: Array<UserFeedback>;
    /**
     * 
     * @type {Array<Report>}
     * @memberof User
     */
    report?: Array<Report>;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "cognitoSub" in value;
    isInstance = isInstance && "isRegistered" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "lastActiveDiseaseId" in value;

    return isInstance;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'cognitoSub': json['cognitoSub'],
        'isRegistered': json['isRegistered'],
        'temporaryToken': !exists(json, 'temporaryToken') ? undefined : json['temporaryToken'],
        'createdAt': (new Date(json['createdAt'])),
        'lastActiveDisease': !exists(json, 'lastActiveDisease') ? undefined : DiseaseSettingsFromJSON(json['lastActiveDisease']),
        'lastActiveDiseaseId': json['lastActiveDiseaseId'],
        'lastActiveOrganisation': !exists(json, 'lastActiveOrganisation') ? undefined : OrganisationSettingsFromJSON(json['lastActiveOrganisation']),
        'lastActiveOrganisationId': !exists(json, 'lastActiveOrganisationId') ? undefined : json['lastActiveOrganisationId'],
        'settings': !exists(json, 'settings') ? undefined : ((json['settings'] as Array<any>).map(UserSettingsFromJSON)),
        'stripeInfo': !exists(json, 'stripeInfo') ? undefined : ((json['stripeInfo'] as Array<any>).map(StripeInfoFromJSON)),
        'details': !exists(json, 'details') ? undefined : ((json['details'] as Array<any>).map(UserDetailFromJSON)),
        'diseaseEvents': !exists(json, 'diseaseEvents') ? undefined : ((json['diseaseEvents'] as Array<any>).map(EventFromJSON)),
        'landbotCaseData': !exists(json, 'landbotCaseData') ? undefined : ((json['landbotCaseData'] as Array<any>).map(LandbotCaseDataFromJSON)),
        'landbotEventData': !exists(json, 'landbotEventData') ? undefined : ((json['landbotEventData'] as Array<any>).map(LandbotEventDataFromJSON)),
        'conversation': !exists(json, 'conversation') ? undefined : ((json['conversation'] as Array<any>).map(ConversationFromJSON)),
        'groupsOfUser': !exists(json, 'groupsOfUser') ? undefined : ((json['groupsOfUser'] as Array<any>).map(GroupsOfUserFromJSON)),
        'userAttributes': !exists(json, 'userAttributes') ? undefined : UserAttributesFromJSON(json['userAttributes']),
        'userActivityLog': !exists(json, 'userActivityLog') ? undefined : ((json['userActivityLog'] as Array<any>).map(UserActivityLogFromJSON)),
        'notification': !exists(json, 'notification') ? undefined : ((json['notification'] as Array<any>).map(NotificationFromJSON)),
        'userFeedback': !exists(json, 'userFeedback') ? undefined : ((json['userFeedback'] as Array<any>).map(UserFeedbackFromJSON)),
        'report': !exists(json, 'report') ? undefined : ((json['report'] as Array<any>).map(ReportFromJSON)),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'cognitoSub': value.cognitoSub,
        'isRegistered': value.isRegistered,
        'temporaryToken': value.temporaryToken,
        'createdAt': (value.createdAt.toISOString()),
        'lastActiveDisease': DiseaseSettingsToJSON(value.lastActiveDisease),
        'lastActiveDiseaseId': value.lastActiveDiseaseId,
        'lastActiveOrganisation': OrganisationSettingsToJSON(value.lastActiveOrganisation),
        'lastActiveOrganisationId': value.lastActiveOrganisationId,
        'settings': value.settings === undefined ? undefined : ((value.settings as Array<any>).map(UserSettingsToJSON)),
        'stripeInfo': value.stripeInfo === undefined ? undefined : ((value.stripeInfo as Array<any>).map(StripeInfoToJSON)),
        'details': value.details === undefined ? undefined : ((value.details as Array<any>).map(UserDetailToJSON)),
        'diseaseEvents': value.diseaseEvents === undefined ? undefined : ((value.diseaseEvents as Array<any>).map(EventToJSON)),
        'landbotCaseData': value.landbotCaseData === undefined ? undefined : ((value.landbotCaseData as Array<any>).map(LandbotCaseDataToJSON)),
        'landbotEventData': value.landbotEventData === undefined ? undefined : ((value.landbotEventData as Array<any>).map(LandbotEventDataToJSON)),
        'conversation': value.conversation === undefined ? undefined : ((value.conversation as Array<any>).map(ConversationToJSON)),
        'groupsOfUser': value.groupsOfUser === undefined ? undefined : ((value.groupsOfUser as Array<any>).map(GroupsOfUserToJSON)),
        'userAttributes': UserAttributesToJSON(value.userAttributes),
        'userActivityLog': value.userActivityLog === undefined ? undefined : ((value.userActivityLog as Array<any>).map(UserActivityLogToJSON)),
        'notification': value.notification === undefined ? undefined : ((value.notification as Array<any>).map(NotificationToJSON)),
        'userFeedback': value.userFeedback === undefined ? undefined : ((value.userFeedback as Array<any>).map(UserFeedbackToJSON)),
        'report': value.report === undefined ? undefined : ((value.report as Array<any>).map(ReportToJSON)),
    };
}

