import React from "react";
import { twMerge } from "tailwind-merge";

import { Disableable } from "../../types/disableable";
import { I18nProps, Text } from "../text";

export type CheckboxProps = Omit<
  Disableable<JSX.IntrinsicElements["input"]>,
  "ref" | "className" | "title"
> & {
  type?: "checkbox";
  label?: I18nProps;
  className?: string;
};

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ isDisabled, type, label, onKeyDown, className, ...rest }, ref) => {
    return (
      <div className={twMerge("flex items-center", className)}>
        <input
          {...rest}
          ref={ref}
          type="checkbox"
          disabled={isDisabled}
          className="checkbox checkbox-primary bg-base-100"
        />
        <span className="label-text ml-3 text-left">
          <Text {...label} />
        </span>
      </div>
    );
  },
);
Checkbox.displayName = "Checkbox";
