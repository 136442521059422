"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userTracking = void 0;
exports.userTracking = {
    chatStarted: "UserTracking_ChatStarted",
    signedIn: "UserTracking_SignedIn",
    tokenRefreshed: "UserTracking_TokenRefreshed",
    signedOut: "UserTracking_SignedOut",
    userDeleted: "UserTracking_UserDeleted",
};
