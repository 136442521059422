"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.apiMultiAgentDomainOfDeploymentEnv = exports.appDomainOfDeploymentEnv = exports.subdomainOfDeploymentDataEnv = exports.subdomainOfDeploymentEnv = exports.allDeploymentEnvironments = exports.DeploymentEnvironment = void 0;
const utility_1 = require("../utils/utility");
var DeploymentEnvironment;
(function (DeploymentEnvironment) {
    DeploymentEnvironment["DEV"] = "dev";
    DeploymentEnvironment["PROD"] = "prod";
})(DeploymentEnvironment || (exports.DeploymentEnvironment = DeploymentEnvironment = {}));
exports.allDeploymentEnvironments = (0, utility_1.allEnumValues)({
    [DeploymentEnvironment.DEV]: DeploymentEnvironment.DEV,
    [DeploymentEnvironment.PROD]: DeploymentEnvironment.PROD,
    local: "local",
});
exports.subdomainOfDeploymentEnv = {
    [DeploymentEnvironment.DEV]: "dev",
    [DeploymentEnvironment.PROD]: "app",
};
exports.subdomainOfDeploymentDataEnv = {
    [DeploymentEnvironment.DEV]: "dev.data",
    [DeploymentEnvironment.PROD]: "app.data",
};
exports.appDomainOfDeploymentEnv = {
    [DeploymentEnvironment.DEV]: "https://dev.mamahealth.com",
    [DeploymentEnvironment.PROD]: "https://app.mamahealth.com",
    local: "http://localhost:3000",
};
exports.apiMultiAgentDomainOfDeploymentEnv = {
    [DeploymentEnvironment.DEV]: "http://multiagent.mama.health",
    [DeploymentEnvironment.PROD]: "http://multiagent.mama.health",
    local: "http://localhost:8000",
};
