import React, { PropsWithChildren, useMemo } from "react";

import { MAX_AMOUNT_OF_XP } from "./constants";
import { toTenantIdHeader, useApiQuery } from "../../../api/use-api";
import { CHAT_COMPLETENESS_KEY } from "../../../types/query-keys";
import { useTenantId } from "../../../util/use-active-tenant-id";
import { Text } from "../../text";

export const ChatCompletion: React.FC<
  PropsWithChildren<{
    conversationId?: string;
  }>
> = ({ children, conversationId }) => {
  const tenantId = useTenantId();

  const { data: chatCompletenessPercentage } = useApiQuery(
    "multiagent",
    (api) =>
      api.getMultiagentChatCompleteness({
        ...toTenantIdHeader(tenantId),
        conversationId: conversationId as string,
      }),
    CHAT_COMPLETENESS_KEY(tenantId.disease),
    undefined,
    { enabled: !!conversationId },
  );

  const LinearProgress = useMemo(
    () => (
      <div className="flex flex-row items-center gap-4 md:hidden">
        <progress
          className="progress-success progress w-full"
          value={Math.floor(
            ((chatCompletenessPercentage ?? 0) / 100) * MAX_AMOUNT_OF_XP,
          )}
          max={MAX_AMOUNT_OF_XP}
        />
        <Text
          className="w-10 text-sm"
          text={`${Math.floor(
            ((chatCompletenessPercentage ?? 0) / 100) * MAX_AMOUNT_OF_XP,
          )} XP`}
        />
      </div>
    ),
    [chatCompletenessPercentage],
  );

  const CircularProgress = useMemo(
    () => (
      <div className="hidden h-full flex-col items-center justify-center gap-1 md:flex">
        <div
          className="radial-progress m-auto w-full items-center text-mama-green-300"
          style={
            {
              width: 80,
              height: 80,
              "--thickness": "1px",
              "--value": Math.floor(chatCompletenessPercentage ?? 0),
            } as object
          }
          role="progressbar"
        >
          {`${Math.floor(
            ((chatCompletenessPercentage ?? 0) / 100) * MAX_AMOUNT_OF_XP,
          )} XP`}
        </div>
      </div>
    ),
    [chatCompletenessPercentage],
  );

  return (
    <div className="item flex w-full max-w-[1660px] flex-col gap-4 px-2 md:w-9/12 md:flex-row">
      {LinearProgress}
      {CircularProgress}
      <div className="flex w-full flex-row justify-center gap-2">
        {children}
      </div>
    </div>
  );
};
