import { QueryClientProvider } from "@tanstack/react-query";
import React, { Suspense, useRef, useState } from "react";
import { Outlet } from "react-router-dom";

import { CreateUserGuard } from "../components/create-user-guard";
import { Events } from "../components/events/events";
import { ForwardEndoToEndometriosis } from "../components/forward-endo-to-endometriosis";
import { HasEnabledPages } from "../components/has-enabled-pages";
import { IdentifyUserInGoogleAnalytics } from "../components/identify-user-in-google-analytics";
import { LinkSentryToUser } from "../components/link-sentry-to-user";
import { DynamicHelmet } from "../dynamic-helmet";
import { EnsureUserFollowsTenantGuard } from "../guards/ensure-user-follows-tenant-guard";
import {
  EnsureLanguageIsSupported,
  SynchronizeLanguageWithBackend,
} from "../i18n/synchronize-language-with-backend";
import { LanguageSettings } from "../language-settings";
import { StoreProvider } from "../models/helpers";
import { ModalProvider } from "../models/modal-provider";
import { RootStore } from "../models/root";
import { useLastActiveTenantSaver } from "../navigate-to-last-active-tenant";
import { queryClient } from "../query-client";
import { ThemeProvider } from "../theme-provider";
import { PrivateApiProvider } from "~/api/private-api-provider";
import { AuthProvider } from "~/auth/auth-store";
import { useAutoSignIn } from "~/auth/hooks/use-auto-sign-in";
import { DataGuard } from "~/data-collection/data-guard";

export const Root: React.FC = () => {
  const [i18NReady, setI18NReady] = useState(false);
  const rootStoreRef = useRef<RootStore>(new RootStore());
  return (
    <QueryClientProvider client={queryClient}>
      <StoreProvider value={rootStoreRef.current}>
        {i18NReady ? (
          <Suspense>
            <Outlet />
          </Suspense>
        ) : null}
        <ForwardEndoToEndometriosis />
        <LanguageSettings setI18NReady={setI18NReady} />
        <Events />
      </StoreProvider>
    </QueryClientProvider>
  );
};

export const TenantRoot: React.FC = () => {
  useLastActiveTenantSaver(false);
  return (
    <ThemeProvider>
      <DynamicHelmet />
      <AuthProvider>
        <PrivateApiProvider>
          <CreateUserGuard>
            <LinkSentryToUser />
            <ModalProvider>
              <SynchronizeLanguageWithBackend />
              <IdentifyUserInGoogleAnalytics />
              <EnsureUserFollowsTenantGuard>
                <HasEnabledPages>
                  <DataGuard>
                    <Outlet />
                  </DataGuard>
                </HasEnabledPages>
              </EnsureUserFollowsTenantGuard>
            </ModalProvider>
          </CreateUserGuard>
        </PrivateApiProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export const TenantPublicRoot: React.FC = () => {
  useLastActiveTenantSaver(false);
  useAutoSignIn();

  return (
    <ThemeProvider>
      <ModalProvider>
        <EnsureLanguageIsSupported />
        <Outlet />
      </ModalProvider>
    </ThemeProvider>
  );
};
