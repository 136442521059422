import { Suspense, useEffect, useState } from "react";
import { TranslationFeKey } from "shared/types/translation-key";

import { OnMessageSendFunction } from "../../chat/conversation";
import { getComponent } from "../lookup";
import { SocketAssistantMessage } from "../schema-socket";
import { MessageRecordWithType } from "~/api/generated/multiagent";

export type MessageProcessedChoices = MessageRecordWithType & {
  questionNodeInfo: Omit<
    MessageRecordWithType["questionNodeInfo"],
    "choices"
  > & {
    choices: TranslationFeKey[] | undefined;
  };
};

const processChoices = (
  message: SocketAssistantMessage,
): MessageProcessedChoices => {
  const clone = structuredClone(message);

  const processedChoices: TranslationFeKey[] | undefined =
    clone.questionNodeInfo.choices?.reduce((processed, choice) => {
      processed.push(
        `graph.choice.${clone.questionNodeInfo.subtype}.${choice}` as TranslationFeKey,
      );
      return processed;
    }, [] as TranslationFeKey[]);

  clone.questionNodeInfo.choices = processedChoices;

  return clone as MessageProcessedChoices;
};

const FallbackLoadingComponent: React.FC<{ showAfterMs?: number }> = ({
  showAfterMs = 75,
}) => {
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldShow(true);
    }, showAfterMs);

    return () => clearTimeout(timer);
  }, [showAfterMs]);

  return shouldShow ? (
    <div className="skeleton h-60 w-full sm:w-[30rem]" />
  ) : null;
};

export const MessageComponent: React.FC<{
  message: SocketAssistantMessage;
  sendMessage: OnMessageSendFunction;
}> = ({ message, sendMessage }) => {
  const { type, subtype } = message.questionNodeInfo;

  const [Component, validationFunction] = getComponent(type, subtype);
  if (!Component) {
    return <div>{"Component not found"}</div>;
  }

  const processedMessage = processChoices(message);

  return (
    <Suspense fallback={<FallbackLoadingComponent />}>
      <Component
        message={processedMessage}
        sendMessage={sendMessage}
        validationFunction={validationFunction}
      />
    </Suspense>
  );
};
