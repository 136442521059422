import { DataToBeCollectedEnum } from "shared/model/data-collection/types-and-constants";

import { acquisitionDetailInput } from "./inputs/acquisition-detail-input";
import { countryInput } from "./inputs/country-input";
import { dateOfBirthInput } from "./inputs/date-of-birth-input";
import { emailInput } from "./inputs/email-input";
import { endoStudyInformation20240115Input } from "./inputs/endo-study-information-2024-01-15-input";
import { genderInput } from "./inputs/gender-input";
import { languageInput } from "./inputs/language-input";
import { nameInput } from "./inputs/name-input";
import { opadePatientIdInput } from "./inputs/opade-patient-id-input";
import { phoneNumberInput } from "./inputs/phone-number-input";
import { postalCodeInput } from "./inputs/postal-code-input";
import { privacyPolicy20231018Input } from "./inputs/privacy-policy-2023-10-18-input";
import { termsAndConditions20230830Input } from "./inputs/terms-and-conditions-2023-08-30-input";
import { DataCollectionImplementation } from "./types";

export const DataCollectionImplementationsRecord: Record<
  DataToBeCollectedEnum,
  DataCollectionImplementation
> = {
  [DataToBeCollectedEnum.NAME]: nameInput,
  [DataToBeCollectedEnum.EMAIL]: emailInput,
  [DataToBeCollectedEnum.PHONE_NUMBER]: phoneNumberInput,
  [DataToBeCollectedEnum.YEAR_OF_BIRTH]: dateOfBirthInput,
  [DataToBeCollectedEnum.GENDER]: genderInput,
  [DataToBeCollectedEnum.POSTAL_CODE]: postalCodeInput,
  [DataToBeCollectedEnum.COUNTRY]: countryInput,
  [DataToBeCollectedEnum.ACQUISITION_DETAIL]: acquisitionDetailInput,
  [DataToBeCollectedEnum.PRIVACY_POLICY_2023_10_18]: privacyPolicy20231018Input,
  [DataToBeCollectedEnum.TERMS_AND_CONDITIONS_2023_08_30]:
    termsAndConditions20230830Input,
  [DataToBeCollectedEnum.ENDO_STUDY_INFORMATION_2024_01_15]:
    endoStudyInformation20240115Input,
  [DataToBeCollectedEnum.OPADE_PATIENT_ID]: opadePatientIdInput,
  [DataToBeCollectedEnum.LANGUAGE]: languageInput,
};
