import { createContext, useCallback, useContext, useState } from "react";

type LanguageSelectorContextProps = {
  Component: JSX.Element | null;
  setEmbellishment: (Component: JSX.Element) => void;
  unsetEmbellishment: () => void;
};

const EmbellishmentVisibilityContext = createContext<
  LanguageSelectorContextProps | undefined
>(undefined);

export const EmbellishmentVisibilityProvider: React.FC<{
  children: (EmbellishmentComponent: JSX.Element | null) => JSX.Element;
}> = ({ children }) => {
  const [Component, setComponent] = useState<JSX.Element | null>(null);

  const setEmbellishment = useCallback(
    (EmbellishmentComponent: JSX.Element) =>
      setComponent(EmbellishmentComponent),
    [],
  );
  const unsetEmbellishment = useCallback(() => setComponent(null), []);

  return (
    <EmbellishmentVisibilityContext.Provider
      value={{
        Component,
        setEmbellishment,
        unsetEmbellishment,
      }}
    >
      {children(Component)}
    </EmbellishmentVisibilityContext.Provider>
  );
};

export const useEmbellishmentVisibility = (): LanguageSelectorContextProps => {
  const context = useContext(EmbellishmentVisibilityContext);
  if (!context) {
    throw new Error(
      "useEmbellishmentVisibility must be used within a EmbellishmentVisibilityProvider",
    );
  }
  return context;
};
