/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QuestionNodeInfo } from './QuestionNodeInfo';
import {
    QuestionNodeInfoFromJSON,
    QuestionNodeInfoFromJSONTyped,
    QuestionNodeInfoToJSON,
} from './QuestionNodeInfo';
import type { ServerMessageMetadata } from './ServerMessageMetadata';
import {
    ServerMessageMetadataFromJSON,
    ServerMessageMetadataFromJSONTyped,
    ServerMessageMetadataToJSON,
} from './ServerMessageMetadata';
import type { UserMediaRecord } from './UserMediaRecord';
import {
    UserMediaRecordFromJSON,
    UserMediaRecordFromJSONTyped,
    UserMediaRecordToJSON,
} from './UserMediaRecord';

/**
 * 
 * @export
 * @interface MessageRecordWithType
 */
export interface MessageRecordWithType {
    /**
     * 
     * @type {string}
     * @memberof MessageRecordWithType
     */
    role: MessageRecordWithTypeRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof MessageRecordWithType
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof MessageRecordWithType
     */
    language: string;
    /**
     * 
     * @type {string}
     * @memberof MessageRecordWithType
     */
    contentLocalized: string;
    /**
     * 
     * @type {ServerMessageMetadata}
     * @memberof MessageRecordWithType
     */
    metadata?: ServerMessageMetadata;
    /**
     * 
     * @type {string}
     * @memberof MessageRecordWithType
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof MessageRecordWithType
     */
    inputTime: Date;
    /**
     * 
     * @type {Array<UserMediaRecord>}
     * @memberof MessageRecordWithType
     */
    media?: Array<UserMediaRecord>;
    /**
     * 
     * @type {QuestionNodeInfo}
     * @memberof MessageRecordWithType
     */
    questionNodeInfo: QuestionNodeInfo;
}


/**
 * @export
 */
export const MessageRecordWithTypeRoleEnum = {
    Assistant: 'ASSISTANT',
    System: 'SYSTEM',
    User: 'USER'
} as const;
export type MessageRecordWithTypeRoleEnum = typeof MessageRecordWithTypeRoleEnum[keyof typeof MessageRecordWithTypeRoleEnum];


/**
 * Check if a given object implements the MessageRecordWithType interface.
 */
export function instanceOfMessageRecordWithType(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "language" in value;
    isInstance = isInstance && "contentLocalized" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "inputTime" in value;
    isInstance = isInstance && "questionNodeInfo" in value;

    return isInstance;
}

export function MessageRecordWithTypeFromJSON(json: any): MessageRecordWithType {
    return MessageRecordWithTypeFromJSONTyped(json, false);
}

export function MessageRecordWithTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageRecordWithType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': json['role'],
        'content': json['content'],
        'language': json['language'],
        'contentLocalized': json['contentLocalized'],
        'metadata': !exists(json, 'metadata') ? undefined : ServerMessageMetadataFromJSON(json['metadata']),
        'id': json['id'],
        'inputTime': (new Date(json['inputTime'])),
        'media': !exists(json, 'media') ? undefined : ((json['media'] as Array<any>).map(UserMediaRecordFromJSON)),
        'questionNodeInfo': QuestionNodeInfoFromJSON(json['questionNodeInfo']),
    };
}

export function MessageRecordWithTypeToJSON(value?: MessageRecordWithType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': value.role,
        'content': value.content,
        'language': value.language,
        'contentLocalized': value.contentLocalized,
        'metadata': ServerMessageMetadataToJSON(value.metadata),
        'id': value.id,
        'inputTime': (value.inputTime.toISOString()),
        'media': value.media === undefined ? undefined : ((value.media as Array<any>).map(UserMediaRecordToJSON)),
        'questionNodeInfo': QuestionNodeInfoToJSON(value.questionNodeInfo),
    };
}

