import { FormTextInput } from "../../components/form/form-text-input";
import { useT } from "../../i18n/use-t";
import { DataCollectionImplementation } from "../types";

const minimumLengthAnswer = 5;
export const acquisitionDetailInput: DataCollectionImplementation = {
  wasCompleted: (user) => !!user.userAttributes?.acquisitionDetail,
  Component: ({ errors, register, user }) => {
    const t = useT();

    return !user.userAttributes?.acquisitionDetail ? (
      <FormTextInput
        labelClassName="font-bold"
        title={{ tx: "userData.WhereDidYouHearAboutMama.details" }}
        {...register("acquisitionDetail", {
          validate: (value) => {
            if ((value ?? "").length < minimumLengthAnswer) {
              return t({
                tx: "userData.WhereDidYouHearAboutMama.answerNeedsToBeLonger",
                txData: { characters: minimumLengthAnswer },
              });
            }
          },
        })}
        error={{ txUnchecked: errors.acquisitionDetail?.message }}
      />
    ) : null;
  },
};
