"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.diseases = void 0;
const atopic_dermatitis_1 = require("./atopic-dermatitis/atopic-dermatitis");
const chronic_hand_eczema_1 = require("./chronic-hand-eczema/chronic-hand-eczema");
const chronic_obstructive_pulmonary_disease_1 = require("./chronic-obstructive-pulmonary-disease/chronic-obstructive-pulmonary-disease");
const chronic_urticaria_1 = require("./chronic-urticaria/chronic-urticaria");
const endometriosis_1 = require("./endometriosis/endometriosis");
const graves_disease_1 = require("./graves-disease/graves-disease");
const insomnia_1 = require("./insomnia/insomnia");
const long_covid_1 = require("./long-covid/long-covid");
const multiple_sclerosis_1 = require("./multiple-sclerosis/multiple-sclerosis");
const myasthenia_gravis_1 = require("./myasthenia-gravis/myasthenia-gravis");
const opade_1 = require("./opade-study/opade");
const thyroid_eye_disease_1 = require("./thyroid-eye-disease/thyroid-eye-disease");
const organisations_1 = require("../../model/organisations");
const _diseases = [
    endometriosis_1.endometriosis,
    long_covid_1.longCovid,
    myasthenia_gravis_1.myastheniaGravis,
    multiple_sclerosis_1.multipleSclerosis,
    opade_1.opadeStudy,
    atopic_dermatitis_1.atopicDermatitis,
    chronic_urticaria_1.chronicUrticaria,
    chronic_hand_eczema_1.chronicHandEczema,
    chronic_obstructive_pulmonary_disease_1.chronicObstructivePulmonaryDisease,
    thyroid_eye_disease_1.thyroidEyeDisease,
    insomnia_1.insomnia,
    graves_disease_1.gravesDisease,
];
exports.diseases = _diseases.map((disease) => (Object.assign(Object.assign({}, disease), { type: "disease", organisations: disease.organisations.map((organisation) => (0, organisations_1.mergeToOrganisationConfig)(disease, organisation)) })));
