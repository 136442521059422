import React, { PropsWithChildren } from "react";
import { stringIdOfTenant } from "shared/config/base-urls";

import { useTenantId } from "./util/use-active-tenant-id";

export const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const tenantId = useTenantId();

  return (
    <div
      className="h-screen-dvh w-full"
      data-theme={stringIdOfTenant(tenantId)}
    >
      {children}
    </div>
  );
};
