"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.endometriosis = void 0;
const cognito_1 = require("../../../model/cognito");
const types_and_constants_1 = require("../../../model/data-collection/types-and-constants");
const diseases_1 = require("../../../model/diseases");
const languages_1 = require("../../../model/languages");
const lock_1 = require("../../../model/lock/lock");
const pages_1 = require("../../../model/pages");
const user_feedbacks_1 = require("../../../model/user-feedback/user-feedbacks");
const deployment_environments_1 = require("../../deployment-environments");
exports.endometriosis = {
    diseaseType: diseases_1.DiseaseType.DISEASE,
    disease: diseases_1.Disease.ENDOMETRIOSIS,
    homePage: pages_1.FrontendPageEnum.CHAT,
    organisations: [],
    theme: {
        daisyTheme: {
            primary: "#1F3560",
            "primary-focus": "#ba3c6a",
            "primary-content": "#FFFFFF",
            secondary: "#88a9e3",
            "secondary-focus": "#7895c8",
            "secondary-content": "#FFFFFF",
            accent: "#3459A3",
            "accent-focus": "#2D4D8C",
            "accent-content": "#FFFFFF",
            neutral: "#424242",
            "neutral-focus": "#333333",
            "neutral-content": "#FFFFFF",
            "base-100": "#ffffff",
            "base-200": "#f0f0f0",
            "base-300": "#e0e0e0",
            "base-content": "#1f1f1f",
            info: "#25dbf1",
            "info-content": "#082f34",
            success: "#30ca6b",
            "success-content": "#14522a",
            warning: "#dec762",
            "warning-content": "#524b2b",
            error: "#ff4d4d",
            "error-content": "#ffffff",
            "--btn-text-case": "none",
        },
    },
    supportedLanguages: [languages_1.Language.it_IT, languages_1.Language.de_DE, languages_1.Language.en_US],
    isLanguageSelectionDisabled: true,
    publicPages: [],
    enabledPages: [
        Object.assign({ txPageName: "chat", route: pages_1.FrontendPageEnum.CHAT, groupsWhichCanSeeThePage: [
                cognito_1.SupportedGroupsEnum.USER,
                cognito_1.SupportedGroupsEnum.ADMIN,
            ], settings: {
                chatType: pages_1.ChatType.MIXED,
                defaultLandbotUrlTx: "tenant.endo.defaultLandbotUrl",
                landbotLanguageOverride: [languages_1.Language.de_DE],
            } }, lock_1.defaultPageLock),
        Object.assign({ txPageName: "answer", route: pages_1.FrontendPageEnum.MAMA_ANSWER, groupsWhichCanSeeThePage: [
                cognito_1.SupportedGroupsEnum.USER,
                cognito_1.SupportedGroupsEnum.ADMIN,
            ] }, lock_1.defaultPageLock),
        Object.assign({ txPageName: "home-endo", route: "/home-endo", isPdfPage: true, groupsWhichCanSeeThePage: [
                cognito_1.SupportedGroupsEnum.ADMIN,
                cognito_1.SupportedGroupsEnum.USER,
            ], supportedLanguages: [languages_1.Language.de_DE] }, lock_1.defaultPageLock),
        Object.assign({ txPageName: "journey", route: pages_1.FrontendPageEnum.JOURNEY, groupsWhichCanSeeThePage: [
                cognito_1.SupportedGroupsEnum.USER,
                cognito_1.SupportedGroupsEnum.ADMIN,
            ], settings: {
                displayCaseDataEnums: [
                    "MONTHLY_PAIN_DAYS",
                    "THERAPY_TYPE",
                    "CONTRACEPTIVE_METHOD",
                    "ENDO_DIAGNOSIS",
                    "NON_ENDO_MEDICATIONS",
                    "MULTIPLE_SYMPTOM",
                ],
            } }, lock_1.defaultPageLock),
        Object.assign({ txPageName: "report", route: pages_1.FrontendPageEnum.REPORT, groupsWhichCanSeeThePage: [
                cognito_1.SupportedGroupsEnum.USER,
                cognito_1.SupportedGroupsEnum.ADMIN,
            ], supportedLanguages: [languages_1.Language.it_IT, languages_1.Language.en_US] }, (0, lock_1.createPageLock)([
            {
                reasonWhyLockedIframeUrl: "tabs.locks.blockedReport",
                lockType: pages_1.PageLockType.CHAT_COMPLETENESS,
            },
        ])),
        Object.assign({ route: "/endometriosis-analysis", txPageName: "stats", groupsWhichCanSeeThePage: [
                cognito_1.SupportedGroupsEnum.USER,
                cognito_1.SupportedGroupsEnum.ADMIN,
            ], supportedLanguages: [languages_1.Language.it_IT, languages_1.Language.de_DE] }, (0, lock_1.createPageLock)([
            {
                reasonWhyLockedIframeUrl: "tabs.locks.blockedDashboard",
                lockType: pages_1.PageLockType.CHAT_COMPLETENESS,
            },
        ])),
        Object.assign({ txPageName: "admin-dashboard", route: pages_1.FrontendPageEnum.ADMIN_DASHBOARD, groupsWhichCanSeeThePage: [cognito_1.SupportedGroupsEnum.ADMIN], settings: {
                dashboards: {
                    [deployment_environments_1.DeploymentEnvironment.DEV]: {
                        index: 6,
                    },
                    [deployment_environments_1.DeploymentEnvironment.PROD]: {
                        index: 36,
                    },
                },
            }, supportedLanguages: [languages_1.Language.it_IT, languages_1.Language.de_DE] }, lock_1.defaultPageLock),
    ],
    showCallSupportButton: {
        telephoneNumberTx: "disease.endometriosis.settings.supportNumber",
        helpPageUrl: "disease.endometriosis.settings.surpportUrl",
    },
    authLogo: "/resources/mama-logo-wide.svg",
    headerLogo: "/resources/mama-logo.svg",
    narrowHeaderLogo: "/resources/mama-logo.svg",
    favIcon: "/resources/mama-favicon.png",
    favIconHighres: "/resources/mama-favicon-highres.png",
    tenantNameTx: "disease.endometriosis.settings.name",
    autoConfirmUsers: false,
    groupSecretsArns: {
        [deployment_environments_1.DeploymentEnvironment.DEV]: "arn:aws:secretsmanager:eu-central-1:616427543840:secret:group-secrets-endometriosis-WpBe7k",
        [deployment_environments_1.DeploymentEnvironment.PROD]: "arn:aws:secretsmanager:eu-central-1:025928572003:secret:group-secrets-endometriosis-iThlX1",
    },
    identityProviders: [
        { language: languages_1.Language.it_IT, providers: [cognito_1.IdpEnum.FACEBOOK] },
        { language: languages_1.Language.de_DE, providers: [] },
    ],
    metaDescriptionTx: "disease.endometriosis.settings.description",
    dataGuardConfig: [
        {
            title: "dataGuards.legal.title",
            dataGuardInputs: [
                { inputFor: types_and_constants_1.DataToBeCollectedEnum.PRIVACY_POLICY_2023_10_18 },
                { inputFor: types_and_constants_1.DataToBeCollectedEnum.TERMS_AND_CONDITIONS_2023_08_30 },
                { inputFor: types_and_constants_1.DataToBeCollectedEnum.ENDO_STUDY_INFORMATION_2024_01_15 },
            ],
        },
        {
            title: "dataGuards.socialDemographics.title",
            dataGuardInputs: [
                { inputFor: types_and_constants_1.DataToBeCollectedEnum.NAME },
                { inputFor: types_and_constants_1.DataToBeCollectedEnum.EMAIL },
            ],
        },
    ],
    userFeedback: user_feedbacks_1.mamaAnswerFeedbacks,
    profileInputs: [
        types_and_constants_1.DataToBeCollectedEnum.NAME,
        types_and_constants_1.DataToBeCollectedEnum.EMAIL,
        types_and_constants_1.DataToBeCollectedEnum.LANGUAGE,
    ],
    onboardingType: "chat",
};
