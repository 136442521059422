import { useEffect } from "react";

import { forceRefreshUserSession } from "../auth-store";
import {
  SpecialNavigationRoutes,
  useMamaNavigate,
} from "~/navigation/mama-navigate";

export const useAutoSignIn = (): void => {
  const navigate = useMamaNavigate();
  useEffect(() => {
    const executeAsync = async () => {
      try {
        const jwt = await forceRefreshUserSession();
        if (jwt) {
          navigate(SpecialNavigationRoutes.HOME);
        }
      } catch {
        /* empty */
      }
    };

    executeAsync();
  }, [navigate]);
};
