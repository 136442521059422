import { useCallback, useEffect, useState } from "react";

import { requestMagicLinkForSignIn } from "./use-handle-magic-link-sign-in";
import {
  AuthMessages,
  AuthMessageTypesEnum,
  AuthPagesEnum,
  MAGIC_LINK_SIGN_IN_METHOD,
  SignInStatusEnum,
  SignUpNextStepEnum,
  SignUpStatusEnum,
} from "../constants";
import { CognitoAuthInput, signUpRetry } from "../utils/auth-methods";
import { handleCognitoError, MamaCognitoError } from "../utils/cognito-errors";
import { useClientMetaData } from "~/auth/hooks/use-client-meta-data";
import {
  PUBLIC_CHAT_LOCAL_STORAGE_KEY,
  PublicChatCredentials,
} from "~/components/chat/ai/ask/chat/ai-public-chat";
import {
  SpecialNavigationRoutes,
  useMamaNavigate,
} from "~/navigation/mama-navigate";

export const useHandleMagicLinkSignUp = ({
  legacy,
}: {
  legacy: boolean;
}): (({
  name,
  email,
}: {
  name: string;
  email: string;
}) => Promise<boolean>) => {
  const [publicChatCredentials, setPublicChatCredentials] =
    useState<PublicChatCredentials>();

  const navigate = useMamaNavigate();

  useEffect(() => {
    const creds = localStorage.getItem(PUBLIC_CHAT_LOCAL_STORAGE_KEY);
    try {
      const parsedCreds = creds ? JSON.parse(creds) : undefined;
      setPublicChatCredentials(parsedCreds);
    } catch {
      return;
    }
  }, []);

  const clientMetadata = useClientMetaData({
    signInMethod: MAGIC_LINK_SIGN_IN_METHOD,
    isSignup: "yes",
    alreadyHaveMagicLink: "no",
    userId: publicChatCredentials?.userId,
    token: publicChatCredentials?.token,
  });

  return useCallback(
    async ({
      name,
      email,
    }: {
      name: string;
      email: string;
    }): Promise<boolean> => {
      if (!legacy) {
        if (!clientMetadata.userId || !clientMetadata.token) {
          let creds: Partial<{ userId: string; token: string }> = {};
          try {
            creds = JSON.parse(
              localStorage.getItem(PUBLIC_CHAT_LOCAL_STORAGE_KEY) || "{}",
            );
          } catch (err) {
            localStorage.removeItem(PUBLIC_CHAT_LOCAL_STORAGE_KEY);
            navigate(SpecialNavigationRoutes.AUTH_FALLBACK, {
              replace: true,
              error: handleCognitoError(
                new Error(
                  "An unknown error has occured. Please try again later!",
                ),
                { email },
              ),
            });
            return false;
          }

          if (!creds.userId || !creds.token) {
            navigate(SpecialNavigationRoutes.AUTH_FALLBACK, {
              replace: true,
              error: handleCognitoError(
                new Error(
                  "An unknown error has occured. Please try again later!",
                ),
                { email },
              ),
            });
            return false;
          }

          clientMetadata.userId = creds.userId;
          clientMetadata.token = creds.token;
        }
      }

      try {
        const signUpState = await signUpThenRequestMagicLink({
          email,
          name,
          clientMetadata,
        });
        switch (signUpState) {
          case SignUpStatusEnum.MAGIC_LINK_SENT:
            if (legacy) {
              navigate(AuthPagesEnum.AUTH_ROOT, {
                info: AuthMessages[AuthMessageTypesEnum.CONFIRM_SIGN_UP],
                replace: true,
              });
            }
            return true;
          case SignUpStatusEnum.USER_ALREADY_EXISTS:
            localStorage.removeItem(PUBLIC_CHAT_LOCAL_STORAGE_KEY);

            navigate(AuthPagesEnum.MAGIC_SIGN_IN, {
              replace: true,
              error: {
                tx: "auth.signIn.errors.AnAccountWithThisEmailAlreadyExists",
              },
            });
            return false;
          default:
            localStorage.removeItem(PUBLIC_CHAT_LOCAL_STORAGE_KEY);
            throw new MamaCognitoError({
              name: "auth.signIn.errors.UnknownErorHasOccured",
            });
        }
      } catch (error) {
        localStorage.removeItem(PUBLIC_CHAT_LOCAL_STORAGE_KEY);
        navigate(SpecialNavigationRoutes.AUTH_FALLBACK, {
          replace: true,
          error: handleCognitoError(error, { email }),
        });
        return false;
      }
    },
    [clientMetadata, navigate, legacy],
  );
};

export const signUpThenRequestMagicLink = async ({
  email,
  name,
  clientMetadata,
}: CognitoAuthInput): Promise<SignUpStatusEnum> => {
  try {
    const signInResult = await requestMagicLinkForSignIn({
      email,
      name,
      clientMetadata,
    });

    if (signInResult === SignInStatusEnum.MAGIC_LINK_SENT) {
      return SignUpStatusEnum.USER_ALREADY_EXISTS;
    }
  } catch (error) {
    return SignUpStatusEnum.UNKNOWN_SIGN_IN_BEFORE_SIGN_UP_ERROR;
  }

  try {
    const { isSignUpComplete, nextStep, userId } = await signUpRetry({
      username: email,
      password: generatePassword(),
      options: {
        userAttributes: {
          name,
          email,
        },
        autoSignIn: false,
        clientMetadata,
      },
    });

    if (!isSignUpComplete || nextStep.signUpStep !== SignUpNextStepEnum.DONE) {
      return SignUpStatusEnum.SIGN_UP_NOT_DONE;
    }

    if (!userId) {
      return SignUpStatusEnum.SIGN_UP_NOT_DONE;
    }

    const signInStatus = await requestMagicLinkForSignIn({
      email,
      name,
      clientMetadata,
    });

    if (signInStatus === SignInStatusEnum.MAGIC_LINK_SENT) {
      return SignUpStatusEnum.MAGIC_LINK_SENT;
    }

    return SignUpStatusEnum.UNKNOWN_ERROR;
  } catch {
    return SignUpStatusEnum.UNKNOWN_ERROR;
  }
};

const DEFAULT_LENGTH = 16;
const CHARS = [
  "abcdefghijklmnopqrstuvwwyz",
  "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
  "0123456890",
  "+-^$*.[]{}()?\"!@#%&/,><':;|_~",
];

const generatePassword = (): string => {
  let indexHistory: number[] = [];
  const gen = () => {
    let p = "";
    for (let i = 0; i < DEFAULT_LENGTH; i++) {
      const idx = Math.floor(Math.random() * 4);
      indexHistory.push(idx);

      const set = CHARS[idx];
      const l = set.length;
      const idx2 = Math.floor(Math.random() * l);
      const chr = set[idx2];
      p = p += chr;
    }
    return p;
  };

  const password = gen();
  if (
    indexHistory.includes(0) &&
    indexHistory.includes(1) &&
    indexHistory.includes(2) &&
    indexHistory.includes(3)
  ) {
    return password;
  } else {
    indexHistory = [];
    return gen();
  }
};
