import { tx } from "shared/types/i18n";

import { DataCollectionImplementation } from "../types";
import { UserAttributesLanguageEnum } from "~/api/generated/backend";
import { FormCheckboxInput } from "~/components/form/form-checkbox-input";
import { Link } from "~/components/link";
import { Text } from "~/components/text";

export const endoStudyInformation20240115Input: DataCollectionImplementation = {
  /*
    WARNING: This Guard is Language Dependent
  */
  wasCompleted: (user) =>
    user.userAttributes?.language !== UserAttributesLanguageEnum.DeDe ||
    !!user.userAttributes?.agreedToEndoStudyInformation20240115,
  Component: ({ errors, register, user }) => {
    return user.userAttributes?.language === UserAttributesLanguageEnum.DeDe ? (
      <>
        <Text
          className="label-text ml-3 py-2 font-bold"
          tx="signUp.customFields.endoChariteStudy.studyInformation"
          txComponents={{
            href: <Link className="underline" target="_blank" />,
          }}
        />
        <FormCheckboxInput
          labelClassName="font-bold"
          label={{
            tx: "signUp.customFields.endoChariteStudy.firstParticipationCriteria",
          }}
          {...register("agreedToEndoStudyInformation20240115", {
            required: tx(
              "signUp.customFields.endoChariteStudy.errorAgeCriteriaNotMet",
            ),
          })}
          error={{
            txUnchecked: errors.agreedToEndoStudyInformation20240115?.message,
          }}
        />
      </>
    ) : null;
  },
};
