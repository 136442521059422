import { useMemo } from "react";
import { CognitoClientMetadata, LambdaBoolean } from "shared/types/auth";

import { useAppLanguage } from "~/components/form/language/use-app-language";
import { isLocalhost } from "~/util/env-utils";
import { useTenantId } from "~/util/use-active-tenant-id";

export const useClientMetaData = ({
  signInMethod,
  isSignup,
  alreadyHaveMagicLink,
  userId,
  token,
}: {
  signInMethod?: string;
  isSignup?: LambdaBoolean;
  alreadyHaveMagicLink?: LambdaBoolean;
  userId?: string;
  token?: string;
} = {}): Partial<CognitoClientMetadata> => {
  const { disease, organisation } = useTenantId();
  const { appLanguage } = useAppLanguage();

  return useMemo<Partial<CognitoClientMetadata>>(() => {
    const metaData: Partial<CognitoClientMetadata> = {
      disease,
      language: appLanguage,
      isLocalhost: isLocalhost ? "yes" : "no",
      userAgent: navigator.userAgent,
      ...(organisation && { organisation }),
      ...(token && { token }),
      ...(userId && { userId }),
      ...(isSignup && { isSignup }),
      ...(signInMethod && { signInMethod }),
      ...(alreadyHaveMagicLink && { alreadyHaveMagicLink }),
    };

    return metaData;
  }, [
    disease,
    appLanguage,
    organisation,
    token,
    userId,
    isSignup,
    signInMethod,
    alreadyHaveMagicLink,
  ]);
};
