import assert from "assert";
import React, { createContext, useContext, useMemo } from "react";
import { Outlet } from "react-router-dom";
import { TenantIdentifier } from "shared/config";
import { Disease } from "shared/model/diseases";
import { Organisation } from "shared/model/organisations";

const DiseaseContext = createContext<{ disease?: Disease }>({
  disease: undefined,
});
const OrganisationContext = createContext<{ organisation?: Organisation }>({
  organisation: undefined,
});

export const DiseaseProvider: React.FC<{ disease: Disease }> = ({
  ...props
}) => {
  return (
    <DiseaseContext.Provider value={props}>
      <Outlet />
    </DiseaseContext.Provider>
  );
};

export const OrganisationProvider: React.FC<{ organisation: Organisation }> = ({
  ...props
}) => {
  return (
    <OrganisationContext.Provider value={props}>
      <Outlet />
    </OrganisationContext.Provider>
  );
};

export const useTenantId = (): TenantIdentifier => {
  const tenantId = useOptionalTenantId();
  assert(tenantId, "expected tenantId to defined");
  return tenantId;
};

export const useOptionalTenantId = (): TenantIdentifier | undefined => {
  const { disease } = useContext(DiseaseContext);
  const { organisation } = useContext(OrganisationContext);

  return useMemo(
    () => disease && { disease, organisation },
    [disease, organisation],
  );
};

export const dirtyGetMaybeDisease = (): Disease | undefined => {
  try {
    const path = window.location.pathname;
    const maybeDisease = path.split("/").slice(1, 2);
    if (maybeDisease.length > 0) {
      return maybeDisease[0] as Disease;
    }
  } catch {
    // empty
  }
  return undefined;
};
