import { useState } from "react";
import { defaultCards } from "shared/model/cards/default-cards";
import { twMerge } from "tailwind-merge";

import { useT } from "../../i18n/use-t";
import { useTenantConfig } from "../../tenant-settings";
import { Button, GhostButton } from "../form/button";
import { Text } from "../text";

export const ChatTutorial: React.FC = () => {
  const { tenantNameTx } = useTenantConfig();

  const t = useT();
  const [activeCardIndex, setActiveCardIndex] = useState(0);

  return (
    <>
      {defaultCards.map(
        (card, idx) =>
          activeCardIndex === idx && (
            <div
              className="flex max-w-lg flex-col items-center gap-5"
              key={idx}
            >
              <div className="relative my-32 flex min-h-fit flex-col items-center justify-center gap-4">
                <Button
                  className="absolute h-60 w-20 self-start opacity-0"
                  onClick={() => {
                    if (activeCardIndex > 0)
                      setActiveCardIndex(activeCardIndex - 1);
                  }}
                />
                <img src={t({ tx: card.image })} className="my-2 h-32 w-32" />
                <Text
                  as="div"
                  tx={card.description}
                  txData={{ disease: t({ tx: tenantNameTx }) }}
                  className="text-md flex h-20 flex-col justify-center text-center"
                />
                <Button
                  className="absolute h-60 w-20 self-end opacity-0"
                  onClick={() => {
                    if (activeCardIndex < defaultCards.length - 1)
                      setActiveCardIndex(activeCardIndex + 1);
                  }}
                />
              </div>

              <div className="flex items-center gap-3">
                <GhostButton
                  text="<"
                  className={twMerge(
                    "h-8 w-8 rounded-full border-0 text-xl",
                    activeCardIndex === 0
                      ? "cursor-default opacity-0"
                      : "opacity-100",
                  )}
                  textClassName="text-mama-gray-200"
                  onClick={() => {
                    if (activeCardIndex > 0)
                      setActiveCardIndex(activeCardIndex - 1);
                  }}
                />

                <div className="flex flex-row gap-4">
                  {defaultCards.map((_, dot_index) => (
                    <div
                      key={`intro-modal-dot-${dot_index}`}
                      className={twMerge(
                        "h-4 w-4 rounded-full",
                        activeCardIndex === dot_index
                          ? "bg-mama-default-primary"
                          : "bg-mama-gray-200",
                      )}
                      onClick={() => setActiveCardIndex(dot_index)}
                    />
                  ))}
                </div>

                <GhostButton
                  text=">"
                  className={twMerge(
                    "h-8 w-8 rounded-full border-0 text-xl",
                    activeCardIndex === defaultCards.length - 1
                      ? "cursor-default opacity-0"
                      : "opacity-100",
                  )}
                  textClassName="text-mama-gray-200"
                  onClick={() => {
                    if (activeCardIndex < defaultCards.length - 1)
                      setActiveCardIndex(activeCardIndex + 1);
                  }}
                />
              </div>
            </div>
          ),
      )}
    </>
  );
};
