import { useMemo } from "react";

import { Text } from "../../components/text";
import {
  AuthMessages,
  AuthMessageTypesEnum,
  sniperLinkConfig,
} from "../constants";
import { useAppLanguage } from "~/components/form/language/use-app-language";
import { SvgIcon } from "~/components/icons/svg-icon";
import { Link } from "~/components/link";

export const CognitoMagicLinkSent: React.FC<{ showText?: boolean }> = (
  { showText = true } = { showText: true },
) => {
  const { appLanguage } = useAppLanguage();
  const sniperLinks = useMemo(
    () =>
      Object.values(sniperLinkConfig).filter(
        (link) => !link.language || link.language === appLanguage,
      ),
    [appLanguage],
  );

  return (
    <div className="flex flex-col items-center justify-center gap-4">
      {showText && (
        <Text
          as="p"
          className="text-center text-balance"
          {...AuthMessages[AuthMessageTypesEnum.CONFIRM_SIGN_IN]}
        />
      )}

      <div className="flex flex-row justify-center gap-6">
        {sniperLinks.map((sniperLink, index) => (
          <Link key={index} href={sniperLink.link}>
            <SvgIcon icon={sniperLink.logo} className="w-12" />
          </Link>
        ))}
      </div>
    </div>
  );
};
