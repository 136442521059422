import { useForm } from "react-hook-form";
import { tx } from "shared/types/i18n";

import { SecondaryButton } from "../../components/form/button";
import { Form } from "../../components/form/form";
import { FormTextInput } from "../../components/form/form-text-input";
import { LoadingButton } from "../../components/form/loading-button";
import { Link } from "../../components/link";
import { Text } from "../../components/text";
import { useMamaNavigateState } from "../../navigation/use-mama-navigate-state";
import { isEmailRegex } from "../../util/regex";
import { useTenantId } from "../../util/use-active-tenant-id";
import { AuthPagesEnum } from "../constants";
import { useHandleSignIn } from "../hooks/use-handle-sign-in";
import { useMamaNavigate } from "~/navigation/mama-navigate";

export const CognitoSignIn: React.FC = () => {
  const tenantId = useTenantId();
  const handleSignIn = useHandleSignIn();

  const { prefillValues } = useMamaNavigateState();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<{ email: string; password: string }>({
    defaultValues: prefillValues,
  });

  useMamaNavigate(
    () => ({
      email: getValues("email"),
    }),
    [getValues],
  );

  return (
    <>
      <Form onSubmit={handleSubmit(handleSignIn)}>
        <FormTextInput
          title={{ tx: "auth.signIn.inputs.email.title" }}
          placeholder={{ tx: "auth.signIn.inputs.email.placeholder" }}
          autoComplete="username"
          {...register("email", {
            required: tx("auth.signIn.inputs.email.fieldMissingError"),
            pattern: {
              value: isEmailRegex,
              message: tx("auth.signIn.inputs.email.notAnEmailError"),
            },
          })}
          error={{ txUnchecked: errors.email?.message }}
        />
        <FormTextInput
          title={{ tx: "auth.signIn.inputs.password.title" }}
          placeholder={{ tx: "auth.signIn.inputs.password.placeholder" }}
          type="password"
          autoComplete="current-password"
          {...register("password", {
            required: tx("auth.signIn.inputs.password.fieldMissingError"),
          })}
          error={{ txUnchecked: errors.password?.message }}
        />
        <LoadingButton
          type="submit"
          Button={SecondaryButton}
          loading={isSubmitting}
          isDisabled={isSubmitting}
          tx="auth.signIn.signInButton"
        />
      </Form>
      <div>
        <Text
          as="p"
          tx="auth.navigation.signInWithMagicLink"
          txComponents={{
            SignInWithMagicLink: <Link href={AuthPagesEnum.MAGIC_SIGN_IN} />,
          }}
        />
        {!tenantId.organisation && (
          <Text
            as="p"
            tx="auth.navigation.signUpInstead"
            txComponents={{
              SignUpLink: <Link href={AuthPagesEnum.ONBOARDING} />,
            }}
          />
        )}
      </div>
    </>
  );
};
