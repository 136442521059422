import { ChangeEvent, useCallback, useEffect, useRef } from "react";

import { TextInput, TextInputProps } from "./text-input";

export const AutoResizingTextInput: React.FC<Omit<TextInputProps, "rows">> = ({
  value,
  onChange,
  ...rest
}) => {
  const textareaRef = useRef<HTMLInputElement>(null);

  const onChatInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
    },
    [onChange],
  );

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = textarea.scrollHeight + "px";
      textarea.style.overflowY = "hidden";
    }
  }, []);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    }
  }, [value]);

  return (
    <TextInput
      ref={textareaRef}
      value={value}
      onChange={onChatInputChange}
      {...rest}
    />
  );
};
