import { SvgIcon } from "~/components/icons/svg-icon";
import { Text } from "~/components/text";

export const TrustedBy: React.FC = () => {
  return (
    <div className="flex max-w-md flex-col items-center gap-4 md:max-w-2xl">
      <span>
        <Text
          as="span"
          tx="graph.text.trustedBy"
          className="text-center text-sm text-mama-default-primary"
        />
      </span>

      <div className="flex flex-wrap items-center justify-center gap-3 px-4 sm:gap-6 sm:px-0">
        <SvgIcon icon="europeanCommission" width={92} />
        <SvgIcon icon="kofinanziertVorDerEuropaischenUnion" width={127} />
        <SvgIcon icon="hassoPlattnerInstitut" width={49} />
        <SvgIcon icon="berlinSenatswerbungen" width={48} />
        <SvgIcon icon="fiveHTDigitalHub" width={45} />
        <SvgIcon icon="universitaDiSiena" width={93} />
        <SvgIcon icon="ebris" width={59} />
      </div>
    </div>
  );
};
