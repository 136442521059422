import { LogEventQueryParams } from "shared/types/auth";

import { consoleLogLocalhost, isLocalhost } from "./env-utils";

export const logActivity = async (
  token: string,
  isBearer: boolean,
  queryParams: LogEventQueryParams,
): Promise<void> => {
  if (isLocalhost) {
    consoleLogLocalhost(
      "Would have sent log activity with params:",
      queryParams.triggerSource,
      queryParams,
    );
    return;
  }

  try {
    const params = new URLSearchParams(queryParams as Record<string, string>);
    const appUrl = window.location.host;
    const finalToken = isBearer ? `Bearer ${token}` : token;

    await fetch(`https://users.${appUrl}/log?${params.toString()}`, {
      method: "GET",
      headers: {
        Authorization: finalToken,
      },
    });
  } catch (error) {
    console.error("Error in logActivity:", error);
  }
};
