import { TranslationFeKey } from "shared/types/translation-key";

import { CheckOutMamaAnswerMultipleChoice } from "./components/check-out-mama-answer-multiple-choice";
import { CountryDropdown } from "./components/country-dropdown";
import { EmailInput } from "./components/email-input";
import { EmailNameTerms } from "./components/email-name-terms";
import { EndoStudyAgreement } from "./components/endo-study-agreement";
import { IntroductionModal } from "./components/introduction-modal";
import { Media } from "./components/media";
import { MultipleChoice } from "./components/multiple-choice";
import { OpadeStudyIdInput } from "./components/opade-study-id-input";
import { OrganisationDropdown } from "./components/organisation-dropdown";
import { PhoneTextInput } from "./components/phone-input";
import { SingleChoice } from "./components/single-choice";
import { Slider } from "./components/slider";
import { Terms } from "./components/terms";
import { TextDrawer } from "./components/text-drawer";
import { TextDrawerSimple } from "./components/text-drawer-simple";
import { TextInput } from "./components/text-input";
import { YearOfBirth } from "./components/year-of-birth";
import { OnMessageSendFunction } from "./conversation";
import { emailValidation } from "./validation/email";
import { createValidationFunctionForMinLength } from "./validation/min-length";
import { opadeIdValidation } from "./validation/opade-study-id";
import { phoneValidation } from "./validation/phone";
import { yearOfBirthValidation } from "./validation/year-of-birth";
import { FallbackLookup } from "../lookup";

export type ChatFallbackLookup = FallbackLookup<
  React.FC<ChatOptionComponentProps>
>;

export const mamaAskChatDrawerComponents: ChatFallbackLookup =
  new FallbackLookup(
    [TextDrawer, undefined],
    // prettier-ignore
    [ 
      [["TEXT", "MARKETING_CHANNEL"], [TextInput, createValidationFunctionForMinLength(1)]],
      [["TEXT", "MIGRATION_BACKGROUND"], [TextInput, createValidationFunctionForMinLength(1)]],
      [["TEXT", undefined], [TextInput, undefined]],

      [["QUESTION", undefined], [TextDrawer, undefined]],
      
      [["APPROVAL", "ACKNOWLEDGE"], [SingleChoice, undefined]],
      [["APPROVAL", "TERMS"], [Terms, undefined]],
      [["APPROVAL", "ENDO_STUDY_AGREEMENT"], [EndoStudyAgreement, undefined]],
      [["APPROVAL", "LINK"], [CheckOutMamaAnswerMultipleChoice, undefined]],
      [["APPROVAL", "MEDIA"], [Media, undefined]],

      [["DYNAMIC", "PAG"], [OrganisationDropdown, undefined]],
      [["DYNAMIC", "COUNTRY"], [CountryDropdown, undefined]],

      [["MODAL", "INTRODUCTION"], [IntroductionModal, undefined]],

      [["MULTIPLE_CHOICE", undefined], [MultipleChoice, undefined]],
      [["MULTIPLE_CHOICE_OTHER", undefined], [MultipleChoice, undefined]],
      // [["MULTIPLE_ENUM", undefined], [MultipleChoice, undefined]],

      [["REGEX", "PHONE"], [PhoneTextInput, phoneValidation]],
      [["REGEX", "YEAR_OF_BIRTH"], [YearOfBirth, yearOfBirthValidation]],
      [["REGEX", "OPADE_STUDY_ID"], [OpadeStudyIdInput, opadeIdValidation]],
      [["REGEX", "POSTAL_CODE"], [TextInput, createValidationFunctionForMinLength(3)]],
      [["REGEX", undefined], [TextInput, undefined]],

      [["SIGNUP", "EMAIL"], [EmailInput, emailValidation]],
      [["SIGNUP", "EMAIL_NAME_TERMS"], [EmailNameTerms, undefined]],

      [["SINGLE_CHOICE", undefined], [SingleChoice, undefined]],
      [["SINGLE_ENUM", "GENDER"], [SingleChoice, undefined]],

      [["SCALE", "ZERO_TO_TEN"], [Slider, undefined]]
    ],
  );

export const mamaAnswerChatDrawerComponents = new FallbackLookup<
  React.FC<ChatOptionComponentProps>
>(
  [TextDrawer, undefined],
  // prettier-ignore
  [ 
    [["QUESTION", undefined], [TextDrawerSimple, createValidationFunctionForMinLength(1)]],
  ],
);

export interface ChatOptionComponentProps {
  choices: TranslationFeKey[];
  // questionNodeInfo: FrontendQuestionNodeInfo;
  sendResponse: OnMessageSendFunction;
  mediaSrc?: string;
  mediaAlt?: string;
}
