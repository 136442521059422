import { useMemo } from "react";
import { FrontendPageEnum } from "shared/model/pages";

import { EnumTitle } from "./enum-title";
import { EnumIcon } from "./icons/enum-icon";
import { Text } from "./text";
import { EventDetailRecord } from "../api/generated/landbot/models/EventDetailRecord";
import { usePageSettings, useTenantConfig } from "../tenant-settings";
import { joinDetails } from "../util/join-details";
import { useUser } from "../util/use-user";

const defaultDisplayedUserDetailTypes = [
  "GENDER",
  "COUNTRY",
  "YEAR_OF_BIRTH",
  "MULTIPLE_MEDICATION",
  "MULTIPLE_COMORBIDITY",
  "MULTIPLE_INTERVENTION",
];

export const UserInfo: React.FC<{ userDetails: EventDetailRecord[] }> = ({
  userDetails,
}) => {
  const tenantConfig = useTenantConfig();
  const settings = usePageSettings(FrontendPageEnum.JOURNEY);
  const displayedUserDetailTypes = useMemo(
    () =>
      new Set(
        settings?.displayCaseDataEnums ?? defaultDisplayedUserDetailTypes,
      ),
    [settings],
  );
  const user = useUser();

  const flatUserDetails = useMemo(() => {
    const collectUserDetails = (
      details: EventDetailRecord[],
    ): EventDetailRecord[] => {
      const results: EventDetailRecord[] = [];
      for (const detail of details) {
        results.push({
          ...detail,
          details: [],
        });
        if (detail.details.length > 0)
          results.push(...collectUserDetails(detail.details));
      }
      return results;
    };

    return collectUserDetails(userDetails);
  }, [userDetails]);

  return (
    <>
      {user?.userAttributes?.name && (
        <Text
          text={user.userAttributes.name}
          className="mb-5 ml-4 self-center hyphens-auto text-center text-2xl font-bold"
        />
      )}

      <div className="grid grid-cols-1  gap-3 md:grid-cols-2 lg:grid-cols-4">
        {flatUserDetails.map(
          (detail) =>
            displayedUserDetailTypes.has(detail.type) && (
              <div
                key={detail.id}
                className="mb-5 flex max-w-xs flex-col items-center justify-center rounded-lg bg-slate-100 py-3 text-center md:p-3"
              >
                <EnumIcon
                  width={60}
                  height={60}
                  type="detail"
                  enumValue={detail.type}
                  disease={tenantConfig?.disease || "common"}
                />
                <EnumTitle
                  className="break-words px-2 text-center text-[11px] font-semibold text-blue-500 md:text-lg"
                  disease={tenantConfig?.disease || "common"}
                  type="detail"
                  enumValue={detail.type}
                />
                <div className="text-light-grey w-[calc(100%-2rem)] hyphens-auto text-[10px] text-xs font-light sm:text-sm">
                  {joinDetails(detail)}
                </div>
              </div>
            ),
        )}
      </div>
    </>
  );
};
