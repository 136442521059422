import { useState } from "react";
import { TranslationFeKey } from "shared/types/translation-key";


import { ChatOptionComponentProps } from "../lookup";
import { Button } from "~/components/form/button";
import { Text } from "~/components/text";
import { useT } from "~/i18n/use-t";
import { cn } from "~/util/cn";

export const MultipleChoice: React.FC<ChatOptionComponentProps> = ({
  choices,
  sendResponse,
}) => {
  const t = useT();
  const [selection, setSelection] = useState<string[]>([]);

  return (
    <div className="flex items-center justify-center gap-2">
      <div className="dropdown-top dropdown">
        <div
          tabIndex={0}
          role="button"
          className="btn m-1 border border-base-300"
        >
          {selection.length === 0 ? (
            <Text tx="graph.text.errorMessage.atLeastOne" />
          ) : (
            <div className="flex flex-nowrap items-center gap-1">
              <Text tx="graph.text.selected" />
              {selection.length}
            </div>
          )}
        </div>
        <ul
          tabIndex={0}
          className="dropdown-content menu z-10 w-52 rounded-box bg-base-100 p-2 shadow-sm"
        >
          {choices.map((choice) => {
            const isSelected = selection.includes(choice);
            return (
              <li key={choice} className="mt-1 rounded-md border">
                <button
                  title={t({ tx: choice })}
                  onClick={() => {
                    setSelection((previous) => {
                      if (isSelected) {
                        return previous.filter((c) => c !== choice);
                      }
                      return [...previous, choice];
                    });
                  }}
                  className={cn(
                    "w-full text-left",
                    isSelected
                      ? "bg-primary text-primary-content hover:bg-primary focus:bg-primary"
                      : "bg-transparent",
                  )}
                >
                  <Text tx={choice} />
                </button>
              </li>
            );
          })}
        </ul>
      </div>
      <Button
        icon="paperPlane"
        onClick={() => {
          if (selection.length > 0) {
            const message = selection
              .map((choice) => t({ tx: choice as TranslationFeKey }))
              .join(",");

            sendResponse({
              message,
              selection: selection,
            });
          }
        }}
        isDisabled={selection.length === 0}
      />
    </div>
  );
};
