import React, { ElementType, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { Disableable } from "../../types/disableable";
import { IconColor, IconType, SvgIcon } from "../icons/svg-icon";
import { I18nProps, Text } from "../text";
import { NavigationRoute, useMamaNavigate } from "~/navigation/mama-navigate";

type IconDimension = `${string}px` | `${string}rem` | number;

export type ButtonProps = Disableable<JSX.IntrinsicElements["button"]> &
  PropsWithChildren<
    {
      as?: ElementType;
      icon?: IconType;
      iconColor?: IconColor;
      iconWidth?: IconDimension;
      iconHeight?: IconDimension;
      textClassName?: string;
    } & I18nProps
  >;

export const Button: React.FC<ButtonProps> = ({
  children,
  isDisabled,
  onClick,
  className,
  as: Component = "button",
  tx,
  text,
  txComponents,
  txData,
  icon,
  iconColor,
  iconWidth,
  iconHeight,
  textClassName,
  ...rest
}) => {
  const button = (
    <Component
      className={twMerge(
        "btn flex h-fit flex-row gap-2 border-mama-gray-200",
        className,
        isDisabled ? "btn-disabled" : "",
      )}
      disabled={isDisabled}
      onClick={isDisabled ? undefined : onClick}
      {...rest}
    >
      {icon && (
        <SvgIcon
          icon={icon}
          color={iconColor}
          width={iconWidth ?? "2.5rem"}
          height={iconHeight ?? "2.5rem"}
        />
      )}
      <Text
        tx={tx}
        text={text}
        txComponents={txComponents}
        txData={txData}
        className={textClassName}
      />
      {children}
    </Component>
  );

  return button;
};

export const PrimaryButton: React.FC<ButtonProps> = ({
  className,
  textClassName,
  ...rest
}) => {
  return (
    <Button
      className={twMerge("bg-bg-blue-900", className)}
      textClassName={twMerge("text-mama-white", textClassName)}
      {...rest}
    />
  );
};

export const SecondaryButton: React.FC<ButtonProps> = ({
  className,
  textClassName,
  ...rest
}) => {
  return (
    <Button
      className={twMerge("bg-mama-green-300", className)}
      textClassName={twMerge("text-mama-white", textClassName)}
      {...rest}
    />
  );
};

export const AccentButton: React.FC<ButtonProps> = ({ className, ...rest }) => {
  return <Button className={twMerge("btn-accent", className)} {...rest} />;
};

export const NeutralButton: React.FC<ButtonProps> = ({
  className,
  ...rest
}) => {
  return <Button className={twMerge("btn-neutral", className)} {...rest} />;
};

export const InfoButton: React.FC<ButtonProps> = ({ className, ...rest }) => {
  return <Button className={twMerge("btn-info", className)} {...rest} />;
};

export const SuccessButton: React.FC<ButtonProps> = ({
  className,
  ...rest
}) => {
  return <Button className={twMerge("btn-success", className)} {...rest} />;
};

export const ErrorButton: React.FC<ButtonProps> = ({ className, ...rest }) => {
  return <Button className={twMerge("btn-error", className)} {...rest} />;
};

export const WarningButton: React.FC<ButtonProps> = ({
  className,
  ...rest
}) => {
  return <Button className={twMerge("btn-warning", className)} {...rest} />;
};

export const OutlineButton: React.FC<ButtonProps> = ({
  className,
  ...rest
}) => {
  return <Button className={twMerge("btn-outline", className)} {...rest} />;
};

export const GhostButton: React.FC<ButtonProps> = ({ className, ...rest }) => {
  return <Button className={twMerge("btn-ghost", className)} {...rest} />;
};

export const Buttons: {
  [theme in
    | "base"
    | "primary"
    | "secondary"
    | "accent"
    | "neutral"
    | "info"
    | "success"
    | "error"
    | "warning"
    | "outline"
    | "ghost"]: React.FC<ButtonProps>;
} = {
  base: Button,
  primary: PrimaryButton,
  secondary: SecondaryButton,
  accent: AccentButton,
  neutral: NeutralButton,
  info: InfoButton,
  success: SuccessButton,
  error: ErrorButton,
  warning: WarningButton,
  outline: OutlineButton,
  ghost: GhostButton,
};

export const NavigationButton: React.FC<
  {
    to: NavigationRoute;
    theme?: keyof typeof Buttons;
  } & Omit<ButtonProps, "onClick">
> = ({ to, theme = "primary", ...rest }) => {
  const navigate = useMamaNavigate();

  const ButtonComponent = Buttons[theme];
  return <ButtonComponent onClick={() => navigate(to)} {...rest} />;
};
