import { createContext, useContext, useState } from "react";

type LanguageSelectorContextProps = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
};

const LanguageSelectorVisibilityContext = createContext<
  LanguageSelectorContextProps | undefined
>(undefined);

export const LanguageSelectorVisibilityProvider: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <LanguageSelectorVisibilityContext.Provider
      value={{ isVisible, setIsVisible }}
    >
      {children}
    </LanguageSelectorVisibilityContext.Provider>
  );
};

export const useLanguageSelectorVisibility =
  (): LanguageSelectorContextProps => {
    const context = useContext(LanguageSelectorVisibilityContext);
    if (!context) {
      throw new Error(
        "useLanguageSelectorVisibility must be used within a LanguageSelectorVisibilityProvider",
      );
    }
    return context;
  };
