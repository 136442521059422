import {
  MutationFunction,
  MutationKey,
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useMemo } from "react";
import { frontendPathHeader, traceIdHeader } from "shared/types/cors";
import { v4 } from "uuid";

import * as BackendClient from "./generated/backend";
import * as LandbotClient from "./generated/landbot";
import * as MultiagentClient from "./generated/multiagent";
import { ApiClient, ApiClientService, apiUrl } from "./types/api";

export const usePublicApiQuery = <
  TClient extends ApiClientService,
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey & Array<unknown> = QueryKey & Array<unknown>,
>(
  client: TClient,
  callApi: (api: ApiClient<TClient>) => Promise<TQueryFnData>,
  queryKey: TQueryKey,
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<TData, TError> => {
  const apiClient = usePublicApiClient(client);
  return useQuery(queryKey, () => callApi(apiClient), {
    ...options,
    enabled: options?.enabled,
  });
};

export const usePublicApiMutation = <
  TClient extends ApiClientService,
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  client: TClient,
  getMutationFunction: (
    api: ApiClient<TClient>,
  ) => MutationFunction<TData, TVariables>,
  mutationKey?: MutationKey & Array<unknown>,
  options?: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    "mutationKey" | "mutationFn"
  >,
): UseMutationResult<TData, TError, TVariables, TContext> => {
  const apiClient = usePublicApiClient(client);

  return useMutation(getMutationFunction(apiClient), {
    ...options,
    mutationKey,
  });
};

const usePublicApiClient = <TClient extends ApiClientService>(
  client: TClient,
): ApiClient<TClient> => {
  const defaultApi = useMemo(() => {
    const config: BackendClient.ConfigurationParameters = {
      credentials: "same-origin",
      basePath: `${apiUrl[client]}-public`,
      middleware: [
        {
          pre: async (context) => {
            context.init.headers = {
              ...context.init.headers,
              [traceIdHeader]: v4(),
              [frontendPathHeader]: window.location.pathname,
            };
          },
        },
      ],
    };

    return (
      client === "multiagent"
        ? new MultiagentClient.DefaultApi(
            new MultiagentClient.Configuration(config),
          )
        : client === "backend"
        ? new BackendClient.DefaultApi(new BackendClient.Configuration(config))
        : new LandbotClient.DefaultApi(new LandbotClient.Configuration(config))
    ) as ApiClient<TClient>;
  }, [client]);

  return defaultApi;
};
