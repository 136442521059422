/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServerMessageMetadata
 */
export interface ServerMessageMetadata {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ServerMessageMetadata
     */
    sources?: { [key: string]: string; };
}

/**
 * Check if a given object implements the ServerMessageMetadata interface.
 */
export function instanceOfServerMessageMetadata(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ServerMessageMetadataFromJSON(json: any): ServerMessageMetadata {
    return ServerMessageMetadataFromJSONTyped(json, false);
}

export function ServerMessageMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServerMessageMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sources': !exists(json, 'sources') ? undefined : json['sources'],
    };
}

export function ServerMessageMetadataToJSON(value?: ServerMessageMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sources': value.sources,
    };
}

