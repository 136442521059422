import { confirmSignIn, signIn } from "aws-amplify/auth";
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { useClientMetaData } from "./use-client-meta-data";
import {
  SpecialNavigationRoutes,
  useMamaNavigate,
} from "../../navigation/mama-navigate";
import {
  AuthPagesEnum,
  MAGIC_LINK_SIGN_IN_AUTH_FLOW,
  MAGIC_LINK_SIGN_IN_METHOD,
  SignInNextStepEnum,
} from "../constants";
import { handleCognitoError, MamaCognitoError } from "../utils/cognito-errors";

export const useHandleMagicLinkVerification = (): (() => Promise<void>) => {
  const navigate = useMamaNavigate();
  const [params] = useSearchParams();
  const clientMetadata = useClientMetaData({
    signInMethod: MAGIC_LINK_SIGN_IN_METHOD,
    alreadyHaveMagicLink: "yes",
  });

  const token = useMemo(() => params.get("token"), [params]);

  const dataToSend = useMemo(
    () =>
      token
        ? {
            challengeResponse: token,
            options: {
              clientMetadata,
            },
          }
        : undefined,
    [clientMetadata, token],
  );

  const username = useMemo(() => {
    if (!dataToSend) {
      return undefined;
    }
    return decodeMagicToken(dataToSend.challengeResponse);
  }, [dataToSend]);

  return useCallback(async () => {
    try {
      if (!dataToSend || !username) {
        throw new MamaCognitoError({
          name: "auth.signInMagic.verifyMagicLinkError",
        });
      }

      const signInResult = await signIn({
        username,
        options: {
          authFlowType: MAGIC_LINK_SIGN_IN_AUTH_FLOW,
          autoSignIn: false,
          clientMetadata: dataToSend.options.clientMetadata,
        },
      });

      if (
        !signInResult.isSignedIn &&
        signInResult.nextStep.signInStep ===
          SignInNextStepEnum.CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE
      ) {
        const confirmSignInResult = await confirmSignIn(dataToSend);

        if (confirmSignInResult.isSignedIn) {
          navigate(SpecialNavigationRoutes.HOME);
        }
      }
    } catch (error) {
      const mamaCognitoError = new MamaCognitoError({
        name: "auth.loginProblem.outdatedLink",
      });
      navigate(AuthPagesEnum.LOGIN_PROBLEM, {
        replace: true,
        error: handleCognitoError(mamaCognitoError, { username }),
      });
    }
  }, [dataToSend, navigate, username]);
};

const decodeMagicToken = (token: string) => {
  try {
    const header = token.split(".")[0];
    const message = JSON.parse(atob(header));

    assertIsMessage(message);

    return message.cognitoSub;
  } catch (err) {
    throw new MamaCognitoError({
      name: "auth.signIn.errors.errorDecodingMagicLinkToken",
    });
  }
};

function assertIsMessage(
  msg: unknown,
): asserts msg is { cognitoSub: string; exp: number; iat: number } {
  if (
    !msg ||
    typeof msg !== "object" ||
    !("cognitoSub" in msg) ||
    typeof msg.cognitoSub !== "string" ||
    !("exp" in msg) ||
    typeof msg.exp !== "number" ||
    !("iat" in msg) ||
    typeof msg.iat !== "number"
  ) {
    throw new MamaCognitoError({ name: "auth.signIn.errors.invalidMagicLink" });
  }
}
