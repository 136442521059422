import { signIn, signOut } from "aws-amplify/auth";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { MIN_PASSWORD_LENGTH } from "shared/config/cognito";
import { tx } from "shared/types/i18n";
import { TranslationFeKey } from "shared/types/translation-key";

import { AuthPagesEnum } from "~/auth/constants";
import { useClientMetaData } from "~/auth/hooks/use-client-meta-data";
import { handleCognitoError } from "~/auth/utils/cognito-errors";
import { SecondaryButton } from "~/components/form/button";
import { Form } from "~/components/form/form";
import { FormTextInput } from "~/components/form/form-text-input";
import { LoadingButton } from "~/components/form/loading-button";
import { LoadingIndicator } from "~/components/loading-spinner";
import {
  SpecialNavigationRoutes,
  useMamaNavigate,
} from "~/navigation/mama-navigate";
import { useStrictModeSafeEffect } from "~/util/hooks";

export const CognitoMigrateAccount: React.FC = () => {
  const [params] = useSearchParams();
  const navigate = useMamaNavigate();
  const [signedIn, setSignedIn] = useState(false);
  const email = params.get("email");
  const clientMetadata = useClientMetaData();
  const tmpPassword = useMemo(
    () => params.get("accountMigrationCode"),
    [params],
  );

  const handleCognitoMigrateAccount = useCallback(async () => {
    if (!email || !tmpPassword) {
      navigate(AuthPagesEnum.SIGN_IN, {
        replace: true,
        error: {
          tx: "auth.migrate.linkInvalidError",
        },
      });
      return;
    }

    try {
      const { isSignedIn, nextStep } = await signIn({
        username: email,
        password: tmpPassword,
        options: { clientMetadata: clientMetadata },
      });

      if (
        isSignedIn &&
        nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
      ) {
        setSignedIn(isSignedIn);
      } else {
        signOut().then(() => {
          navigate(AuthPagesEnum.SIGN_IN, {
            replace: true,
            error: { tx: "auth.migrate.signInSucceededUnexpectedlyError" },
          });
        });
      }
    } catch (error) {
      navigate(AuthPagesEnum.SIGN_IN, {
        replace: true,
        error: {
          tx: "auth.migrate.linkInvalidError",
        },
      });
    }
  }, [clientMetadata, email, navigate, tmpPassword]);

  useStrictModeSafeEffect(handleCognitoMigrateAccount);

  return signedIn && email ? (
    <CognitoMigrateAccountWithConfirmedUser email={email} />
  ) : (
    <div className="min-h-16 flex flex-row items-center justify-center self-stretch">
      <LoadingIndicator
        as="spinner"
        message={{ tx: "auth.migrate.loadingMigrationPageStatusMessage" }}
      />
    </div>
  );
};

const CognitoMigrateAccountWithConfirmedUser: React.FC<{
  email: string;
}> = ({ email }) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<{
    newPassword: string;
    confirmNewPassword: string;
  }>();
  const navigate = useMamaNavigate();

  const clientMetadata = useClientMetaData();

  const onSubmitMigrateAccount = handleSubmit(async ({ newPassword }) => {
    try {
      await signIn({
        username: email,
        password: newPassword,
        options: { clientMetadata: clientMetadata },
      });

      navigate(SpecialNavigationRoutes.RELOAD, {
        replace: true,
        info: {
          tx: "auth.migrate.setPasswordSuccessfulAddCustomFields",
        },
      });
    } catch (error) {
      navigate(AuthPagesEnum.MIGRATE_ACCOUNT, {
        error: handleCognitoError(error, { email }),
      });
    }
  });

  return (
    <Form onSubmit={onSubmitMigrateAccount}>
      <FormTextInput
        title={{ tx: "auth.migrate.inputs.newPassword.title" }}
        placeholder={{
          tx: "auth.migrate.inputs.newPassword.placeholder",
        }}
        autoComplete="new-password"
        type="password"
        {...register("newPassword", {
          required: tx("auth.migrate.inputs.newPassword.fieldMissingError"),
          minLength: {
            value: MIN_PASSWORD_LENGTH,
            message: tx(
              "auth.migrate.inputs.newPassword.passwordTooShortError",
            ),
          },
        })}
        error={{
          txUnchecked: errors.newPassword?.message,
          txData: { min_password_length: MIN_PASSWORD_LENGTH },
        }}
      />
      <FormTextInput
        title={{ tx: "auth.migrate.inputs.confirmPassword.title" }}
        placeholder={{
          tx: "auth.migrate.inputs.confirmPassword.placeholder",
        }}
        autoComplete="new-password"
        type="password"
        {...register("confirmNewPassword", {
          required: tx("auth.migrate.inputs.confirmPassword.fieldMissingError"),
          validate: (val: string): TranslationFeKey | undefined => {
            if (getValues("newPassword") !== val) {
              return "auth.migrate.inputs.confirmPassword.passwordsDoNotMatchError";
            }
          },
        })}
        error={{ txUnchecked: errors.confirmNewPassword?.message }}
      />
      <LoadingButton
        type="submit"
        loading={isSubmitting}
        Button={SecondaryButton}
        tx="auth.migrate.updateAccountButtonFirstStep"
      />
    </Form>
  );
};
