import { tx } from "shared/types/i18n";

import {
  isOlderThan,
  isYoungerThan,
} from "../../../../../../util/age-comparison";
import { ValidationFunction } from "../../lookup";

export const yearOfBirthValidation: ValidationFunction = (value) => {
  if (Number.isInteger(Number(value)) === false) {
    return {
      ok: false,
      message: { tx: tx("userData.pleaseInputAValidYearOfBirth") },
    };
  }

  if (isOlderThan({ year: Number(value), minimumAge: 16 }) === false) {
    return { ok: false, message: { tx: tx("userData.youMustBeOlderError") } };
  }

  if (isYoungerThan({ year: Number(value), maximumAge: 100 }) === false) {
    return { ok: false, message: { tx: tx("userData.youMustBeYoungerError") } };
  }

  return {
    ok: true,
  };
};
