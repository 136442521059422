import { useQueryClient } from "@tanstack/react-query";
import { PropsWithChildren, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { diseaseMap, organisationMap } from "shared/config";
import { nonUserGroups } from "shared/model/cognito";

import { JoinGroupRequest } from "../api/generated/backend";
import { UserGroup } from "../api/generated/multiagent";
import { toTenantIdHeader, useApiMutation, useApiQuery } from "../api/use-api";
import { Button, PrimaryButton } from "../components/form/button";
import { LoadingScreen } from "../components/loading-screen";
import { Text } from "../components/text";
import { useT } from "../i18n/use-t";
import { DefaultContentContainer } from "../layouts/default-content-container";
import {
  FOLLOWED_PAGS_KEY,
  IS_USER_FOLLOWING_TENANT_KEY,
  ME_KEY,
} from "../types/query-keys";
import { useTenantId } from "../util/use-active-tenant-id";
import { useUser } from "../util/use-user";
import { EmptyWrapper } from "~/components/util/empty-wrapper";

export const EnsureUserFollowsTenantGuard: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const user = useUser();

  const tenantId = useTenantId();
  const queryClient = useQueryClient();
  const t = useT();

  const navigate = useNavigate();

  const { data: isUserFollowingTenant } = useApiQuery(
    "backend",
    (api) => api.isUserFollowingTenant(toTenantIdHeader(tenantId)),
    IS_USER_FOLLOWING_TENANT_KEY(tenantId),
    undefined,
  );

  const { mutate: followTenant } = useApiMutation(
    "backend",
    (api) => (request: JoinGroupRequest) => api.joinGroup(request),
    undefined,
    undefined,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ME_KEY(tenantId));
        queryClient.invalidateQueries(IS_USER_FOLLOWING_TENANT_KEY(tenantId));
        queryClient.invalidateQueries(FOLLOWED_PAGS_KEY(tenantId.disease));
      },
    },
  );

  const hasNonUserGroup = useMemo(() => {
    return nonUserGroups.some((g) => user?.tenantGroups?.includes(g));
  }, [user?.tenantGroups]);

  const showChildren = useMemo(
    () => isUserFollowingTenant?.isFollowingTenant || hasNonUserGroup,
    [hasNonUserGroup, isUserFollowingTenant?.isFollowingTenant],
  );

  return showChildren === undefined ? null : showChildren ? (
    <EmptyWrapper>{children}</EmptyWrapper>
  ) : (
    <DefaultContentContainer className="flex h-full items-center justify-center">
      {!isUserFollowingTenant ? (
        <LoadingScreen />
      ) : (
        <div className="column flex flex-col gap-5">
          <Text
            className="text-2xl"
            tx={
              tenantId.organisation
                ? "organisation-disease-guard.organisationAndDiseaseFollowText"
                : "organisation-disease-guard.diseaseFollowText"
            }
            txData={{
              disease: t({ tx: diseaseMap[tenantId.disease].tenantNameTx }),
              organisation: tenantId.organisation
                ? t({
                    tx: organisationMap[tenantId.organisation].tenantNameTx,
                  })
                : "?",
            }}
          />
          <Button
            onClick={() =>
              followTenant({
                groupInfoDto: {
                  group: UserGroup.User,
                  organisation: tenantId.organisation,
                },
                mamaDisease: tenantId.disease,
              })
            }
            tx={
              tenantId.organisation
                ? "organisation-disease-guard.followButton"
                : "organisation-disease-guard.confirmButton"
            }
          />
          <PrimaryButton
            className="-mt-2"
            onClick={() => {
              navigate(-1);
            }}
            tx="organisation-disease-guard.dontHaveThisDisease"
          />
        </div>
      )}
    </DefaultContentContainer>
  );
};
