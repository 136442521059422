"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cognitoInfoApp = exports.MIN_PASSWORD_LENGTH = void 0;
const deployment_environments_1 = require("./deployment-environments");
const deployment_environment_1 = require("../model/deployment-environment");
exports.MIN_PASSWORD_LENGTH = 8;
exports.cognitoInfoApp = (0, deployment_environment_1.perDeploymentEnv)({
    [deployment_environments_1.DeploymentEnvironment.DEV]: {
        userPoolId: "eu-central-1_pQnbyg9RA",
        identityPoolId: "eu-central-1:098c6374-79f8-4d03-b7eb-1be1b98d4702",
        userPoolClientId: "10svjgt32g1je9o4drb01stst5",
    },
    [deployment_environments_1.DeploymentEnvironment.PROD]: {
        userPoolId: "eu-central-1_CTvRPRFEp",
        identityPoolId: "eu-central-1:c3e562a0-f506-4bef-9144-5cf00b784f29",
        userPoolClientId: "4bae4e8aqnjcjjc4hfgj8unp08",
    },
});
