import { LoadingIndicator } from "../../components/loading-spinner";
import { useHandleMagicLinkVerification } from "~/auth/hooks/use-handle-magic-link-verification";
import { useStrictModeSafeEffect } from "~/util/hooks";

export const CognitoVerifyMagicLink: React.FC = () => {
  const handleMagicLinkVerification = useHandleMagicLinkVerification();

  useStrictModeSafeEffect(handleMagicLinkVerification);

  return (
    <div className="min-h-16 flex flex-row items-center justify-center self-stretch">
      <LoadingIndicator
        as="spinner"
        message={{ tx: "auth.signUp.confirmingSignUpWaitingMessage" }}
      />
    </div>
  );
};
