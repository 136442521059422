import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Language } from "shared/model/languages";
import { SECONDS_MAGIC_LINK_COOLDOWN } from "shared/utils/constants";
import { z } from "zod";

import { PUBLIC_CHAT_LOCAL_STORAGE_KEY } from "../../../chat/ai-public-chat";
import { OnMessageSendFunction } from "../../../chat/conversation";
import { TrustedBy } from "../components/trusted-by";
import { MessageProcessedChoices } from "../message-component";
import { useFocusFirstElement } from "../utils";
import { useHandleMagicLinkSignUp } from "~/auth/hooks/use-handle-magic-link-sign-up";
import { CognitoMagicLinkSent } from "~/auth/routes/cognito-magic-link-sent";
import { IconType, SvgIcon } from "~/components/icons/svg-icon";
import { Link } from "~/components/link";
import { Text } from "~/components/text";
import { useT } from "~/i18n/use-t";
import { cn } from "~/util/cn";
import { userHasCreatedAccount } from "~/util/data-layer-actions";

const Form: React.FC<{
  message: MessageProcessedChoices;
  onAccountCreated: (accountData: { name: string; email: string }) => void;
}> = ({ message, onAccountCreated }) => {
  const t = useT();

  const schema = z.object({
    name: z.string().min(1, { message: t({ tx: "study.name.required" }) }),
    email: z
      .string()
      .regex(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        { message: t({ tx: "study.email.required" }) },
      ),
    terms: z.boolean().refine((value) => value === true, {
      message: t({ tx: "study.acceptTerms.required" }),
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleMagicLinkSignUp = useHandleMagicLinkSignUp({ legacy: false });
  const { parentRef } =
    useFocusFirstElement<HTMLDivElement>("div > div > input");

  const onSubmit = async (data: z.infer<typeof schema>) => {
    setIsLoading(true);
    const successful = await handleMagicLinkSignUp({
      name: data.name,
      email: data.email,
    });
    setIsLoading(false);

    if (!successful) return;

    userHasCreatedAccount();

    onAccountCreated(data);
  };

  return (
    <section className="card w-full border bg-white shadow-lg sm:w-[34rem]">
      <form onSubmit={handleSubmit(onSubmit)} className="card-body text-center">
        <h3 className="card-title block text-center text-xl font-bold text-mama-default-primary text-balance">
          {message.contentLocalized}
        </h3>

        <div ref={parentRef} className="mt-6 w-full">
          <div>
            <input
              type="text"
              {...register("name")}
              className={cn(
                "input-bordered input w-full focus:outline-primary/80",
                errors.name
                  ? "input-error focus:outline-error/80"
                  : "focus:outline-primary/80",
              )}
              placeholder={t({ tx: "study.name.placeholder" })}
              autoComplete="name"
              disabled={isLoading}
            />
            {errors.name && (
              <p className="mt-1 text-left text-sm text-red-500">
                {errors.name.message}
              </p>
            )}
          </div>
        </div>

        <div className="mt-1 w-full">
          <input
            type="email"
            {...register("email")}
            className={cn(
              "input-bordered input w-full focus:outline-primary/80",
              errors.email
                ? "input-error focus:outline-error/80"
                : "focus:outline-primary/80",
            )}
            placeholder={t({ tx: "study.email.placeholder" })}
            autoComplete="email"
            disabled={isLoading}
          />
          {errors.email && (
            <p className="mt-1 text-left text-sm text-red-500">
              {errors.email.message}
            </p>
          )}
        </div>

        <div className="mt-6 flex w-full flex-col items-center">
          <div className="flex w-full items-center gap-y-1">
            <input
              id="terms"
              type="checkbox"
              {...register("terms")}
              className="checkbox checkbox-primary"
              disabled={isLoading}
            />
            <Text
              className="ml-2 text-left"
              tx="signUp.customFields.mamaTermsAndConditions.agreeToTermsAndConditions"
              txComponents={{
                href: (
                  <Link
                    className="font-normal text-bg-blue-900 underline"
                    target="_blank"
                  />
                ),
              }}
            />
          </div>
          {errors.terms && (
            <p className="mt-1 w-full text-left text-sm text-red-500">
              {errors.terms.message}
            </p>
          )}
        </div>

        <Text
          className="text-left"
          tx="signUp.customFields.mamaPrivacyPolicy.checkOutPrivacyPolicy"
          txComponents={{
            href: (
              <Link
                target="_blank"
                className="font-normal text-bg-blue-900 underline"
              />
            ),
          }}
        />

        <div className="card-actions mt-6">
          <button
            disabled={isLoading}
            type="submit"
            className="btn-primary btn-block btn"
          >
            {isLoading && (
              <span className="loading loading-spinner loading-md" />
            )}

            {isLoading ? (
              <Text tx="auth.signUp.signUpButtonLoading" />
            ) : (
              <Text tx="auth.signUp.signUpButton" />
            )}
          </button>
        </div>
      </form>
    </section>
  );
};

const DevelopedByDoctors: React.FC = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const map: Record<Language, IconType> = {
    [Language.de_CH]: "developedByDoctorsDe",
    [Language.de_DE]: "developedByDoctorsDe",
    [Language.en_US]: "developedByDoctorsEn",
    [Language.es_CO]: "developedByDoctorsEs",
    [Language.es_ES]: "developedByDoctorsEs",
    [Language.fr_FR]: "developedByDoctorsFr",
    [Language.it_IT]: "developedByDoctorsIt",
    [Language.nl_NL]: "developedByDoctorsEn",
    [Language.tr_TR]: "developedByDoctorsEn",
  };

  return (
    <div className="w-40 rounded-2xl border border-neutral/10 bg-white px-4 py-2 shadow-2xl">
      <SvgIcon icon={map[language as Language]} />
    </div>
  );
};

const ThankYou: React.FC<{ data: { name: string; email: string } }> = ({
  data,
}) => {
  return (
    <section className="flex flex-col items-center gap-y-16">
      <DevelopedByDoctors />

      <div className="card w-full border bg-white shadow-lg sm:w-[34rem]">
        <div className="card-body text-center">
          <Text
            as="h3"
            tx="graph.text.thankYou"
            className="card-title block text-center text-4xl font-bold text-mama-default-primary text-balance"
          />

          <Text className="mt-6 font-normal" tx="graph.text.signup.info" />
          <Text
            className="font-normal"
            tx="graph.text.signup.restartChat"
            txComponents={{
              restartChat: (
                <Link
                  onClick={async () => {
                    localStorage.removeItem(PUBLIC_CHAT_LOCAL_STORAGE_KEY);
                    location.reload();
                  }}
                />
              ),
            }}
          />
        </div>
      </div>

      <div className="flex flex-col gap-6">
        <CognitoMagicLinkSent showText={false} />

        <ButtonResend
          countdownMs={SECONDS_MAGIC_LINK_COOLDOWN * 1_000}
          data={data}
        />
      </div>

      <TrustedBy />
    </section>
  );
};

export const ButtonResend: React.FC<{
  countdownMs: number;
  data: { name: string; email: string };
  className?: string;
}> = ({ countdownMs, data: { email, name }, className }) => {
  const t = useT();
  const [isLoading, setIsLoading] = useState(false);
  const [canRequestResend, setCanRequestResend] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(Math.ceil(countdownMs / 1000));
  const handleMagicLinkSignUp = useHandleMagicLinkSignUp({ legacy: false });
  const intervalId = useRef<NodeJS.Timeout | null>(null);

  const resendText = t(
    canRequestResend
      ? { tx: "graph.choice.SIGNUP.resend" }
      : {
          tx: "graph.choice.SIGNUP.resendIn",
          txData: { time: secondsLeft },
        },
  );

  useEffect(() => {
    setCanRequestResend(false);
    setSecondsLeft(Math.ceil(countdownMs / 1000));

    if (intervalId.current) {
      clearInterval(intervalId.current);
    }

    intervalId.current = setInterval(() => {
      setSecondsLeft((prev) => {
        if (prev <= 1) {
          setCanRequestResend(true);
          if (intervalId.current) {
            clearInterval(intervalId.current);
            intervalId.current = null;
          }
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
        intervalId.current = null;
      }
    };
  }, [countdownMs]);

  return (
    <button
      title={resendText}
      disabled={isLoading || !canRequestResend}
      className={cn(
        "btn",
        canRequestResend ? "btn-primary" : "btn-outline",
        className,
      )}
      onClick={() => {
        setIsLoading(true);
        handleMagicLinkSignUp({ email, name });

        setSecondsLeft(Math.ceil(countdownMs / 1000));
        setCanRequestResend(false);

        if (intervalId.current) {
          clearInterval(intervalId.current);
        }

        intervalId.current = setInterval(() => {
          setSecondsLeft((prev) => {
            if (prev <= 1) {
              setCanRequestResend(true);
              if (intervalId.current) {
                clearInterval(intervalId.current);
                intervalId.current = null;
              }
              return 0;
            }
            return prev - 1;
          });
        }, 1000);

        setIsLoading(false);
      }}
    >
      {resendText}
    </button>
  );
};

export const EmailNameTerms: React.FC<{
  message: MessageProcessedChoices;
  sendMessage: OnMessageSendFunction;
}> = ({ message, sendMessage }) => {
  const [data, setData] = useState<{ name: string; email: string } | null>(
    null,
  );

  return data === null ? (
    <Form
      message={message}
      onAccountCreated={(accountData) => {
        sendMessage({
          message: accountData.name,
          selection: [accountData.email, accountData.name],
        });
        setData(accountData);
      }}
    />
  ) : (
    <ThankYou data={data} />
  );
};
