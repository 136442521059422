import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { tx } from "shared/types/i18n";

import { SecondaryButton } from "../../components/form/button";
import { Form } from "../../components/form/form";
import { FormTextInput } from "../../components/form/form-text-input";
import { LoadingButton } from "../../components/form/loading-button";
import { Link } from "../../components/link";
import { Text } from "../../components/text";
import { useMamaNavigateState } from "../../navigation/use-mama-navigate-state";
import { useUserHasInteractedWithSignupForm } from "../../util/data-layer-actions";
import { isEmailRegex } from "../../util/regex";
import { useTenantId } from "../../util/use-active-tenant-id";
import { AuthPagesEnum } from "../constants";
import { useHandleMagicLinkSignIn } from "../hooks/use-handle-magic-link-sign-in";
import { useAppLanguage } from "~/components/form/language/use-app-language";
import { LoadingIndicator } from "~/components/loading-spinner";
import { useMamaNavigate } from "~/navigation/mama-navigate";
import { useTenantConfig } from "~/tenant-settings";

export const CognitoMagicLinkSignIn: React.FC = () => {
  const handleMagicLinkSignIn = useHandleMagicLinkSignIn();
  const { organisation } = useTenantId();
  const { prefillValues } = useMamaNavigateState();
  const [params] = useSearchParams();
  const [isDynamicLogin, setIsDynamicLogin] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<{
    name: string;
    email: string;
  }>({ defaultValues: prefillValues });

  useUserHasInteractedWithSignupForm(isDirty);
  const navigate = useMamaNavigate(
    () => ({
      email: getValues("email"),
      name: getValues("name"),
    }),
    [getValues],
  );

  useEffect(() => {
    const email = params.get("email");
    if (email) {
      setIsDynamicLogin(() => true);
      handleMagicLinkSignIn({ name: "", email });
    }
  }, [handleMagicLinkSignIn, params]);

  useEffect(() => {
    if (organisation) {
      navigate(AuthPagesEnum.SIGN_IN);
    }
  }, [navigate, organisation]);

  return isDynamicLogin ? (
    <LoadingIndicator
      className="self-center justify-self-center"
      as="spinner"
    />
  ) : (
    <>
      <Form onSubmit={handleSubmit(handleMagicLinkSignIn)}>
        <FormTextInput
          title={{ tx: "auth.signUp.inputs.email.title" }}
          placeholder={{ tx: "auth.signUp.inputs.email.placeholder" }}
          autoComplete="username"
          {...register("email", {
            required: tx("auth.signUp.inputs.email.fieldMissingError"),
            pattern: {
              value: isEmailRegex,
              message: tx("auth.signUp.inputs.email.notAnEmailError"),
            },
          })}
          error={{ txUnchecked: errors.email?.message }}
        />
        <LoadingButton
          type="submit"
          loading={isSubmitting}
          Button={SecondaryButton}
          tx="auth.signIn.signInButton"
        />
        <Text
          as="p"
          tx="auth.navigation.signUpInstead"
          txComponents={{
            SignUpLink: <Link href={AuthPagesEnum.ONBOARDING} />,
          }}
        />
      </Form>
      <PartenrLogo />
    </>
  );
};

const PartenrLogo: React.FC = () => {
  const config = useTenantConfig();
  const { appLanguage } = useAppLanguage();

  const partner = config.partners?.find(
    (value) => value.language === appLanguage,
  );

  return partner ? (
    <div className="mt-12 flex flex-row items-center justify-center gap-2">
      <img className="w-40" src={partner.image} />
    </div>
  ) : null;
};
