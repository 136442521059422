import { useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { userTracking } from "shared/types/auth";
import { twMerge } from "tailwind-merge";

import { UpdateUserAttributesDto, User } from "../api/generated/backend";
import { toTenantIdHeader, useApiMutation } from "../api/use-api";
import { Form } from "../components/form/form";
import { LoadingButton } from "../components/form/loading-button";
import { LoadingIndicator } from "../components/loading-spinner";
import { useModal } from "../models/modal-provider";
import { useTenantConfig } from "../tenant-settings";
import { ME_KEY } from "../types/query-keys";
import { useTenantId } from "../util/use-active-tenant-id";
import { useUser } from "../util/use-user";
import {
  dirtyGetMaybeCurrentUserData,
  useAuthContext,
} from "~/auth/auth-store";
import { SuccessEvent } from "~/components/events/success-event";
import { Button } from "~/components/form/button";
import { Link } from "~/components/link";
import { Text } from "~/components/text";
import { DataCollectionImplementationsRecord } from "~/data-collection/constants";
import { useStore } from "~/models/helpers";
import { isLocalhost } from "~/util/env-utils";
import { logActivity } from "~/util/user-log";

export interface Profile {
  hideProfile: () => void;
  showProfile: () => void;
}

export const useProfile: () => Profile = () => {
  const { showModal: showProfileModal, hideModal: hideProfileModal } =
    useModal();
  const { showModal: showWarningModal, hideModal: hideWarningModal } =
    useModal();
  const [hasChanges, setHasChanges] = useState(false);
  const hasChangesRef = useRef(hasChanges);

  const [ignoreChanges, setIgnoreChanges] = useState(false);
  const ignoreChangesRef = useRef(ignoreChanges);

  useEffect(() => {
    hasChangesRef.current = hasChanges;
  }, [hasChanges]);

  useEffect(() => {
    ignoreChangesRef.current = ignoreChanges;
    if (ignoreChanges) {
      hideProfileModal();
      hideWarningModal();
      setIgnoreChanges(false);
    }
  }, [ignoreChanges, hideProfileModal, hideWarningModal]);

  const showWarning = useCallback(() => {
    showWarningModal({
      title: { tx: "profile.unsavedChangesTitle" },
      description: { tx: "profile.unsavedChanges" },
      onConfirm: () => {
        setIgnoreChanges(true);
      },
      confirmButtonTx: "general.yes",
      closeButtonTx: "general.no",
    });
  }, [showWarningModal, setIgnoreChanges]);

  const ensureCanClose = useCallback(() => {
    if (hasChangesRef.current) {
      showWarning();
    }
    return !hasChangesRef.current || ignoreChangesRef.current;
  }, [showWarning]);

  const showProfile = useCallback(() => {
    showProfileModal({
      showsWindowCloseButton: true,
      closeOnClickOutside: true,
      showCloseButton: false,
      title: { tx: "profile.headerTitle" },
      ensureCanClose,
      children: (
        <div>
          <Profile
            onSavedProfile={hideProfileModal}
            setHasChanges={setHasChanges}
          />
        </div>
      ),
    });
  }, [hideProfileModal, showProfileModal, ensureCanClose]);

  return { showProfile, hideProfile: hideProfileModal };
};

export const Profile: React.FC<{
  onSavedProfile: () => void;
  setHasChanges: Dispatch<SetStateAction<boolean>>;
}> = ({ onSavedProfile, setHasChanges }) => {
  const queryClient = useQueryClient();
  const tenantId = useTenantId();
  const tenantConfig = useTenantConfig();
  const user = useUser();

  const {
    control,
    register,
    formState: { errors, isSubmitting, isDirty: hasChanges },
    handleSubmit,
  } = useForm<UpdateUserAttributesDto>({
    defaultValues: user?.userAttributes,
  });

  useEffect(() => {
    setHasChanges(hasChanges);
  }, [setHasChanges, hasChanges]);

  const inputs = useMemo(
    () =>
      tenantConfig.profileInputs.map((input) => {
        return DataCollectionImplementationsRecord[input];
      }),
    [tenantConfig.profileInputs],
  );

  const { mutate: updateUserAttributes } = useApiMutation(
    "backend",
    (api) => (updateUserAttributesDto: UpdateUserAttributesDto) =>
      api.updateUserAttributes({
        updateUserAttributesDto,
        ...toTenantIdHeader(tenantId),
      }),
    undefined,
    { successMessage: { tx: "profile.updateSuccessful" }, trackErrors: true },
    {
      onSuccess() {
        queryClient.invalidateQueries(ME_KEY(tenantId));
        onSavedProfile();
      },
    },
  );

  const updateProfileHandler = handleSubmit(
    async (data: UpdateUserAttributesDto) => {
      setHasChanges(false);
      i18next.changeLanguage(data.language ?? "en");
      updateUserAttributes(data);
    },
  );

  return user ? (
    <div className="flex flex-col gap-4">
      <Form
        onSubmit={updateProfileHandler}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            event.stopPropagation();
            updateProfileHandler();
          }
        }}
      >
        <div className="flex w-full shrink-0 flex-col gap-2 xl:max-w-[600px] 2xl:max-w-[700px]">
          {inputs.map((input, index) => (
            <input.Component
              key={index}
              user={user}
              register={register}
              control={control}
              errors={errors}
            />
          ))}
          <LoadingButton
            isDisabled={!hasChanges}
            loading={isSubmitting}
            tx="general.confirm"
            className="mt-4"
          />
        </div>
      </Form>
      <ManageAccountModal user={user} />
    </div>
  ) : (
    <LoadingIndicator as="spinner" className="justify-center" />
  );
};

const ManageAccountModal: React.FC<
  JSX.IntrinsicElements["div"] & { user: User }
> = ({ className, user }) => {
  const { showModal } = useModal();

  return (
    <Button
      className={className}
      tx="profile.manageAccount"
      onClick={() => {
        showModal({
          title: { tx: "profile.manageAccount" },
          children: (
            <div className="flex w-full flex-col gap-4">
              <DeleteUserButton user={user} />
            </div>
          ),
        });
      }}
    />
  );
};

const DeleteUserButton: FC<JSX.IntrinsicElements["div"] & { user: User }> = ({
  className,
  user,
}) => {
  const queryClient = useQueryClient();
  const tenantId = useTenantId();
  const store = useStore();
  const { signOut } = useAuthContext();
  const { showModal, hideModal, updateModal } = useModal();
  const { mutate: deleteUser } = useApiMutation(
    "backend",
    (api) => () => api.deletUserFromCognito(),
    undefined,
    undefined,
    {
      onSuccess: () => {
        queryClient.removeQueries(ME_KEY(tenantId));
        hideModal();
        signOut?.();
        store.addToastEvent(new SuccessEvent({ tx: "profile.accountDeleted" }));
      },
    },
  );
  return (
    <Text
      className={twMerge("cursor-pointer text-error", className)}
      tx="profile.deleteAccount"
      onClick={() => {
        if (!signOut) {
          alert("Error: no auth implementation.");
          return;
        }

        showModal({
          title: { tx: "profile.deleteAccount" },
          description: {
            tx: "profile.deleteAccountDescription",
            txComponents: {
              SupportEmailLink: <Link />,
            },
          },
          onConfirm: async () => {
            updateModal({
              loading: { isLoading: true },
              description: { text: "" },
            });

            const maybeUserData = await dirtyGetMaybeCurrentUserData();
            if (!maybeUserData) return;
            const { accessToken, email } = maybeUserData;
            logActivity(accessToken, true, {
              triggerSource: userTracking.userDeleted,
              disease: tenantId.disease,
              userId: user.id,
              email: email,
              isLocalhost: isLocalhost ? "yes" : "no",
              language: i18next.language,
            });

            deleteUser();
          },
        });
      }}
    />
  );
};
