import { ChatOptionComponentProps } from "../lookup";
import { Button } from "~/components/form/button";

export const Media: React.FC<ChatOptionComponentProps> = ({ sendResponse }) => {
  return (
    <div>
      <Button
        icon="paperPlane"
        onClick={() => {
          sendResponse({
            message: "acknowledge",
            selection: [],
          });
        }}
      />
    </div>
  );
};
