import { useCallback, useMemo } from "react";
import {
  Navigate,
  NavigateOptions,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { basePathOfTenant } from "shared/config/base-urls";
import { FrontendPageEnum } from "shared/model/pages";
import { PlainI18nProps } from "shared/types/i18n";

import { AuthPagesEnum } from "../auth/constants";
import { useMaybeAuthContext } from "~/auth/auth-store";
import { useTenantConfig } from "~/tenant-settings";
import { useTenantId } from "~/util/use-active-tenant-id";

export enum SpecialNavigationRoutes {
  HOME = "home",
  AUTH_FALLBACK = "auth-fallback",
  RELOAD = "reload",
  BACK = -1,
}

export type NavigationRoute =
  | AuthPagesEnum
  | FrontendPageEnum
  | SpecialNavigationRoutes;

export type MamaNavigateOptions = {
  prefillValues?: Record<string, string>;
  replace?: boolean;
} & (
  | {
      info?: PlainI18nProps;
      error?: never;
    }
  | { info?: never; error?: PlainI18nProps }
);

const AUTH_FALLBACK = AuthPagesEnum.MAGIC_SIGN_IN;

export const useMamaNavigate = (
  autoOverwritePrefillValues?: () => Record<string, string>,
  dependencies?: unknown[],
): ((page: NavigationRoute, options?: MamaNavigateOptions) => void) => {
  const navigate = useNavigate();
  const tenantId = useTenantId();
  const authContext = useMaybeAuthContext();
  const { homePage } = useTenantConfig();
  const { pathname } = useLocation();

  return useCallback(
    (page: NavigationRoute, options?: MamaNavigateOptions) => {
      const navigateOptions: NavigateOptions = {
        ...options,
        state: {
          info: options?.info,
          error: options?.error,
          prefillValues: {
            ...(autoOverwritePrefillValues && autoOverwritePrefillValues()),
            ...options?.prefillValues,
          },
        },
      };

      page === SpecialNavigationRoutes.BACK
        ? navigate(-1)
        : page === SpecialNavigationRoutes.AUTH_FALLBACK
        ? navigate(
            `${basePathOfTenant(tenantId)}${AUTH_FALLBACK}`,
            navigateOptions,
          )
        : page === SpecialNavigationRoutes.HOME ||
          (isAuthRoute(page) && authContext?.jwt)
        ? navigate(`${basePathOfTenant(tenantId)}${homePage}`, navigateOptions)
        : page === SpecialNavigationRoutes.RELOAD
        ? navigate(pathname, navigateOptions)
        : navigate(`${basePathOfTenant(tenantId)}${page}`, navigateOptions);
    },

    [
      autoOverwritePrefillValues,
      homePage,
      authContext?.jwt,
      navigate,
      pathname,
      tenantId,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ...(dependencies ?? []),
    ],
  );
};

export const MamaNavigate: React.FC<{
  to: NavigationRoute;
}> = ({ to }) => {
  const tenantId = useTenantId();
  const { homePage } = useTenantConfig();
  const authContext = useMaybeAuthContext();

  const route = useMemo(
    () =>
      to === SpecialNavigationRoutes.HOME ||
      (isAuthRoute(to) && authContext?.jwt)
        ? `${basePathOfTenant(tenantId)}${homePage}`
        : to === SpecialNavigationRoutes.AUTH_FALLBACK
        ? `${basePathOfTenant(tenantId)}${AUTH_FALLBACK}`
        : `${basePathOfTenant(tenantId)}${to}`,
    [homePage, authContext?.jwt, tenantId, to],
  );

  return <Navigate to={route} replace={true} />;
};

const isAuthRoute = (
  maybeAuthRoute: unknown,
): maybeAuthRoute is AuthPagesEnum =>
  !!maybeAuthRoute &&
  typeof maybeAuthRoute === "string" &&
  (Object.values(AuthPagesEnum) as string[]).includes(maybeAuthRoute);
