/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IeNodeType } from './IeNodeType';
import {
    IeNodeTypeFromJSON,
    IeNodeTypeFromJSONTyped,
    IeNodeTypeToJSON,
} from './IeNodeType';
import type { IeSubtype } from './IeSubtype';
import {
    IeSubtypeFromJSON,
    IeSubtypeFromJSONTyped,
    IeSubtypeToJSON,
} from './IeSubtype';

/**
 * 
 * @export
 * @interface QuestionNodeInfo
 */
export interface QuestionNodeInfo {
    /**
     * 
     * @type {IeNodeType}
     * @memberof QuestionNodeInfo
     */
    type: IeNodeType;
    /**
     * 
     * @type {IeSubtype}
     * @memberof QuestionNodeInfo
     */
    subtype?: IeSubtype;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionNodeInfo
     */
    choices?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof QuestionNodeInfo
     */
    mediaSrc?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionNodeInfo
     */
    mediaAlt?: string;
}

/**
 * Check if a given object implements the QuestionNodeInfo interface.
 */
export function instanceOfQuestionNodeInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function QuestionNodeInfoFromJSON(json: any): QuestionNodeInfo {
    return QuestionNodeInfoFromJSONTyped(json, false);
}

export function QuestionNodeInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionNodeInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': IeNodeTypeFromJSON(json['type']),
        'subtype': !exists(json, 'subtype') ? undefined : IeSubtypeFromJSON(json['subtype']),
        'choices': !exists(json, 'choices') ? undefined : json['choices'],
        'mediaSrc': !exists(json, 'mediaSrc') ? undefined : json['mediaSrc'],
        'mediaAlt': !exists(json, 'mediaAlt') ? undefined : json['mediaAlt'],
    };
}

export function QuestionNodeInfoToJSON(value?: QuestionNodeInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': IeNodeTypeToJSON(value.type),
        'subtype': IeSubtypeToJSON(value.subtype),
        'choices': value.choices,
        'mediaSrc': value.mediaSrc,
        'mediaAlt': value.mediaAlt,
    };
}

