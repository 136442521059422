import { useCallback } from "react";
import { FrontendPageEnum } from "shared/model/pages";

import { useActiveLandbotUrlTx } from "./landbot/active-landbot-url-tx";
import { resetLocalLandbotState } from "./landbot/session-storage";
import { useNavigateInsideTenant } from "../../util/navigation-hooks";
import { useUser } from "~/util/use-user";

export const useEditLandbotTimeline = (): (() => void) | null => {
  const landbotUrlTx = useActiveLandbotUrlTx();
  const navigate = useNavigateInsideTenant();
  const user = useUser();
  const editLandbotTimeline = useCallback(() => {
    if (landbotUrlTx && user?.cognitoSub) {
      resetLocalLandbotState({ landbotUrlTx, sub: user.cognitoSub });
    }
    navigate(FrontendPageEnum.CHAT);
  }, [landbotUrlTx, user?.cognitoSub, navigate]);
  return landbotUrlTx ? editLandbotTimeline : null;
};
