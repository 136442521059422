"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringIdOfTenant = exports.pathSegmentOfEnum = exports.basePathOfTenant = exports.baseUrlOfTenant = void 0;
const baseUrlOfTenant = ({ subdomainElement, tenantId, isLocalhost, }) => `${isLocalhost
    ? "http://localhost:3000"
    : `https://${subdomainElement}.mamahealth.com`}${(0, exports.basePathOfTenant)(tenantId)}`;
exports.baseUrlOfTenant = baseUrlOfTenant;
const basePathOfTenant = ({ disease, organisation, }) => `/${(0, exports.pathSegmentOfEnum)(disease)}${organisation ? `/${organisation}` : "/_"}`;
exports.basePathOfTenant = basePathOfTenant;
const pathSegmentOfEnum = (disease) => {
    return disease.toLowerCase().replaceAll("_", "-");
};
exports.pathSegmentOfEnum = pathSegmentOfEnum;
const stringIdOfTenant = ({ disease, organisation, }) => `${disease}${organisation ? `_${organisation.toUpperCase()}` : ""}`.replace(/-/g, "_");
exports.stringIdOfTenant = stringIdOfTenant;
