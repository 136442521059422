import { RefObject, useCallback, useEffect, useRef } from "react";

export const useFocusFirstElement = <E extends HTMLElement>(
  selector: string,
): {
  parentRef: RefObject<E>;
  focus: () => void;
} => {
  const parentRef = useRef<E>(null);

  const focus = useCallback(() => {
    const firstButton: HTMLElement | null | undefined =
      parentRef.current?.querySelector(selector);
    if (!firstButton) return;
    firstButton.focus();
  }, [selector]);

  useEffect(focus, [focus]);

  return { parentRef, focus };
};
