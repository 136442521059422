import assert from "assert";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";
import {
  credentialsHeader,
  frontendPathHeader,
  traceIdHeader,
} from "shared/types/cors";
import { v4 as uuidv4 } from "uuid";

import * as BackendClient from "./generated/backend";
import * as LandbotClient from "./generated/landbot";
import * as MultiagentClient from "./generated/multiagent";
import {
  ApiClientService,
  apiUrl,
  ConfigurationParameters,
  PrivateApiContextType,
  RequestContext,
} from "./types/api";
import { useAuthContext } from "~/auth/auth-store";
import { MamaCognitoError } from "~/auth/utils/cognito-errors";

export const PrivateApiProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { ensureValidJwt } = useAuthContext();

  const pre = useCallback(
    async (ctx: RequestContext): Promise<void> => {
      const path = window.location.pathname;
      const jwt = await ensureValidJwt();

      if (!jwt)
        throw new MamaCognitoError({ name: "auth.signIn.errors.missingJwt" });

      const headers = new Headers({
        Authorization: `Bearer ${jwt}`,
        ...ctx.init.headers,
        [traceIdHeader]: uuidv4(),
        [frontendPathHeader]: path,
      });

      ctx.init.headers = headers;
    },
    [ensureValidJwt],
  );

  const getClientConfig = useCallback(
    <TClient extends ApiClientService>(
      client: TClient,
    ): ConfigurationParameters => ({
      credentials: credentialsHeader,
      basePath: getClientApiUrl(client),
      middleware: [{ pre }],
    }),
    [pre],
  );

  const context = useMemo(() => {
    const backend = new BackendClient.DefaultApi(
      new BackendClient.Configuration(getClientConfig("backend")),
    );
    const multiagent = new MultiagentClient.DefaultApi(
      new MultiagentClient.Configuration(getClientConfig("multiagent")),
    );
    const landbot = new LandbotClient.DefaultApi(
      new LandbotClient.Configuration(getClientConfig("landbot")),
    );
    return { backend, multiagent, landbot };
  }, [getClientConfig]);

  return (
    <privateApiContext.Provider value={context}>
      {children}
    </privateApiContext.Provider>
  );
};

export const usePrivateApiClients = (): PrivateApiContextType => {
  const maybePrivateApiContext = useContext(privateApiContext);
  assert(maybePrivateApiContext, "expected private api context to be defined");

  return maybePrivateApiContext;
};

const privateApiContext = createContext<PrivateApiContextType | undefined>(
  undefined,
);

const getClientApiUrl = <TClient extends ApiClientService>(client: TClient) =>
  apiUrl[client];
