/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Conversation } from './Conversation';
import {
    ConversationFromJSON,
    ConversationFromJSONTyped,
    ConversationToJSON,
} from './Conversation';
import type { Event } from './Event';
import {
    EventFromJSON,
    EventFromJSONTyped,
    EventToJSON,
} from './Event';
import type { GroupsOfUser } from './GroupsOfUser';
import {
    GroupsOfUserFromJSON,
    GroupsOfUserFromJSONTyped,
    GroupsOfUserToJSON,
} from './GroupsOfUser';
import type { LandbotCaseData } from './LandbotCaseData';
import {
    LandbotCaseDataFromJSON,
    LandbotCaseDataFromJSONTyped,
    LandbotCaseDataToJSON,
} from './LandbotCaseData';
import type { LandbotEventData } from './LandbotEventData';
import {
    LandbotEventDataFromJSON,
    LandbotEventDataFromJSONTyped,
    LandbotEventDataToJSON,
} from './LandbotEventData';
import type { OrganisationSettings } from './OrganisationSettings';
import {
    OrganisationSettingsFromJSON,
    OrganisationSettingsFromJSONTyped,
    OrganisationSettingsToJSON,
} from './OrganisationSettings';
import type { QuestionnaireGraph } from './QuestionnaireGraph';
import {
    QuestionnaireGraphFromJSON,
    QuestionnaireGraphFromJSONTyped,
    QuestionnaireGraphToJSON,
} from './QuestionnaireGraph';
import type { StripeInfo } from './StripeInfo';
import {
    StripeInfoFromJSON,
    StripeInfoFromJSONTyped,
    StripeInfoToJSON,
} from './StripeInfo';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { UserDetail } from './UserDetail';
import {
    UserDetailFromJSON,
    UserDetailFromJSONTyped,
    UserDetailToJSON,
} from './UserDetail';
import type { UserSettings } from './UserSettings';
import {
    UserSettingsFromJSON,
    UserSettingsFromJSONTyped,
    UserSettingsToJSON,
} from './UserSettings';

/**
 * Represents a DiseaseSettings record
 * @export
 * @interface DiseaseSettings
 */
export interface DiseaseSettings {
    /**
     * 
     * @type {string}
     * @memberof DiseaseSettings
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DiseaseSettings
     */
    name: string;
    /**
     * 
     * @type {Array<StripeInfo>}
     * @memberof DiseaseSettings
     */
    stripeInfo?: Array<StripeInfo>;
    /**
     * 
     * @type {string}
     * @memberof DiseaseSettings
     */
    stripeId?: string;
    /**
     * 
     * @type {Array<OrganisationSettings>}
     * @memberof DiseaseSettings
     */
    organisationSettings?: Array<OrganisationSettings>;
    /**
     * 
     * @type {Array<LandbotCaseData>}
     * @memberof DiseaseSettings
     */
    landbotCaseData?: Array<LandbotCaseData>;
    /**
     * 
     * @type {Array<LandbotEventData>}
     * @memberof DiseaseSettings
     */
    landbotEventData?: Array<LandbotEventData>;
    /**
     * 
     * @type {Array<UserSettings>}
     * @memberof DiseaseSettings
     */
    userSettings?: Array<UserSettings>;
    /**
     * 
     * @type {Array<Event>}
     * @memberof DiseaseSettings
     */
    event?: Array<Event>;
    /**
     * 
     * @type {Array<UserDetail>}
     * @memberof DiseaseSettings
     */
    userDetail?: Array<UserDetail>;
    /**
     * 
     * @type {Array<Conversation>}
     * @memberof DiseaseSettings
     */
    conversation?: Array<Conversation>;
    /**
     * 
     * @type {Array<GroupsOfUser>}
     * @memberof DiseaseSettings
     */
    groupsOfUser?: Array<GroupsOfUser>;
    /**
     * 
     * @type {Array<User>}
     * @memberof DiseaseSettings
     */
    usersLastActiveOnDisease?: Array<User>;
    /**
     * 
     * @type {Array<QuestionnaireGraph>}
     * @memberof DiseaseSettings
     */
    questionnaireGraph?: Array<QuestionnaireGraph>;
}

/**
 * Check if a given object implements the DiseaseSettings interface.
 */
export function instanceOfDiseaseSettings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function DiseaseSettingsFromJSON(json: any): DiseaseSettings {
    return DiseaseSettingsFromJSONTyped(json, false);
}

export function DiseaseSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiseaseSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'stripeInfo': !exists(json, 'stripeInfo') ? undefined : ((json['stripeInfo'] as Array<any>).map(StripeInfoFromJSON)),
        'stripeId': !exists(json, 'stripeId') ? undefined : json['stripeId'],
        'organisationSettings': !exists(json, 'organisationSettings') ? undefined : ((json['organisationSettings'] as Array<any>).map(OrganisationSettingsFromJSON)),
        'landbotCaseData': !exists(json, 'landbotCaseData') ? undefined : ((json['landbotCaseData'] as Array<any>).map(LandbotCaseDataFromJSON)),
        'landbotEventData': !exists(json, 'landbotEventData') ? undefined : ((json['landbotEventData'] as Array<any>).map(LandbotEventDataFromJSON)),
        'userSettings': !exists(json, 'userSettings') ? undefined : ((json['userSettings'] as Array<any>).map(UserSettingsFromJSON)),
        'event': !exists(json, 'event') ? undefined : ((json['event'] as Array<any>).map(EventFromJSON)),
        'userDetail': !exists(json, 'userDetail') ? undefined : ((json['userDetail'] as Array<any>).map(UserDetailFromJSON)),
        'conversation': !exists(json, 'conversation') ? undefined : ((json['conversation'] as Array<any>).map(ConversationFromJSON)),
        'groupsOfUser': !exists(json, 'groupsOfUser') ? undefined : ((json['groupsOfUser'] as Array<any>).map(GroupsOfUserFromJSON)),
        'usersLastActiveOnDisease': !exists(json, 'usersLastActiveOnDisease') ? undefined : ((json['usersLastActiveOnDisease'] as Array<any>).map(UserFromJSON)),
        'questionnaireGraph': !exists(json, 'questionnaireGraph') ? undefined : ((json['questionnaireGraph'] as Array<any>).map(QuestionnaireGraphFromJSON)),
    };
}

export function DiseaseSettingsToJSON(value?: DiseaseSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'stripeInfo': value.stripeInfo === undefined ? undefined : ((value.stripeInfo as Array<any>).map(StripeInfoToJSON)),
        'stripeId': value.stripeId,
        'organisationSettings': value.organisationSettings === undefined ? undefined : ((value.organisationSettings as Array<any>).map(OrganisationSettingsToJSON)),
        'landbotCaseData': value.landbotCaseData === undefined ? undefined : ((value.landbotCaseData as Array<any>).map(LandbotCaseDataToJSON)),
        'landbotEventData': value.landbotEventData === undefined ? undefined : ((value.landbotEventData as Array<any>).map(LandbotEventDataToJSON)),
        'userSettings': value.userSettings === undefined ? undefined : ((value.userSettings as Array<any>).map(UserSettingsToJSON)),
        'event': value.event === undefined ? undefined : ((value.event as Array<any>).map(EventToJSON)),
        'userDetail': value.userDetail === undefined ? undefined : ((value.userDetail as Array<any>).map(UserDetailToJSON)),
        'conversation': value.conversation === undefined ? undefined : ((value.conversation as Array<any>).map(ConversationToJSON)),
        'groupsOfUser': value.groupsOfUser === undefined ? undefined : ((value.groupsOfUser as Array<any>).map(GroupsOfUserToJSON)),
        'usersLastActiveOnDisease': value.usersLastActiveOnDisease === undefined ? undefined : ((value.usersLastActiveOnDisease as Array<any>).map(UserToJSON)),
        'questionnaireGraph': value.questionnaireGraph === undefined ? undefined : ((value.questionnaireGraph as Array<any>).map(QuestionnaireGraphToJSON)),
    };
}

