/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Message } from './Message';
import {
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
} from './Message';

/**
 * Represents a MessageMetadata record
 * @export
 * @interface MessageMetadata
 */
export interface MessageMetadata {
    /**
     * 
     * @type {string}
     * @memberof MessageMetadata
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MessageMetadata
     */
    messageId: string;
    /**
     * 
     * @type {string}
     * @memberof MessageMetadata
     */
    sources?: string;
    /**
     * 
     * @type {Date}
     * @memberof MessageMetadata
     */
    createdAt: Date;
    /**
     * 
     * @type {Message}
     * @memberof MessageMetadata
     */
    message?: Message;
}

/**
 * Check if a given object implements the MessageMetadata interface.
 */
export function instanceOfMessageMetadata(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "messageId" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function MessageMetadataFromJSON(json: any): MessageMetadata {
    return MessageMetadataFromJSONTyped(json, false);
}

export function MessageMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'messageId': json['messageId'],
        'sources': !exists(json, 'sources') ? undefined : json['sources'],
        'createdAt': (new Date(json['createdAt'])),
        'message': !exists(json, 'message') ? undefined : MessageFromJSON(json['message']),
    };
}

export function MessageMetadataToJSON(value?: MessageMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'messageId': value.messageId,
        'sources': value.sources,
        'createdAt': (value.createdAt.toISOString()),
        'message': MessageToJSON(value.message),
    };
}

