import { useEffect, useMemo } from "react";

import {
  SpecialNavigationRoutes,
  useMamaNavigate,
} from "~/navigation/mama-navigate";
import { useMamaNavigateState } from "~/navigation/use-mama-navigate-state";

export const AuthRoot: React.FC = () => {
  const navigate = useMamaNavigate();
  const state = useMamaNavigateState();
  const messageToShow = useMemo(
    () => (state.error || state.info) ?? undefined,
    [state.error, state.info],
  );

  useEffect(() => {
    if (!messageToShow) navigate(SpecialNavigationRoutes.AUTH_FALLBACK);
  }, [messageToShow, navigate]);

  return null;
};
