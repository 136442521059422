import { Text } from "~/components/text";

export const StepsIndication: React.FC<{
  current: number;
  last: number;
}> = ({ current, last }) => {
  return (
    <div className="flex flex-col items-center gap-1">
      <span className="text-sm text-mama-default-primary">
        <Text tx="graph.text.stepsIndicator" txData={{ current, last }} />
      </span>
      <progress
        className="progress-primary progress w-full max-w-xs sm:w-56"
        value={current}
        max={last}
      />
    </div>
  );
};
