import { useEffect, useRef } from "react";

import { PatientsAvatars } from "./patients-avatars";
import { TrustedBy } from "./trusted-by";
import { useEmbellishmentVisibility } from "../../layout/providers/embellishment-visibility-provider";
import { useLanguageSelectorVisibility } from "../../layout/providers/language-selector-visibility-provider";
import { SvgIcon } from "~/components/icons/svg-icon";
import { Text } from "~/components/text";
import { useT } from "~/i18n/use-t";

const SquigglyLines: React.FC = () => (
  <div className="pointer-events-none fixed inset-0">
    <div className="relative h-full opacity-0 transition-opacity duration-700 ease-in-out md:opacity-40 lg:opacity-100">
      <SvgIcon
        color="fill-transparent"
        icon="squigglyLineTopRight"
        width="356"
        height="209"
        className="absolute right-0 top-0"
      />
      <SvgIcon
        color="fill-transparent"
        icon="squigglyLineLeftBottom"
        width="356"
        height="209"
        className="absolute bottom-0 left-0"
      />
    </div>
  </div>
);

export const Welcome: React.FC<{ onClick: () => void; disabled: boolean }> = ({
  onClick,
  disabled,
}) => {
  const ref = useRef<HTMLButtonElement>(null);
  const t = useT();

  const { setIsVisible } = useLanguageSelectorVisibility();
  const { setEmbellishment, unsetEmbellishment } = useEmbellishmentVisibility();

  useEffect(() => {
    ref.current?.focus();
    setIsVisible(true);
    setEmbellishment(<SquigglyLines />);

    return () => {
      setIsVisible(false);
      unsetEmbellishment();
    };
  }, [setIsVisible, setEmbellishment, unsetEmbellishment]);

  return (
    <section className="flex flex-col items-center gap-y-20">
      <div className="flex max-w-xl flex-col items-center text-center text-primary">
        <PatientsAvatars randomOrder />
        <Text
          as="strong"
          tx="graph.text.patiensHaveAlreadyJoined"
          className="mb-4 mt-6 text-balance"
        />
        <Text
          as="p"
          tx="graph.text.patiensBecomeOneOfThem"
          className="text-balance"
        />
      </div>

      <div className="card w-full border bg-white shadow-lg sm:w-[30rem]">
        <div className="card-body text-center">
          <Text
            as="h2"
            tx="graph.text.welcome"
            className="card-title block text-center text-4xl font-bold text-mama-default-primary text-balance"
          />
          <Text
            as="p"
            tx="graph.text.afterWelcome"
            className="mx-auto mt-5 max-w-[22rem] text-balance"
          />
          <div className="card-actions mt-6">
            <button
              title={t({ tx: "graph.text.letsStart" })}
              type="button"
              ref={ref}
              className="btn-primary btn-block btn"
              onClick={onClick}
              disabled={disabled}
            >
              <Text tx="graph.text.letsStart" className="text-inherit" />
            </button>
          </div>
        </div>
      </div>

      <div className="z-10">
        <TrustedBy />
      </div>
    </section>
  );
};
