import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { tx } from "shared/types/i18n";

import { SecondaryButton } from "../../components/form/button";
import { Form } from "../../components/form/form";
import { FormTextInput } from "../../components/form/form-text-input";
import { LoadingButton } from "../../components/form/loading-button";
import { useMamaNavigateState } from "../../navigation/use-mama-navigate-state";
import { useUserHasInteractedWithSignupForm } from "../../util/data-layer-actions";
import { isEmailRegex } from "../../util/regex";
import { AuthPagesEnum } from "../constants";
import { useHandleMagicLinkSignUp } from "../hooks/use-handle-magic-link-sign-up";
import { useMamaNavigate } from "~/navigation/mama-navigate";

export const CognitoMagicLinkSignUp: React.FC = () => {
  const { hash } = useLocation();
  const handleMagicLinkSignUp = useHandleMagicLinkSignUp({
    legacy: true,
  });

  const { prefillValues } = useMamaNavigateState();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<{
    name: string;
    email: string;
  }>({ defaultValues: prefillValues });

  useUserHasInteractedWithSignupForm(isDirty);

  const navigate = useMamaNavigate(
    () => ({
      email: getValues("email"),
      name: getValues("name"),
    }),
    [getValues],
  );

  useEffect(() => {
    if (hash !== "#override") {
      navigate(AuthPagesEnum.ONBOARDING, {
        replace: true,
      });
    }
  }, [hash, navigate]);

  return (
    <Form onSubmit={handleSubmit(handleMagicLinkSignUp)}>
      <FormTextInput
        title={{ tx: "auth.signUp.inputs.name.title" }}
        placeholder={{ tx: "auth.signUp.inputs.name.placeholder" }}
        {...register("name", {
          required: tx("auth.signUp.inputs.name.fieldMissingError"),
          validate: (value) => {
            if (value.trim() === "") {
              return tx("auth.signUp.inputs.name.fieldMissingError");
            }
          },
        })}
        error={{ txUnchecked: errors.name?.message }}
      />
      <FormTextInput
        title={{ tx: "auth.signUp.inputs.email.title" }}
        placeholder={{ tx: "auth.signUp.inputs.email.placeholder" }}
        autoComplete="username"
        {...register("email", {
          required: tx("auth.signUp.inputs.email.fieldMissingError"),
          pattern: {
            value: isEmailRegex,
            message: tx("auth.signUp.inputs.email.notAnEmailError"),
          },
        })}
        error={{ txUnchecked: errors.email?.message }}
      />
      <LoadingButton
        type="submit"
        loading={isSubmitting}
        Button={SecondaryButton}
        tx="auth.signUp.signUpButton"
      />
    </Form>
  );
};
