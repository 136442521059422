import { PrimaryButton } from "../../components/form/button";
import { AuthPagesEnum } from "../constants";
import { useMamaNavigate } from "~/navigation/mama-navigate";

export const CognitoUpdateEmailInfo: React.FC = () => {
  const navigate = useMamaNavigate();
  return (
    <div className="min-h-16 flex flex-row items-center justify-center self-stretch">
      <PrimaryButton
        tx="profile.emailConfirmedContinueToAppButton"
        className="w-full"
        onClick={() => navigate(AuthPagesEnum.MAGIC_SIGN_IN)}
      />
    </div>
  );
};
