import { useCallback } from "react";

import { useClientMetaData } from "./use-client-meta-data";
import {
  SpecialNavigationRoutes,
  useMamaNavigate,
} from "../../navigation/mama-navigate";
import {
  AuthPagesEnum,
  MAGIC_LINK_SIGN_IN_AUTH_FLOW,
  MAGIC_LINK_SIGN_IN_METHOD,
  SignInNextStepEnum,
  SignInStatusEnum,
} from "../constants";
import {
  CognitoAuthInput,
  confirmSignInRetry,
  signInRetry,
} from "../utils/auth-methods";
import {
  handleCognitoError,
  isCognitoError,
  MamaCognitoError,
} from "../utils/cognito-errors";

export const useHandleMagicLinkSignIn = (): (({
  name,
  email,
}: {
  name: string;
  email: string;
}) => Promise<void>) => {
  const navigate = useMamaNavigate();
  const clientMetadata = useClientMetaData({
    signInMethod: MAGIC_LINK_SIGN_IN_METHOD,
    alreadyHaveMagicLink: "no",
  });

  return useCallback(
    async ({ name, email }: { name: string; email: string }) => {
      if (!clientMetadata) {
        navigate(SpecialNavigationRoutes.AUTH_FALLBACK, {
          replace: true,
          error: handleCognitoError(
            new MamaCognitoError({
              name: "auth.signIn.errors.UnknownErorHasOccured",
            }),
            { email },
          ),
        });
        return;
      }

      try {
        const cognitoAuthInput: CognitoAuthInput = {
          name,
          email,
          clientMetadata,
        };

        const signInStatus = await requestMagicLinkForSignIn(cognitoAuthInput);

        switch (signInStatus) {
          case SignInStatusEnum.MAGIC_LINK_SENT:
            navigate(AuthPagesEnum.MAGIC_LINK_SENT, {
              replace: true,
            });
            break;

          case SignInStatusEnum.USER_NOT_FOUND:
            navigate(AuthPagesEnum.ONBOARDING, {
              replace: true,
            });
            break;

          case SignInStatusEnum.CONDITIONAL_CHECK_FAILED_EXCEPTION:
            navigate(AuthPagesEnum.AUTH_ROOT, {
              error: {
                tx: "auth.signIn.errors.ConditionalCheckFailedException",
              },
              replace: true,
            });
            break;

          default:
            throw new MamaCognitoError({
              name: "auth.signIn.errors.UnknownErorHasOccured",
            });
        }
      } catch (error) {
        navigate(SpecialNavigationRoutes.AUTH_FALLBACK, {
          replace: true,
          error: handleCognitoError(error, { email }),
        });
      }
    },
    [clientMetadata, navigate],
  );
};

export const requestMagicLinkForSignIn = async ({
  email,
  name,
  clientMetadata,
}: CognitoAuthInput): Promise<SignInStatusEnum> => {
  try {
    const { isSignedIn, nextStep } = await signInRetry({
      username: email,
      options: {
        authFlowType: MAGIC_LINK_SIGN_IN_AUTH_FLOW,
        userAttributes: {
          name,
          email,
        },
        autoSignIn: false,
        clientMetadata,
      },
    });

    if (isSignedIn) {
      return SignInStatusEnum.USER_ALREADY_SIGNED_IN;
    }

    if (
      nextStep.signInStep !==
      SignInNextStepEnum.CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE
    ) {
      return SignInStatusEnum.USER_CHALLENGE_INVALID;
    }

    const { isSignedIn: confirmSignedIn, nextStep: confirmNextStep } =
      await confirmSignInRetry({
        challengeResponse: "__dummy__",
        options: { clientMetadata: clientMetadata },
      });

    if (confirmSignedIn) {
      return SignInStatusEnum.USER_CONFIRMED_SIGNED_IN;
    }

    if (
      confirmNextStep.signInStep !==
      SignInNextStepEnum.CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE
    ) {
      return SignInStatusEnum.USER_CONFIRMED_CHALLENGE_INVALID;
    }
    return SignInStatusEnum.MAGIC_LINK_SENT;
  } catch (error) {
    if (isCognitoError(error) && error.name === "UserNotFoundException") {
      return SignInStatusEnum.USER_NOT_FOUND;
    }

    if (
      isCognitoError(error) &&
      error.message.includes("ConditionalCheckFailedException")
    ) {
      return SignInStatusEnum.CONDITIONAL_CHECK_FAILED_EXCEPTION;
    }

    return SignInStatusEnum.UNKNOWN_ERROR;
  }
};
