"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserDetailType = exports.Gender = void 0;
var Gender;
(function (Gender) {
    Gender["MALE"] = "MALE";
    Gender["FEMALE"] = "FEMALE";
    Gender["INTERSEX"] = "INTERSEX";
})(Gender || (exports.Gender = Gender = {}));
var UserDetailType;
(function (UserDetailType) {
    UserDetailType["CONDITION_START_DATE"] = "CONDITION_START_DATE";
    UserDetailType["MEDICATIONS"] = "MEDICATIONS";
    UserDetailType["CARE_PROVIDER"] = "CARE_PROVIDER";
    UserDetailType["CONDITION_VARIANT"] = "CONDITION_VARIANT";
})(UserDetailType || (exports.UserDetailType = UserDetailType = {}));
