import * as Sentry from "@sentry/react";
import { useCallback, useState } from "react";
import { DeploymentEnvironment } from "shared/config/deployment-environments";
import { Disease } from "shared/model/diseases";
import { Language } from "shared/model/languages";

import { Welcome } from "./components/components/welcome";
import { MessageComponent } from "./components/message-component";
import { RestartButton } from "./layout/restart-button";
import { StepsIndication } from "./layout/steps-indicator";
import { shemaSocketEvent, SocketAssistantMessage } from "./schema-socket";
import { OnMessageSendFunction } from "../chat/conversation";
import { PublicConversationRecordWithMessages } from "~/api/generated/multiagent";
import { usePublicApiQuery } from "~/api/use-public-api";
import { usePublicWebSocket } from "~/api/use-public-web-socket";
import { ICredentials, useCredentials } from "~/auth/hooks/use-credentials";
import { PlainError } from "~/components/events/plain-error";
import { LoadingIndicator } from "~/components/loading-spinner";
import { useStore } from "~/models/helpers";
import { RootStore } from "~/models/root";
import { queryClient } from "~/query-client";
import {
  ANSWER_COUNT_KEY,
  PUBLIC_CHAT_CONVERSATION_KEY,
} from "~/types/query-keys";
import { appEnv, isLocalhost } from "~/util/env-utils";

const usePublicConversation = ({
  disease,
  credentials,
}: {
  disease: Disease;
  credentials: ICredentials;
}) => {
  const queryKey = [PUBLIC_CHAT_CONVERSATION_KEY(disease)];

  const [conversation, setConversation] =
    useState<PublicConversationRecordWithMessages>();

  const [assistantMessages, setAssistantMessages] = useState<
    SocketAssistantMessage[]
  >([]);

  const { reset: resetCredentials } = useCredentials();

  const { error, refetch } = usePublicApiQuery(
    "multiagent",
    (api) =>
      api.getPublicConversationWithId({
        mamaUser: credentials.userId,
        authorization: credentials.token,
        mamaConversationId: credentials.conversationId,
        mamaDisease: disease,
      }),
    queryKey,
    {
      refetchOnWindowFocus: false,
      onSuccess(receivedConversation) {
        setConversation(receivedConversation);

        const onlyAssistant = receivedConversation.messages.filter(
          (message) => message.role === "ASSISTANT",
        ) as SocketAssistantMessage[];
        setAssistantMessages(onlyAssistant);
      },
    },
  );
  if (error) {
    resetCredentials();
    refetch();
    window.location.reload();
  }

  return {
    conversationId: conversation ? conversation.id : undefined,
    numberOfOnboardingNodes: conversation?.numberOfOnboardingNodes || 0,
    assistantMessages,
    pushAssistantMessage(message: SocketAssistantMessage) {
      setAssistantMessages((messages) => [...messages, message]);
    },
  };
};

export const PublicEcommerceConversation: React.FC<{
  credentials: ICredentials;
  language: Language;
  disease: Disease;
  disabled: boolean;
}> = ({ disease, language, credentials, disabled }) => {
  const {
    conversationId,
    numberOfOnboardingNodes,
    assistantMessages,
    pushAssistantMessage,
  } = usePublicConversation({
    disease,
    credentials,
  });

  return !conversationId ? (
    <LoadingIndicator message={{ tx: "chat.loadingConversation" }} />
  ) : assistantMessages.length == 0 ? (
    <LoadingIndicator message={{ tx: "chat.invalidConfiguration" }} />
  ) : (
    <ConversationReady
      credentials={credentials}
      conversationId={conversationId}
      numberOfOnboardingNodes={numberOfOnboardingNodes}
      assistantMessages={assistantMessages}
      language={language}
      disease={disease}
      onAssistantMessage={pushAssistantMessage}
      disabled={disabled}
    />
  );
};

const url = isLocalhost
  ? "ws://localhost:8000/public/ws"
  : appEnv === DeploymentEnvironment.DEV
  ? "wss://ws.dev.mamahealth.com/public/ws"
  : "wss://ws.app.mamahealth.com/public/ws";

const ConversationReady: React.FC<{
  credentials: ICredentials;
  conversationId: string;
  numberOfOnboardingNodes: number;
  assistantMessages: SocketAssistantMessage[];
  language: Language;
  disease: Disease;
  onAssistantMessage: (message: SocketAssistantMessage) => void;
  disabled: boolean;
}> = ({
  credentials,
  language,
  disease,
  conversationId: conversation_id,
  numberOfOnboardingNodes,
  assistantMessages,
  onAssistantMessage,
  disabled,
}) => {
  const store = useStore();
  const [hasAcknowledgedWelcome, setHasAcknowledgedWelcome] = useState(false);

  const activeMessage = assistantMessages[assistantMessages.length - 1];

  const { sendMessage: sendWebSocketMessage, isReady: isWebSocketReady } =
    usePublicWebSocket({
      url,
      query: {
        language,
        disease,
        conversation_id,
        temp_token: credentials.token,
        external_user_id: credentials.userId,
      },
      async onResponse(event) {
        queryClient.invalidateQueries(ANSWER_COUNT_KEY(disease));

        if (!event) return;

        const result = await shemaSocketEvent.safeParseAsync(event);
        if (!result.success) {
          handleError({
            store,
            errorName: "Invalid websocket event",
            extra: {
              error: result.error,
            },
          });
          return;
        }

        switch (result.data.type) {
          case "error": {
            handleError({
              store,
              errorName: "Invalid websocket input",
              extra: {
                error: result.data.data.error,
              },
            });
            return;
          }
          case "info": {
            if (isLocalhost) {
              console.info(result.data.data);
            }
            return;
          }
          case "message": {
            if (isLocalhost) {
              console.info(result.data.data);
            }
            onAssistantMessage(result.data.data.message);
          }
        }
      },
    });

  const onSendMessage: OnMessageSendFunction = useCallback(
    ({
      message,
      selection,
      base64Images = undefined,
      base64Audio = undefined,
    }) => {
      sendWebSocketMessage({
        type: "message",
        data: {
          questionNodeInfo: activeMessage.questionNodeInfo,
          metadata: {
            selections: selection,
          },
          media: {
            audio: base64Audio,
            images: base64Images,
          },
          message,
        },
      });

      return { hasResponseBeenSent: true };
    },
    [activeMessage.questionNodeInfo, sendWebSocketMessage],
  );

  return !isWebSocketReady ? (
    <LoadingIndicator message={{ tx: "chat.loadingWebSocket" }} />
  ) : !activeMessage ? (
    <LoadingIndicator message={{ tx: "chat.loadingMessages" }} />
  ) : assistantMessages.length == 1 && !hasAcknowledgedWelcome ? (
    <Welcome
      onClick={() => setHasAcknowledgedWelcome(true)}
      disabled={disabled}
    />
  ) : (
    <>
      <nav className="grid w-full max-w-4xl grid-cols-1 items-center sm:grid-cols-3">
        <RestartButton className="order-last sm:order-first" />
        <StepsIndication
          current={assistantMessages.length}
          last={numberOfOnboardingNodes}
        />
      </nav>

      <MessageComponent message={activeMessage} sendMessage={onSendMessage} />
    </>
  );
};

const handleError = ({
  store,
  errorName,
  extra,
  toastText = "Something went wrong",
}: {
  store: RootStore;
  errorName: string;
  extra?: Record<string, unknown>;
  toastText?: string;
}) => {
  if (isLocalhost) {
    console.error(errorName);
  }

  Sentry.captureException(new Error(errorName), {
    level: "error",
    extra,
  });

  store.addToastEvent(
    new PlainError({
      text: toastText,
    }),
  );
};
