import * as Sentry from "@sentry/react";
import { useMemo } from "react";
import { ChatMessageSources } from "shared/model/websocket/schema";

import { ChatMessageDocument } from "./chat-message-document";
import { Text } from "../../text";
import { ChatUserMediaRecord } from "../types";
import { cn } from "~/util/cn";

export const ChatMessageAssistant: React.FC<{
  text?: string;
  media?: ChatUserMediaRecord[];
  sources?: ChatMessageSources;
  mediaSrc?: string;
  mediaAlt?: string;
}> = ({ text = " ", media, sources, mediaSrc, mediaAlt }) => {
  if (sources) {
    return <MessageWithSources text={text} media={media} sources={sources} />;
  }

  return (
    <>
      <Text
        as="div"
        className={cn(
          "chat-bubble whitespace-pre-line bg-mama-gray-100 text-bg-blue-900",
          mediaSrc && " rounded-b-none before:hidden",
        )}
        text={text}
      >
        <ChatMessageDocument media={media} />
      </Text>
      {mediaSrc && (
        <div className="chat-bubble rounded-tl-none bg-mama-gray-100 py-4 text-bg-blue-900">
          <img src={mediaSrc} alt={mediaAlt} className="rounded-lg" />
        </div>
      )}
    </>
  );
};

const MessageWithSources: React.FC<{
  text: string;
  media?: ChatUserMediaRecord[];
  sources: ChatMessageSources;
}> = ({ text, media, sources }) => {
  const entries = Object.entries(sources).sort((a, b) => +a[0] - +b[0]);

  const enrichedText = useMemo(() => {
    // Combine consecutive references like [1], [2], [3] into [1, 2, 3]
    const combinedText = text.replace(
      /(\[\d+\])(?:,\s*(\[\d+\]))+/g,
      (match) => {
        const numbers = match.match(/\d+/g);
        return numbers ? `[${numbers.join(", ")}]` : match;
      },
    );

    // Divive into text and sources (e.g. [1, 2])
    const parts = combinedText.split(/(\[\d+(?:,\s*\d+)*\])/g);

    return parts.map((part) => {
      const match = part.match(/\[(\d+(?:,\s*\d+)*)\]/);

      if (!match || match.length < 2) return part.trimEnd();

      const squareBracketContent = match[1].split(", ");

      return squareBracketContent.map((source, index, array) => (
        <sup key={source + index}>
          <a
            href={sources[source]}
            target="_blank"
            rel="noopener noreferrer"
            className="link-hover link font-mono"
          >
            {source}
          </a>
          {index < array.length - 1 ? "," : ""}
        </sup>
      ));
    });
  }, [text, sources]);

  return (
    <div className="chat-bubble w-fit whitespace-pre-wrap bg-mama-gray-100 text-bg-blue-900">
      <p>{enrichedText}</p>
      <ChatMessageDocument media={media} />

      <div className="group collapse-arrow collapse">
        <input type="checkbox" />
        <div className="collapse-title flex items-center justify-end text-sm font-medium">
          <Text tx="chat.mamaAnswerSources" />{" "}
          {entries.length && (
            <span className="ml-0.5">
              {"("}
              {entries.length}
              {")"}
            </span>
          )}
        </div>
        <div className="collapse-content">
          <ul className="flex flex-wrap items-center gap-2">
            {entries.map(([number, href], index) => (
              <ChatMessageAssistantSource
                number={number}
                href={href}
                key={number + index}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const ChatMessageAssistantSource: React.FC<{
  number: string;
  href: string;
}> = ({ number, href }) => {
  const hostname = useMemo(() => {
    try {
      const url = new URL(href);
      return url.hostname.replace(/^www\./, "");
    } catch (err) {
      Sentry.captureException(
        new Error(
          `Invalid source received: ${href} is not a valid URL. Error: ${
            (err as Error).message
          }`,
        ),
        {
          level: "error",
        },
      );
      return null;
    }
  }, [href]);

  if (hostname == null) {
    return null;
  }

  return (
    <li>
      <a
        href={href}
        className="btn-neutral grid grid-cols-[2rem_auto] rounded-md bg-neutral px-2 pl-0 text-xs shadow-md transition delay-75 duration-500 hover:brightness-110 focus:brightness-110"
        target="_blank"
        rel="noopener noreferrer"
        key={number + href}
      >
        <div className="grid h-full w-6 place-content-center rounded-l-md bg-info/70 font-bold shadow-inner">
          {number}
        </div>
        <span className="py-1">{hostname}</span>
      </a>
    </li>
  );
};
