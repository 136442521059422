import { Text } from "../../components/text";
import { AuthPagesEnum } from "../constants";
import { NavigationButton } from "~/components/form/button";

export const CognitoLoginProblem: React.FC = () => {
  return (
    <Text
      as="div"
      tx="auth.navigation.resendMagicLink"
      txComponents={{
        ResendMagicLink: <NavigationButton to={AuthPagesEnum.MAGIC_SIGN_IN} />,
      }}
    />
  );
};
