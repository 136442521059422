import React, { lazy } from "react";

import { MessageProcessedChoices } from "./components/message-component";
import { OnMessageSendFunction } from "../chat/conversation";
import { emailValidation } from "../chat/validation/email";
import { createValidationFunctionForMinLength } from "../chat/validation/min-length";
import { opadeIdValidation } from "../chat/validation/opade-study-id";
import { yearOfBirthValidation } from "../chat/validation/year-of-birth";
import {
  ComponentTuple,
  FallbackLookup,
  TypeTuple,
  ValidationFunction,
} from "../lookup";
import { IeNodeType, IeSubtype } from "~/api/generated/multiagent";

type MaybeLazyComponent<Props> =
  | React.FC<Props>
  | React.LazyExoticComponent<React.FC<Props>>;

type EcommerceComponent = MaybeLazyComponent<{
  message: MessageProcessedChoices;
  sendMessage: OnMessageSendFunction;
  validationFunction?: ValidationFunction;
}>;

export type EcommerceFallbackLookup = FallbackLookup<EcommerceComponent>;

const TextInput = lazy(() =>
  import("./components/text").then((mod) => ({
    default: mod.TextInput,
  })),
);

const Country = lazy(() =>
  import("./components/dynamic/country").then((mod) => ({
    default: mod.Country,
  })),
);

const Media = lazy(() =>
  import("./components/approval/media").then((mod) => ({
    default: mod.Media,
  })),
);

const YearOfBirth = lazy(() =>
  import("./components/regex/year-of-birth").then((mod) => ({
    default: mod.YearOfBirth,
  })),
);

const PostalCode = lazy(() =>
  import("./components/regex/postal-code").then((mod) => ({
    default: mod.PostalCode,
  })),
);

const Email = lazy(() =>
  import("./components/regex/email").then((mod) => ({
    default: mod.Email,
  })),
);

const EmailNameTerms = lazy(() =>
  import("./components/signup/email-name-terms").then((mod) => ({
    default: mod.EmailNameTerms,
  })),
);

const SingleChoice = lazy(() =>
  import("./components/single-choice/base").then((mod) => ({
    default: mod.SingleChoice,
  })),
);

const Satisfaction = lazy(() =>
  import("./components/scale/satisfaction").then((mod) => ({
    default: mod.Satisfaction,
  })),
);

const MultiChoice = lazy(() =>
  import("./components/multiple-choice/base").then((mod) => ({
    default: mod.MultipleChoice,
  })),
);

const MultiChoiceWithOther = lazy(() =>
  import("./components/multiple-choice/with-other").then((mod) => ({
    default: mod.MultipleChoiceWithOther,
  })),
);

const Terms = lazy(() =>
  import("./components/approval/terms").then((mod) => ({ default: mod.Terms })),
);

const Acknowledge = lazy(() =>
  import("./components/approval/acknowledge").then((mod) => ({
    default: mod.Acknowledge,
  })),
);

const Introduction = lazy(() =>
  import("./components/modal/introduction").then((mod) => ({
    default: mod.Introduction,
  })),
);

const Link = lazy(() =>
  import("./components/approval/link").then((mod) => ({ default: mod.Link })),
);

const Phone = lazy(() =>
  import("./components/regex/phone").then((mod) => ({ default: mod.Phone })),
);

const OpadeStudyId = lazy(() =>
  import("./components/regex/opade-study-id").then((mod) => ({
    default: mod.OpadeStudyId,
  })),
);

const mamaAskEcommerceComponents: EcommerceFallbackLookup = new FallbackLookup(
  [TextInput, undefined],
  // prettier-ignore
  [
    [["TEXT", "MARKETING_CHANNEL"], [TextInput, createValidationFunctionForMinLength(1)]],
    [["TEXT", "MIGRATION_BACKGROUND"], [TextInput, createValidationFunctionForMinLength(1)]],
    [["TEXT", undefined], [TextInput, undefined]],

    [["QUESTION", undefined], [TextInput, undefined]],

    [["APPROVAL", "ACKNOWLEDGE"], [Acknowledge, undefined]],
    [["APPROVAL", "TERMS"], [Terms, undefined]],
    [["APPROVAL", "ENDO_STUDY_AGREEMENT"], [Acknowledge, undefined]],
    [["APPROVAL", "LINK"], [Link, undefined]],
    [["APPROVAL", "MEDIA"], [Media, undefined]],

    [["DYNAMIC", "COUNTRY"], [Country, undefined]],

    [["MODAL", "INTRODUCTION"], [Introduction, undefined]],

    [["MULTIPLE_CHOICE", undefined], [MultiChoice, undefined]],
    [["MULTIPLE_CHOICE_OTHER", undefined], [MultiChoiceWithOther, undefined]],

    [["REGEX", "PHONE"], [Phone, undefined]],
    [["REGEX", "YEAR_OF_BIRTH"], [YearOfBirth, yearOfBirthValidation]],
    [["REGEX", "OPADE_STUDY_ID"], [OpadeStudyId, opadeIdValidation]],
    [["REGEX", "POSTAL_CODE"], [PostalCode, createValidationFunctionForMinLength(3)]],
    [["REGEX", undefined], [TextInput, undefined]],

    [["SIGNUP", "EMAIL"], [Email, emailValidation]],
    [["SIGNUP", "EMAIL_NAME_TERMS"], [EmailNameTerms, undefined]],

    [["SINGLE_CHOICE", undefined], [SingleChoice, undefined]],
    [["SINGLE_ENUM", "GENDER"], [SingleChoice, undefined]],

    [["SCALE", "ZERO_TO_TEN"], [Satisfaction, undefined]],
  ],
);

export const getComponent = (
  type: string,
  subType?: string | null,
): ComponentTuple<EcommerceComponent> => {
  const key: TypeTuple = [type as IeNodeType, subType as IeSubtype];
  return mamaAskEcommerceComponents.get(key);
};
