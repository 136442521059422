import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { nonUserGroups } from "shared/model/cognito";
import { FrontendPageEnum } from "shared/model/pages";
import { UserFeedbackType } from "shared/types/user-feedback-type";

import { RatingUserFeedback } from "./inputs/rating-user-feedback";
import { SatisfactionUserFeedback } from "./inputs/satisfaction-user-feedback";
import { ScaleUserFeedback } from "./inputs/scale-user-feedback";
import { UserFeedbackWithTitleLayout } from "./layouts/user-feedback-with-title-layout";
import {
  SubmitUserFeedbackRequest,
  UserFeedback as UserFeedbackEntity,
} from "../../api/generated/backend";
import { SubmitFeedback } from "../../api/generated/backend/models/SubmitFeedback";
import {
  toTenantIdHeader,
  useApiMutation,
  useApiQuery,
} from "../../api/use-api";
import { useT } from "../../i18n/use-t";
import { useModal } from "../../models/modal-provider";
import { useTenantConfig } from "../../tenant-settings";
import {
  ANSWER_COUNT_KEY,
  ASK_FEEDBACK,
  CREATE_FEEDBACK,
  SUBMIT_FEEDBACK,
} from "../../types/query-keys";
import { useUser } from "../../util/use-user";
import { Button, PrimaryButton } from "../form/button";
import { EmptyWrapper } from "../util/empty-wrapper";
import { useTenantId } from "~/util/use-active-tenant-id";

const userFeedbackInputs = {
  [UserFeedbackType.RATING]: { order: 1, Component: RatingUserFeedback },
  [UserFeedbackType.SCALE]: { order: 2, Component: ScaleUserFeedback },
  [UserFeedbackType.SATISFACTION]: {
    order: 3,
    Component: SatisfactionUserFeedback,
  },
};

export const UserFeedbackModal: React.FC = () => {
  const config = useTenantConfig();
  const tenantId = useTenantId();
  const t = useT();
  const { pathname } = useLocation();

  const user = useUser();
  const hasNonUserGroup = useMemo(
    () => nonUserGroups.some((g) => user?.tenantGroups?.includes(g)),
    [user?.tenantGroups],
  );

  const { data: messageCountsForMamaAnswer } = useApiQuery(
    "multiagent",
    (api) => api.getMultiagentMessageCountForAnswer(toTenantIdHeader(tenantId)),
    ANSWER_COUNT_KEY(tenantId.disease),
    undefined,
    undefined,
  );

  const filteredFeedbacks = useMemo(
    () =>
      config.userFeedback.filter(
        (f) =>
          (!!f.mamaAnswerFeedBackConfig &&
            (messageCountsForMamaAnswer ?? 0) >=
              f.mamaAnswerFeedBackConfig.numMessages &&
            pathname.includes(FrontendPageEnum.MAMA_ANSWER)) ||
          !f.mamaAnswerFeedBackConfig,
      ),
    [config.userFeedback, messageCountsForMamaAnswer, pathname],
  );

  const userFeedbacksConfig = useMemo(
    () =>
      filteredFeedbacks.map((f) => ({
        ...f,
        title: t({ tx: f.title }),
      })),
    [filteredFeedbacks, t],
  );

  const { refetch: reShowFeedback } = useApiQuery(
    "backend",
    (api) =>
      api.showFeedback({
        feedbacksDto: {
          feedbacks: userFeedbacksConfig,
        },
      }),
    CREATE_FEEDBACK,
    undefined,
    {
      onSuccess: () => {
        refetchUnseenFeedbacks();
      },
    },
  );

  const { data: unseenFeedbacks, refetch: refetchUnseenFeedbacks } =
    useApiQuery(
      "backend",
      (api) => api.retrieveUnseenFeedbacks(),
      ASK_FEEDBACK,
      undefined,
      undefined,
    );

  useEffect(() => {
    reShowFeedback();
  }, [refetchUnseenFeedbacks, reShowFeedback, userFeedbacksConfig]);

  const sortedFeedbacks = useMemo(() => {
    if (!unseenFeedbacks) return [];

    return unseenFeedbacks.sort((a, b) => {
      const orderA = userFeedbackInputs[a.type]?.order || 0;
      const orderB = userFeedbackInputs[b.type]?.order || 0;
      return orderA - orderB;
    });
  }, [unseenFeedbacks]);

  return !hasNonUserGroup ? (
    <UserFeedback unseenFeedbacks={sortedFeedbacks} />
  ) : null;
};

const UserFeedback: React.FC<{
  unseenFeedbacks: UserFeedbackEntity[];
}> = ({ unseenFeedbacks }) => {
  const { showModal, hideModal } = useModal();

  const [feedbackToSubmit, setFeedbackToSubmit] = useState<SubmitFeedback[]>(
    [],
  );

  const userFeedbackForm = useMemo(
    () => (
      <div className="flex flex-col items-center justify-between gap-4">
        <UserFeedbackInputs
          unseenFeedbacks={unseenFeedbacks}
          setFeedbackToSubmit={setFeedbackToSubmit}
        />
        <UserFeedbackButtons
          onDismissFeedback={hideModal}
          unseenFeedbacks={unseenFeedbacks}
          feedbackToSubmit={feedbackToSubmit}
          setFeedbackToSubmit={setFeedbackToSubmit}
        />
      </div>
    ),
    [feedbackToSubmit, hideModal, unseenFeedbacks],
  );

  useEffect(() => {
    if (!unseenFeedbacks?.length) {
      hideModal();
      return;
    }

    showModal({
      showCloseButton: false,
      children: userFeedbackForm,
    });
  }, [hideModal, showModal, userFeedbackForm, unseenFeedbacks.length]);

  return null;
};

const UserFeedbackInputs: React.FC<{
  unseenFeedbacks: UserFeedbackEntity[];
  setFeedbackToSubmit: React.Dispatch<React.SetStateAction<SubmitFeedback[]>>;
}> = ({ unseenFeedbacks, setFeedbackToSubmit }) => {
  const updateFeedback = useCallback(
    (feedback: SubmitFeedback | undefined) => {
      if (!feedback) {
        return;
      }
      setFeedbackToSubmit((prev) => {
        const index = prev.findIndex(
          (item) => item.feedbackId === feedback.feedbackId,
        );

        if (index !== -1) {
          const updatedList = prev.slice();
          updatedList[index] = feedback;
          return updatedList;
        } else {
          return [...prev, feedback];
        }
      });
    },
    [setFeedbackToSubmit],
  );

  return (
    <EmptyWrapper>
      {unseenFeedbacks?.map((feedback, key) => (
        <UserFeedbackWithTitleLayout feedback={feedback} key={key}>
          {React.createElement(userFeedbackInputs[feedback.type].Component, {
            feedback,
            setFeedback: updateFeedback,
          })}
        </UserFeedbackWithTitleLayout>
      ))}
    </EmptyWrapper>
  );
};

const UserFeedbackButtons: React.FC<{
  feedbackToSubmit: SubmitFeedback[];
  unseenFeedbacks: UserFeedbackEntity[];
  setFeedbackToSubmit: (feedback: SubmitFeedback[]) => void;
  onDismissFeedback: () => void;
}> = ({
  unseenFeedbacks,
  feedbackToSubmit,
  onDismissFeedback,
  setFeedbackToSubmit,
}) => {
  const queryClient = useQueryClient();

  const { mutate: submitFeedback } = useApiMutation(
    "backend",
    (api) => (request: SubmitUserFeedbackRequest) =>
      api.submitUserFeedback(request),
    SUBMIT_FEEDBACK,
    { successMessage: { tx: "userFeedback.feedbackSent" } },
    {
      onSuccess() {
        queryClient.invalidateQueries(ASK_FEEDBACK);
        if (mandatoryFeedbacksToSubmit.length === 0) dismissFeedback();
      },
    },
  );

  const { mutate: dismissFeedback } = useApiMutation(
    "backend",
    (api) => () => api.dismissUserFeedback(),
    SUBMIT_FEEDBACK,
    undefined,
    {
      onSuccess() {
        queryClient.invalidateQueries(ASK_FEEDBACK);
      },
    },
  );

  const mandatoryUnseenFeedbacks = useMemo(
    () =>
      unseenFeedbacks.filter((f) => f.type !== UserFeedbackType.SATISFACTION),
    [unseenFeedbacks],
  );

  const mandatoryFeedbacksToSubmit = useMemo(
    () =>
      feedbackToSubmit.filter((f) => f.type !== UserFeedbackType.SATISFACTION),
    [feedbackToSubmit],
  );

  const hasReviewedAllMandatoryFeedbacks = useMemo(
    () =>
      mandatoryUnseenFeedbacks?.length === mandatoryFeedbacksToSubmit.length,
    [mandatoryFeedbacksToSubmit.length, mandatoryUnseenFeedbacks?.length],
  );

  return (
    <div className="mt-5 flex gap-4">
      <Button
        onClick={() => {
          onDismissFeedback();
          dismissFeedback();
        }}
        tx="general.close"
      />
      <PrimaryButton
        onClick={() => {
          submitFeedback({
            submitFeedbackDto: { submitFeedbacks: feedbackToSubmit },
          });
          setFeedbackToSubmit([]);
        }}
        tx="general.confirm"
        isDisabled={!hasReviewedAllMandatoryFeedbacks}
      />
    </div>
  );
};
