import { useRef } from "react";
import { twMerge } from "tailwind-merge";

import { GhostButton } from "./button";
import { useDetailsBlur } from "../../util/hooks";
import { SvgIcon } from "../icons/svg-icon";
import { I18nProps, Text } from "../text";
import { Tooltip } from "../tooltip";

export interface DropdownProps<T> {
  value?: T;
  noValueSelected: I18nProps;
  onChange?: (value: T) => void | undefined;
  options: {
    value: T;
    label: I18nProps;
    disabled?: { isOptionDisabled: boolean; reasonWhyDisabled?: I18nProps };
  }[];
  className?: string;
  disabled?: boolean;
  overflowVisible?: boolean;
  selectedValueClassName?: string;
  searchResultsOnTop?: boolean;
}

export const Dropdown = <T extends string | number | boolean>({
  value,
  noValueSelected,
  options,
  onChange,
  className,
  disabled,
  overflowVisible,
  selectedValueClassName,
  searchResultsOnTop,
}: DropdownProps<T>): ReturnType<React.FC> => {
  const activeIndex = options.findIndex((option) => option.value === value);
  const dropDownRef = useRef<HTMLDetailsElement>(null);

  useDetailsBlur(dropDownRef);

  return (
    <details
      className={twMerge(
        "dropdown-end dropdown",
        className,
        disabled ? "pointer-events-none opacity-30" : "",
      )}
      ref={dropDownRef}
    >
      <summary className={twMerge("btn-ghost btn flex")}>
        <div className="flex w-full items-center justify-between gap-1 py-2 ">
          <Text
            className={twMerge("whitespace-nowrap", selectedValueClassName)}
            {...(activeIndex === -1
              ? noValueSelected
              : options[activeIndex]?.label)}
          />
          <SvgIcon icon="down" width={20} height={20} />
        </div>
      </summary>

      <ul
        className={twMerge(
          "absolute z-10 flex max-h-96 min-w-fit flex-col gap-1 rounded-box bg-base-100 p-2 text-left shadow",
          overflowVisible ? "overflow-visible" : "overflow-scroll",
          searchResultsOnTop ? "bottom-12" : "top-12",
        )}
      >
        {options
          .filter((option) => option.value !== value)
          .map((option, idx) => (
            <Tooltip
              className="tooltip-left z-20 w-full"
              key={
                typeof option.value === "boolean"
                  ? option.value.toString() + idx
                  : option.value
              }
              text={
                option.disabled?.isOptionDisabled
                  ? option.disabled?.reasonWhyDisabled
                  : {}
              }
            >
              <GhostButton
                onClick={(e) => {
                  onChange?.(option.value);
                  e.preventDefault();
                  if (dropDownRef.current?.open) {
                    dropDownRef.current.open = false;
                  }
                }}
                className="w-full justify-start text-left"
                isDisabled={option.disabled?.isOptionDisabled}
                {...option.label}
              />
            </Tooltip>
          ))}
      </ul>
    </details>
  );
};
