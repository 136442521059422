import { useState } from "react";

import { StripeCheckoutForm } from "./stripe-checkout-form";
import { StripeSavedPaymentMethodsForm } from "./stripe-saved-payment-methods-form";
import { toTenantIdHeader, useApiQuery } from "../../api/use-api";
import { ModalMethods } from "../../models/modal-provider";
import { PAYMENT_METHODS_KEY } from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";
import { Text } from "../text";

enum PaymentMethodStatus {
  SAVED = "saved",
  NOT_SAVED = "notSaved",
}

export const StripeCheckout: React.FC<ModalMethods & { priceId: string }> = ({
  priceId,
  ...modalProps
}) => {
  const tenantId = useTenantId();
  const [paymentMethodStatus, setPaymentMethodStatus] = useState(
    PaymentMethodStatus.NOT_SAVED,
  );
  const [isPaymentSwitchDisabled, setIsPaymentSwitchDisabled] = useState(false);

  const { data: cards } = useApiQuery(
    "backend",
    (api) => api.getCreditCards(toTenantIdHeader(tenantId)),
    PAYMENT_METHODS_KEY(tenantId),
  );

  return (
    <>
      {cards?.length ? (
        <PaymentMethodsSwitch
          paymentMethod={paymentMethodStatus}
          setPaymentMethod={setPaymentMethodStatus}
          isPaymentSwitchDisabled={isPaymentSwitchDisabled}
        />
      ) : null}
      {paymentMethodStatus === PaymentMethodStatus.SAVED && cards?.length ? (
        <StripeSavedPaymentMethodsForm
          priceId={priceId}
          setIsPaymentSwitchDisabled={setIsPaymentSwitchDisabled}
          {...modalProps}
        />
      ) : (
        <StripeCheckoutForm
          isPaymentSwitchDisabled={isPaymentSwitchDisabled}
          setIsPaymentSwitchDisabled={setIsPaymentSwitchDisabled}
          {...modalProps}
        />
      )}
    </>
  );
};

const PaymentMethodsSwitch: React.FC<{
  paymentMethod: string;
  isPaymentSwitchDisabled: boolean;
  setPaymentMethod: (value: PaymentMethodStatus) => void;
}> = ({ paymentMethod, isPaymentSwitchDisabled, setPaymentMethod }) => {
  return (
    <div className="mb-10 flex items-center justify-center">
      <div className="tabs-boxed flex self-center">
        <Text
          tx="stripe.newPaymentMethod"
          className={`tab tab-${
            isPaymentSwitchDisabled ? "disabled" : ""
          } tab-${
            paymentMethod === PaymentMethodStatus.NOT_SAVED ? "active" : ""
          } tab-md`}
          onClick={() =>
            !isPaymentSwitchDisabled &&
            setPaymentMethod(PaymentMethodStatus.NOT_SAVED)
          }
        />
        <Text
          tx="stripe.savedCard"
          className={`tab tab-${
            isPaymentSwitchDisabled ? "disabled" : ""
          } tab-${
            paymentMethod === PaymentMethodStatus.SAVED ? "active" : ""
          } tab-md`}
          onClick={() =>
            !isPaymentSwitchDisabled &&
            setPaymentMethod(PaymentMethodStatus.SAVED)
          }
        />
      </div>
    </div>
  );
};
