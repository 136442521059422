"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.diseasesWithoutStudies = exports.allDiseaseTypes = exports.DiseaseType = exports.Disease = void 0;
const utility_1 = require("../utils/utility");
var Disease;
(function (Disease) {
    Disease["ATOPIC_DERMATITIS"] = "ATOPIC_DERMATITIS";
    Disease["CHRONIC_HAND_ECZEMA"] = "CHRONIC_HAND_ECZEMA";
    Disease["CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE"] = "CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE";
    Disease["CHRONIC_URTICARIA"] = "CHRONIC_URTICARIA";
    Disease["ENDOMETRIOSIS"] = "ENDOMETRIOSIS";
    Disease["LONG_COVID"] = "LONG_COVID";
    Disease["MYASTHENIA_GRAVIS"] = "MYASTHENIA_GRAVIS";
    Disease["MULTIPLE_SCLEROSIS"] = "MULTIPLE_SCLEROSIS";
    Disease["THYROID_EYE_DISEASE"] = "THYROID_EYE_DISEASE";
    Disease["INSOMNIA"] = "INSOMNIA";
    Disease["GRAVES_DISEASE"] = "GRAVES_DISEASE";
    // studies
    Disease["OPADE"] = "OPADE";
})(Disease || (exports.Disease = Disease = {}));
var DiseaseType;
(function (DiseaseType) {
    DiseaseType["DISEASE"] = "DISEASE";
    DiseaseType["STUDY"] = "STUDY";
})(DiseaseType || (exports.DiseaseType = DiseaseType = {}));
exports.allDiseaseTypes = (0, utility_1.allEnumValues)({
    [Disease.LONG_COVID]: Disease.LONG_COVID,
    [Disease.MYASTHENIA_GRAVIS]: Disease.MYASTHENIA_GRAVIS,
    [Disease.ENDOMETRIOSIS]: Disease.ENDOMETRIOSIS,
    [Disease.MULTIPLE_SCLEROSIS]: Disease.MULTIPLE_SCLEROSIS,
    [Disease.OPADE]: Disease.OPADE,
    [Disease.ATOPIC_DERMATITIS]: Disease.ATOPIC_DERMATITIS,
    [Disease.CHRONIC_HAND_ECZEMA]: Disease.CHRONIC_HAND_ECZEMA,
    [Disease.CHRONIC_URTICARIA]: Disease.CHRONIC_URTICARIA,
    [Disease.THYROID_EYE_DISEASE]: Disease.THYROID_EYE_DISEASE,
    [Disease.INSOMNIA]: Disease.INSOMNIA,
    [Disease.GRAVES_DISEASE]: Disease.GRAVES_DISEASE,
    [Disease.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE]: Disease.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE,
});
exports.diseasesWithoutStudies = (0, utility_1.allEnumValues)({
    [Disease.LONG_COVID]: Disease.LONG_COVID,
    [Disease.MYASTHENIA_GRAVIS]: Disease.MYASTHENIA_GRAVIS,
    [Disease.ENDOMETRIOSIS]: Disease.ENDOMETRIOSIS,
    [Disease.MULTIPLE_SCLEROSIS]: Disease.MULTIPLE_SCLEROSIS,
    [Disease.ATOPIC_DERMATITIS]: Disease.ATOPIC_DERMATITIS,
    [Disease.CHRONIC_HAND_ECZEMA]: Disease.CHRONIC_HAND_ECZEMA,
    [Disease.CHRONIC_URTICARIA]: Disease.CHRONIC_URTICARIA,
    [Disease.THYROID_EYE_DISEASE]: Disease.THYROID_EYE_DISEASE,
    [Disease.INSOMNIA]: Disease.INSOMNIA,
    [Disease.GRAVES_DISEASE]: Disease.GRAVES_DISEASE,
    [Disease.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE]: Disease.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE,
});
