"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.thyroidEyeDisease = void 0;
const cognito_1 = require("../../../model/cognito");
const types_and_constants_1 = require("../../../model/data-collection/types-and-constants");
const diseases_1 = require("../../../model/diseases");
const languages_1 = require("../../../model/languages");
const lock_1 = require("../../../model/lock/lock");
const pages_1 = require("../../../model/pages");
const user_feedbacks_1 = require("../../../model/user-feedback/user-feedbacks");
const deployment_environments_1 = require("../../deployment-environments");
exports.thyroidEyeDisease = {
    diseaseType: diseases_1.DiseaseType.DISEASE,
    disease: diseases_1.Disease.THYROID_EYE_DISEASE,
    homePage: pages_1.FrontendPageEnum.CHAT,
    organisations: [],
    theme: {
        daisyTheme: {
            primary: "#3ca66a",
            "primary-focus": "#34915d",
            "primary-content": "#FFFFFF",
            secondary: "#88a9e3",
            "secondary-focus": "#7895c8",
            "secondary-content": "#FFFFFF",
            accent: "#3459A3",
            "accent-focus": "#2D4D8C",
            "accent-content": "#FFFFFF",
            neutral: "#424242",
            "neutral-focus": "#333333",
            "neutral-content": "#FFFFFF",
            "base-100": "#ffffff",
            "base-200": "#f0f0f0",
            "base-300": "#e0e0e0",
            "base-content": "#1f1f1f",
            info: "#25dbf1",
            "info-content": "#082f34",
            success: "#30ca6b",
            "success-content": "#14522a",
            warning: "#dec762",
            "warning-content": "#524b2b",
            error: "#ff4d4d",
            "error-content": "#ffffff",
            "--btn-text-case": "none",
        },
        extendedTheme: {
            colors: {
                mamaAskUserChatBubble: "#43d178",
                mamaAnswerUserChatBubble: "#1f3560",
            },
        },
    },
    supportedLanguages: [languages_1.Language.de_DE, languages_1.Language.en_US],
    publicPages: [],
    enabledPages: [
        Object.assign({ txPageName: "chat", route: pages_1.FrontendPageEnum.CHAT, groupsWhichCanSeeThePage: [
                cognito_1.SupportedGroupsEnum.USER,
                cognito_1.SupportedGroupsEnum.ADMIN,
            ], settings: {
                chatType: pages_1.ChatType.AI,
                introMessagesTx: [],
            } }, lock_1.defaultPageLock),
        Object.assign({ txPageName: "answer", route: pages_1.FrontendPageEnum.MAMA_ANSWER, groupsWhichCanSeeThePage: [
                cognito_1.SupportedGroupsEnum.USER,
                cognito_1.SupportedGroupsEnum.ADMIN,
            ] }, lock_1.defaultPageLock),
        Object.assign({ txPageName: "report", route: pages_1.FrontendPageEnum.REPORT, groupsWhichCanSeeThePage: [
                cognito_1.SupportedGroupsEnum.USER,
                cognito_1.SupportedGroupsEnum.ADMIN,
            ] }, (0, lock_1.createPageLock)([
            {
                reasonWhyLockedIframeUrl: "tabs.locks.blockedReport",
                lockType: pages_1.PageLockType.CHAT_COMPLETENESS,
            },
        ])),
    ],
    showCallSupportButton: {
        telephoneNumberTx: "disease.thyroid-eye-disease.settings.supportNumber",
        helpPageUrl: "disease.thyroid-eye-disease.settings.surpportUrl",
    },
    authLogo: "/resources/mama-logo-wide.svg",
    headerLogo: "/resources/mama-logo.svg",
    narrowHeaderLogo: "/resources/mama-logo.svg",
    favIcon: "/resources/mama-favicon.png",
    favIconHighres: "/resources/mama-favicon-highres.png",
    tenantNameTx: "disease.thyroid-eye-disease.settings.name",
    autoConfirmUsers: false,
    //legacy and needs to be removed but atm (31.12.24) if removed will make the diff check fail
    groupSecretsArns: {
        [deployment_environments_1.DeploymentEnvironment.DEV]: "arn:aws:secretsmanager:eu-central-1:616427543840:secret:group-secrets-chronic-spontaneous-urticaria-YqPezN",
        [deployment_environments_1.DeploymentEnvironment.PROD]: "arn:aws:secretsmanager:eu-central-1:025928572003:secret:group-secrets-chronic-spontaneous-urticaria-xesU5E",
    },
    identityProviders: [cognito_1.IdpEnum.FACEBOOK],
    metaDescriptionTx: "disease.thyroid-eye-disease.settings.description",
    dataGuardConfig: [
        {
            title: "dataGuards.legal.title",
            dataGuardInputs: [
                { inputFor: types_and_constants_1.DataToBeCollectedEnum.PRIVACY_POLICY_2023_10_18 },
                { inputFor: types_and_constants_1.DataToBeCollectedEnum.TERMS_AND_CONDITIONS_2023_08_30 },
            ],
        },
        {
            title: "dataGuards.socialDemographics.title",
            dataGuardInputs: [
                { inputFor: types_and_constants_1.DataToBeCollectedEnum.NAME },
                { inputFor: types_and_constants_1.DataToBeCollectedEnum.EMAIL },
            ],
        },
    ],
    userFeedback: user_feedbacks_1.mamaAnswerFeedbacks,
    profileInputs: [
        types_and_constants_1.DataToBeCollectedEnum.NAME,
        types_and_constants_1.DataToBeCollectedEnum.EMAIL,
        types_and_constants_1.DataToBeCollectedEnum.LANGUAGE,
    ],
    onboardingType: "chat",
};
