import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { tenantIdEquals } from "shared/config";
import { FrontendPageEnum } from "shared/model/pages";

import { LoadingScreen } from "./loading-screen";
import { useTenantPages } from "../router/use-tenant-pages";
import { useTenantConfig } from "../tenant-settings";
import { useTenantId } from "../util/use-active-tenant-id";
import { useMamaNavigate } from "~/navigation/mama-navigate";
import { frontendPages } from "~/router/frontend-pages";

export const NavigateToHome: React.FC = () => {
  const navigate = useMamaNavigate();
  const tenantId = useTenantId();
  const tenantPages = useTenantPages();
  const { homePage } = useTenantConfig();
  const [params] = useSearchParams();

  useEffect(() => {
    const route = params.get("route");

    const enabledPages = tenantPages
      ?.filter((page) => tenantIdEquals(page.tenantId, tenantId))
      .map(({ page }) => page);
    if (enabledPages && enabledPages.length > 0) {
      navigate(route && isFrontendPage(route) ? route : homePage, {
        replace: true,
      });
    }
  }, [homePage, navigate, params, tenantId, tenantPages]);
  return <LoadingScreen />;
};

const isFrontendPage = (
  maybeFrontendPage: string,
): maybeFrontendPage is FrontendPageEnum => maybeFrontendPage in frontendPages;
